import { IconEdit } from 'chat-components/ui/icon';
import { IconTrash } from 'chat-components/ui/icon';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import { Shield } from 'eva-component/ui/icon';
import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { evaThemeLighter } from 'services/EVAResponseService';
import DashboardTemplateAccessSetting from './DashboardTemplateAccessSetting';

function DashboardTemplateList({ editMode, loading, userTemplateList, handleActiveDasboard, showDeleteTemplateConfirmation, 
    handleSelectTemplate, handleTemplateEditModal, handleAddtoFavorite, dashboardUpdate}) 
{
    const [showAccessModal, setShowAccessModal] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    const onHandleSetFavorite = (index) => {
        const param = { index, userTemplateList, from: 'personal' }
        handleAddtoFavorite(param)
    }
    const showAccessSettingModal = (data, index) => {
      
        setSelectedTemplate(data)
        setShowAccessModal(!showAccessModal)
    }
    useEffect(() => {
        // console.log(userTemplateList)
    },[dashboardUpdate, userTemplateList])

    return (
        !selectedClientID ? 
            <Row className="text-center d-flex align-items-center justify-content-center">
                Please choose a client first.
            </Row>
            :
            <>
                {showAccessModal && 
                    <DashboardTemplateAccessSetting 
                        selectedTemplate={selectedTemplate}
                        showAccessModal={showAccessModal}
                        showAccessSettingModal={showAccessSettingModal}
                        firmDetails={firmDetails}
                        selectedClient={selectedClient} />
                }

                {loading ? <CustomSpinner />
                :
                <div className='editDashboardContainer'>
                    
                    {userTemplateList && userTemplateList.length > 0 ? 
                        userTemplateList.map((data, index) => (
                            <div className='editDashboardItems' key={index}>
                                <div className="dashboardListCard" style={handleActiveDasboard(index)}>
                                    <div className='dashboardFavorite'>
                                        {/* <i 
                                            onClick={e => onHandleSetFavorite(index)}
                                            style={{ color: !data.isFavorite && bgColor }}
                                            className={`${!data.isFavorite ? 'far' : 'fas'} fa-star fa-sm`}>    
                                        </i> */}
                                        {editMode &&
                                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <>
                                                    {data.dashboardTemplate !== undefined &&
                                                    <div  
                                                        className='templateBtn' 
                                                        style={{ marginTop: -5, cursor: 'pointer', height: 25, marginTop: 1, width: 23 }} 
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                showAccessSettingModal(data, index);
                                                            }} >
                                                            <i style={{ marginTop: -5, cursor: 'pointer', height: 25, marginTop: 1, color: '#4b4b4b', paddingTop: 3 }}
                                                                className={`ni ni-settings-gear-65`}>    
                                                            </i>
                                                    </div>
                                                    }
                                                    <div  
                                                        className='templateBtn' 
                                                        style={{ marginTop: -5, cursor: 'pointer', height: 25, marginTop: 1 }} 
                                                        onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleTemplateEditModal(data, index);
                                                            }} >
                                                        <IconEdit />
                                                    </div>
                                                    </>
                                                
                                                <div style={{ cursor: 'pointer'}}>
                                                    <IconTrash 
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            showDeleteTemplateConfirmation(data, index);
                                                        }} 
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <div className='groupItemContainer'>
                                            <div className='groupItemContainerIcon'>
                                                {data.isBI ? 
                                                    <img alt="..." className='mr-2' style={{ height: '21px', width: '25px'}} src={require("assets/img/bi.png").default} />
                                                    :
                                                    <img alt="..." className='mr-2' src={require("assets/img/etanilogo.png").default} />
                                                }
                                            </div>
                                            <div className='groupItemContainerTitle'>
                                                <div className='groupItemName' style={{color: bgColor}}>{data.name ? data.name : data.groupName}</div>
                                                {data.dashboardTemplate !== undefined &&
                                                    <div className='groupName'>{data.importedTemplate ? "Imported" : data.userAccessSetting !== undefined ? data.userAccessSetting?.accessType : "Private"} Template</div>
                                                }
                                            </div>
                                        </div>
                                        <div 
                                            className="btn-showDetails"
                                            style={{ ...evaThemeLighter(100, theme), color: textColor }}
                                            onClick={() => handleSelectTemplate(data, index)}
                                            // >View Template Reports</div>
                                            >View
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <>
                            <div style={{ padding: '50px 10px', fontSize: 15, width: '100%', textAlign: 'center' }}>No dashboard Found!</div>
                        </>
                    }
                </div>
                }
            </>
        )
}

export default DashboardTemplateList