import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Dropdown, Input, Table, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, UncontrolledTooltip } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import CustomSpinner from "components/Misc/CustomSpinner";
import { SyncLoader } from "react-spinners";
import CustomTooltip from "customComponents/customComponents";

const dateFormatter = (val) => {
  const dateString = val;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month because it's zero-based
  const day = date.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate
}

const HistoricCapacityModal = ({ bgColor, textColor, handleSetShowHistoricCapacity, historicCapacity, dbDetails, selectedStaff, fetchStaffList, ...props }) => {
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAddCustomTaskRateRow, setShowAddCustomTaskRate] = useState(false)
  const [editHistoricRowValues, setEditHistoricRowValues] = useState(false)
  const [historyCapacityValues, setHistoricCapacityValues] = useState([])
  const [showAddHistoricRow, setShowAddHistoricRow] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [excludedDates, setExcludedDates] = useState([]);
  const [currentHCToAdd, setCurrentHCToAdd] = useState({
    date: dateFormatter(new Date()),
    Mon: '0:00',
    Tue: '0:00',
    Wed: '0:00',
    Thu: '0:00',
    Fri: '0:00',
  })
  const [dateInput, setDateInput] = useState(new Date())
  const [monInput, setMonInput] = useState('0:00')
  const [tueInput, setTueInput] = useState('0:00')
  const [wedInput, setWedInput] = useState('0:00')
  const [thuInput, setThuInput] = useState('0:00')
  const [friInput, setFriInput] = useState('0:00')
  const [historicRowToBeEdited, setHistoricRowToBeEdited] = useState(null)
  const [alert, setAlert] = useState(null);
  
  useEffect(() => {
    setLoading(true)
    let parsedHC;
    if (historicCapacity) {
      parsedHC = JSON.parse(historicCapacity);
    } else {
      parsedHC = []
    }
    let dateToExclude = []
    parsedHC.map(d => {
      // console.log(d)
      dateToExclude.push(new Date(d.date))
    })
    // console.log(dateToExclude)
    setExcludedDates(dateToExclude)
    setHistoricCapacityValues(parsedHC)
    setLoading(false)
  }, [])

  const handleInputChange = (val, type) => {

    let updatedCurrentValue = currentHCToAdd
    if (type === 'date') {
      updatedCurrentValue.date = dateFormatter(val)
      setDateInput(val)
    } else {
      let value = val.target.value
      let inputId = val.target.id
      if (inputId === 'Mon') {
        value = value.replace(/[^0-9:]/g, '');
        if (!value) {
          value = '0:00'
        }
        setMonInput(value)
      }
      if (inputId === 'Tue') {
        value = value.replace(/[^0-9:]/g, '');
        if (!value) {
          value = '0:00'
        }
        setTueInput(value)
      }
      if (inputId === 'Wed') {
        value = value.replace(/[^0-9:]/g, '');
        if (!value) {
          value = '0:00'
        }
        setWedInput(value)
      }
      if (inputId === 'Thu') {
        value = value.replace(/[^0-9:]/g, '');
        if (!value) {
          value = '0:00'
        }
        setThuInput(value)
      }
      if (inputId === 'Fri') {
        value = value.replace(/[^0-9:]/g, '');
        if (!value) {
          value = '0:00'
        }
        setFriInput(value)
      }
      // console.log('handleInputChange', value)
      updatedCurrentValue[inputId] = value
    }
    setCurrentHCToAdd(updatedCurrentValue)
  }

  const handleSubmit = () => {
    // console.log(historyCapacityValues)
    setSubmitting(true)
    const payloadData = {
      "id": selectedStaff.StaffID,
      "historicCapacity": JSON.stringify(historyCapacityValues),
      "db": dbDetails
    }
    fetch(`/api/clientService/updateStaffHistoricCapacity`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payloadData)
    })
      .then((response) => response.json())
      .then((response) => {
        setSubmitting(false)
        if (response.error) {
          return setAlert(
            <ReactBSAlert
              error
              title="Error"
              onConfirm={() => setAlert(null)}
              confirmBtnBsStyle="danger"
              confirmBtnText="Ok"
              btnSize=""
            >
              Something went wrong. Please try again later.
            </ReactBSAlert>
          )
        }
        // handlesetShowCustomTaskRate(false)
        fetchStaffList()
        // console.log(response)
      })
      .catch((err) => {
        setSubmitting(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            Something went wrong. Please try again later.
          </ReactBSAlert>
        )
        console.log(err)
      })
  }

  const insertToCurrentHCValue = () => {
    // console.log(historyCapacityValues)
    // console.log(currentHCToAdd)
    let newHistoricValues = historyCapacityValues
    if (editHistoricRowValues) {
      newHistoricValues.splice(historicRowToBeEdited, 1, currentHCToAdd)
    } else {
      newHistoricValues.push(currentHCToAdd)
    }

    setHistoricCapacityValues(newHistoricValues)
    setCurrentHCToAdd({
      date: dateFormatter(new Date()),
      Mon: '0:00',
      Tue: '0:00',
      Wed: '0:00',
      Thu: '0:00',
      Fri: '0:00',
    })
    setDateInput(new Date())
    setMonInput('0:00')
    setTueInput('0:00')
    setWedInput('0:00')
    setThuInput('0:00')
    setFriInput('0:00')
    setShowAddHistoricRow(false)
    setHistoricRowToBeEdited(null)
    setEditHistoricRowValues(false)
  }

  const handleDeleteHistoricRow = (historic, hi) => {
    let newHistoricCapacity = historyCapacityValues.filter((hcv, i) => i !== hi)
    setHistoricCapacityValues(newHistoricCapacity)
  }

  const showAddHistoric = () => {
    if (editHistoricRowValues || submitting) return;
    setShowAddHistoricRow(!showAddHistoricRow)
  }

  const handleEditHistoric = (value, i) => {
    if (editHistoricRowValues || showAddHistoricRow || submitting) return;
    setCurrentHCToAdd({
      date: value.date,
      Mon: value.Mon,
      Tue: value.Tue,
      Wed: value.Wed,
      Thu: value.Thu,
      Fri: value.Fri,
    })
    setHistoricRowToBeEdited(i)
    setDateInput(new Date(value.date))
    setMonInput(value.Mon)
    setTueInput(value.Tue)
    setWedInput(value.Wed)
    setThuInput(value.Thu)
    setFriInput(value.Fri)
    setEditHistoricRowValues(true)
  }

  return (
    <>
      <div className="CustomTaskRatesLayout" style={{ overflow: 'hidden' }}>
        <div className="CustomTaskRatesContainer">
          <h2>Historic Capacity</h2>
          <div className="custom-task-rate-table-container">
            <table>
              <thead>
                <tr>
                  <td>Date</td>
                  <td>Mon</td>
                  <td>Tue</td>
                  <td>Wed</td>
                  <td>Thu</td>
                  <td>Fri</td>
                  <td></td> {/* Add a new column for delete */}
                </tr>
              </thead>
              <tbody>
                {
                  historyCapacityValues.length >= 1 ? historyCapacityValues?.map((hcv, i) => (
                    <>
                      <tr key={i} id={`row${i}`}>
                        <td onClick={() => handleEditHistoric(hcv, i)} id={`columnDate${i}`}>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <DatePicker
                                selected={dateInput}
                                onChange={(date) => handleInputChange(date, "date")}
                                dateFormat="yyyy-MM-dd"
                                className=""
                              // excludeDates={excludedDates.map(d => new Date(d))}
                              />
                              :
                              hcv.date
                          }
                        </td>
                        <td onClick={() => handleEditHistoric(hcv, i)} id={`columnMon${i}`}>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <input
                                type="text"
                                value={monInput}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter a number"
                                id={`Mon`}
                              />
                              :
                              hcv.Mon
                          }
                        </td>
                        <td onClick={() => handleEditHistoric(hcv, i)} id={`columnTue${i}`}>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <input
                                type="text"
                                value={tueInput}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter a number"
                                id={`Tue`}
                              />
                              :
                              hcv.Tue
                          }
                        </td>
                        <td onClick={() => handleEditHistoric(hcv, i)} id={`columnWed${i}`}>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <input
                                type="text"
                                value={wedInput}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter a number"
                                id={`Wed`}
                              />
                              :
                              hcv.Wed
                          }
                        </td>
                        <td onClick={() => handleEditHistoric(hcv, i)} id={`columnThu${i}`}>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <input
                                type="text"
                                value={thuInput}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter a number"
                                id={`Thu`}
                              />
                              :
                              hcv.Thu
                          }
                        </td>
                        <td onClick={() => handleEditHistoric(hcv, i)} id={`columnFri${i}`}>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <input
                                type="text"
                                value={friInput}
                                onChange={(e) => handleInputChange(e)}
                                placeholder="Enter a number"
                                id={`Fri`}
                              />
                              :
                              hcv.Fri
                          }
                        </td>
                        <td>
                          {
                            editHistoricRowValues && historicRowToBeEdited === i ?
                              <i
                                onClick={() => insertToCurrentHCValue()}
                                className="far fa-save"
                                style={{
                                  backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                    }`,
                                  color: `${textColor === "#000" ? "#fff" : textColor}`,
                                  borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                    }`
                                }}
                              />
                              :
                              <i
                                onClick={() => submitting ? null : handleDeleteHistoricRow(hcv, i)}
                                className="far fa-trash-alt"
                              />
                          }
                        </td>
                      </tr>
                    </>
                  ))
                    : null
                }
                {
                  showAddHistoricRow ?
                    <tr>
                      <td>
                        <DatePicker
                          selected={dateInput}
                          onChange={(date) => handleInputChange(date, "date")}
                          dateFormat="yyyy-MM-dd"
                          className=""
                        // excludeDates={excludedDates.map(d => new Date(d))}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={monInput}
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Enter a number"
                          id={`Mon`}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={tueInput}
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Enter a number"
                          id={`Tue`}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={wedInput}
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Enter a number"
                          id={`Wed`}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={thuInput}
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Enter a number"
                          id={`Thu`}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={friInput}
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Enter a number"
                          id={`Fri`}
                        />
                      </td>
                      <td>
                        <i
                          onClick={() => insertToCurrentHCValue()}
                          className="far fa-save"
                          style={{
                            backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                              }`,
                            color: `${textColor === "#000" ? "#fff" : textColor}`,
                            borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                              }`
                          }}
                        />
                      </td>
                    </tr>
                    : null
                }
              </tbody>
            </table>
            <div className="p-3">
              <div style={{ width: '205px' }}>
                <span onClick={() => showAddHistoric()}><h4 style={{
                  color: `${bgColor === "#fff" ? "#5e72e4" : bgColor}`, cursor: "pointer"
                }}>{showAddHistoricRow ? `Cancel add historic` : `Add historic capacity`}</h4></span>
              </div>
            </div>
          </div>
          <div className="custom-task-rate-button-container">
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`,
                color: `${textColor === "#000" ? "#fff" : textColor}`,
                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                  }`
              }}
              disabled={(loading || submitting || showAddHistoricRow || editHistoricRowValues) ? true : false}
            >
              {submitting ? `Saving...` : `Save Historic Capacity`}
            </Button>
            <Button onClick={() => { handleSetShowHistoricCapacity(false) }} disabled={submitting ? true : false}>Close</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default HistoricCapacityModal;