import React, { useContext, useEffect, useState } from 'react'
import DashboardTemplateList from './DashboardTemplateList'
import GroupDashboardTemplateList from './GroupDashboardTemplateList'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import ReactBSAlert from "react-bootstrap-sweetalert";
import { deleteGroupDashboard } from 'services/mongoDB';
import { deleteGroupDashboardBI } from 'services/mongoDB';
import { MainContext } from 'context/mainContext';
import { getCustomGroups } from 'services/mongoDB';
import { deleteUserDashboardTemplate } from 'services/mongoDB';
import BlockUI from 'components/Misc/BlockUI';
import { softDeleteUserDashboardTemplate } from 'services/mongoDB';

function AllDashboard({editMode, loading, userAllDashboardList, groupDashboardTemplatesList, handleActiveDasboard, showDeleteTemplateConfirmation, 
    handleSelectTemplate, toggleTemplateEditModal, handleAddtoFavorite, dashboardUpdate, handleGroupDashboardTemplatesList, selectedDashboardTemplate, resetState, handleSetDashboardUpdate}) {
    const [selectedDashboardType, setSelectedDashboardType] = useState("Personal") 
    const [alert, setAlert] = useState(false);
    const { userDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [groupList, setGroupList] = useState([])
    const [showBlockUI, setShowBlockUI] = useState(false);

    const handleSelectDashboardType = (e) => {
        // handleSelectTemplate(null)
        setSelectedDashboardType(e)
    }
    useEffect(() => {
        const loadData = async () => {
            const users = []
            const res = await getCustomGroups({ ClientId: selectedClientID, email: userDetails.User.Email || '' })
            selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
            const groups = [{
                "_id": selectedClient.ClientID,
                "Users": users,
                "GroupName": selectedClient.Name,
                "ClientId": selectedClient.ClientID
            }]
            const newRes = groups.map(item => { return item }).concat(res.map(item => {return item}))
            // // console.log(newRes)
            setGroupList(newRes);

            // console.log(newRes)
        }
        loadData()
    },[])

    useState(() => {
        // console.log(groupDashboardTemplatesList)
    },[selectedDashboardType, groupDashboardTemplatesList])

    const showDeleteGroupTemplateConfirmation = (data, index, isFromGroup = false) => 
    {
        // console.log(selectedDashboardTemplate)
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                // style={{ display: "block", marginTop: "-100px" }}
                onConfirm={async () => {
                    await handleDeleteGroupDashboard(data, index)
                }}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                showCancel={true}
                btnSize=""
            >
                <p>
                    Are you sure you want to delete {data.name}?
                </p>
            </ReactBSAlert>
        );
    };
    const handleDeleteGroupDashboard = async (data, index) => 
    {
        // return 
        setShowBlockUI(true)
        const newGroupDashboardTemplatesList = groupDashboardTemplatesList.filter(item => String(item.id) !== String(data.id))
        handleGroupDashboardTemplatesList(newGroupDashboardTemplatesList)

        try{
            if(data.dashboardTemplate !== undefined)
            {
                const param = { id: data.id }
                
                await deleteUserDashboardTemplate(param)
                // if(data.uid && data.dashboardTemplate)
                //     await softDeleteUserDashboardTemplate(param)
                // else 
                //     await deleteUserDashboardTemplate(param)
            }else{
                const param = { clientId: data.clientId, groupId: data.groupId, id: data.id }
                if(data.isBI)
                    await deleteGroupDashboardBI(param)
                else
                    await deleteGroupDashboard(param)
            }
            setShowBlockUI(false)
            processDeleteDashboard(data, index)
            const alertMessage = `You have successfully deleted the ${data.name}`
            handleSetDashboardUpdate()
            handleSuccessUpdate(alertMessage)
        }catch{
            setShowBlockUI(false)
        }
    }
    const handleSuccessUpdate = (msg, data, index) => {
        setAlert(
            <ReactBSAlert
                success
                title="Success"
                onConfirm={async () => {
                    setAlert(null)
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const processDeleteDashboard = ( data, index) => 
    {
        const selectedDashboard = {...selectedDashboardTemplate}
        groupDashboardTemplatesList.splice(index,1)
        if(selectedDashboard._id === data._id) { 
            if(groupDashboardTemplatesList.length > 0) handleSelectTemplate(groupDashboardTemplatesList[0],0)
            else resetState()
        }
    }
    return (
        <>
            {alert}
            {showBlockUI ? <BlockUI /> : null}
            <div style={{ marginTop: 50 }}>
                <Col lg='3' md='3' sm='6' sx='12'>
                    {/* <FormGroup> */}
                        {/* <Label htmlFor="selectedDashboardUID">Select Dashboard Type</Label>
                        <Input
                        type="select"
                        id="selectedDashboardUID"
                        value={selectedDashboardType}
                        onChange={handleSelectDashboardType}
                        >
                            <option key={1} value="Personal">
                                Personal Dashboard
                            </option>
                            <option key={2} value="Group">
                                Group Dashboard
                            </option>
                        </Input> */}
                        <div className="nav nav-tabs" role="tablist" style={{ border: 'unset', justifyContent: 'unset' }}>
                            {["Personal", "Group"].map((type) => {
                                const isSelected = selectedDashboardType === type;
                                return (
                                    <a 
                                        key={type}
                                        href="#" 
                                        role="tab" 
                                        className={`nav-item nav-link ${isSelected ? 'active' : ''}`}
                                        style={{ 
                                            background: isSelected ? '#fff' : '#f8f9fe', 
                                            borderBottom: isSelected ? 'unset' : '1px solid #d8d8d8'
                                        }} 
                                        onClick={() => handleSelectDashboardType(type)}
                                    >
                                        {type} Dashboard
                                    </a>
                                );
                            })}
                        </div>
                    {/* </FormGroup> */}
                </Col>
            </div>
            {selectedDashboardType === "Personal" &&
                <>
                    {(userAllDashboardList && userAllDashboardList.length > 0) || editMode
                    ?
                            <DashboardTemplateList
                                editMode={editMode}
                                loading={loading}
                                userTemplateList={userAllDashboardList}
                                handleActiveDasboard={handleActiveDasboard}
                                showDeleteTemplateConfirmation={showDeleteTemplateConfirmation}
                                handleSelectTemplate={handleSelectTemplate}
                                handleTemplateEditModal={toggleTemplateEditModal}
                                handleAddtoFavorite={handleAddtoFavorite}
                                dashboardUpdate={dashboardUpdate}
                            />
                    :
                    <p>No Personal Dashboard Found!</p>}
                </>
            }
            {selectedDashboardType === "Group" &&
                <>
                    {groupDashboardTemplatesList && groupDashboardTemplatesList.length > 0
                    ?
                        <GroupDashboardTemplateList
                            editMode={editMode}
                            loading={loading}
                            groupDashboardTemplatesList={groupDashboardTemplatesList}
                            handleActiveDasboard={handleActiveDasboard}
                            showDeleteTemplateConfirmation={showDeleteGroupTemplateConfirmation}
                            handleSelectTemplate={handleSelectTemplate}
                            handleTemplateEditModal={toggleTemplateEditModal}
                            handleAddtoFavorite={handleAddtoFavorite}
                            dashboardUpdate={dashboardUpdate}
                            groupList={groupList}
                        />
                    :
                    <p>No Group Dashboard Found!</p>}
                </>
            }
        </>
    )
}

export default AllDashboard