import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { MainContext } from 'context/mainContext';
import { submitEvaQueryHandler } from 'services/DashboardService';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { decryptAndDecompress } from 'services/DashboardService';
import { SailsRegisterEVAtemplates } from 'services/DashboardService';
import ReactBSAlert from "react-bootstrap-sweetalert";

const DashboardTemplateKPIModal = ({ onToggleDashboardKPI, stext, integration, messages }) => {
    const {
        selectedClient,
        firmDetails,
        userDetails,
    } = useContext(MainContext);

    const [isLoading, setIsLoading] = useState(true);
    const [templatesData, setTemplatesData] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [selectedViewOption, setSelectedViewOption] = useState('Grid')
    const [displayName, setDisplayName] = useState("");
    const [query, setQuery] = useState("")
    const [visualData, setVisualData] = useState(null)
    const [alert, setAlert] = useState(false);

    const handleSaveDashboardTemplatesKPI = async () => {
        setIsLoading(true)
        const param = {
            query,
            integration,
            category: selectedCategory,
            sub_category: selectedSubCategory,
            template: selectedTemplate,
            display_name: displayName,
            visual_type: selectedViewOption,
            visual_data: visualData,
        }
   
        try {
            console.log(param)
            await SailsRegisterEVAtemplates(param)
            setIsLoading(false)
            handleSuccessUpdate()
        }catch(err){
            warningAlert(err)
            setIsLoading(false)
        }
    }
    function replaceTableNameXB(query, id, currency) {
        // return query.replace(/\b[a-zA-Z]+_client\d+_xeroblue_[a-zA-Z]+_[A-Z]+\b/g, `${id}_xeroblue_$2_${currency}`);
        return query.replace(/\b([a-zA-Z]+_client\d+)_xeroblue_([a-zA-Z]+)_[A-Z]+\b/g, `${id}_xeroblue_$2_${currency}`);
    }
    function replaceTableNameXPM(query, id) {
        return query.replace(/\b[a-zA-Z]+_client\d+_xpm[a-zA-Z]+\b/g, `${id}_xpm$2`);
    }
    function replaceTableNameMYOB(query, id) {
        return query.replace(/\b[a-zA-Z]+_client\d+_myob_[a-zA-Z]+\b/g, `${id}_myob_$2`);
    }
    function replaceTableNameWFM(query, id, currency) {
        return query.replace(/\b[a-zA-Z]+_client\d+_wfm2_[a-zA-Z]+_[A-Z]+\b/g, `${id}_wfm2_$2_${currency}`);
    }
    useEffect(() => {
        const loadTemplates = async () => {
            try {
                if(messages.visual.selectedViewOption)
                {
                    const visual = messages.visual
                    console.log(visual.selectedViewOption)
                    setSelectedViewOption(visual.selectedViewOption)
                    let visual_data
                    switch (visual.selectedViewOption.trim()) {
                        case "Bar Chart":
                            visual_data = {"dataLabel": "", "dataValue": [], "groupings": ""}
                            visual_data.dataLabel = visual.selectedFooter.label
                            visual_data.dataValue = visual.selectedGroups.map(item => { return item.data } )
                            visual_data.groupings = visual.selectedGroup || "" 
                            setVisualData(visual_data)
                            break;
                        case "Line Chart":
                            visual_data = {"dataLabel": "", "dataValue": [], "groupings": ""}
                            visual_data.dataLabel = visual.selectedFooter.label
                            visual_data.dataValue = visual.selectedGroups.map(item => { return item.data } )
                            visual_data.groupings = visual.selectedGroup || "" 
                            setVisualData(visual_data)
                            break;
                        case "Pie Chart":
                            visual_data = {"dataLabel": "", "dataValue": "", "groupings": ""}
                            visual_data.dataLabel = visual.selectedLabel?.label
                            visual_data.dataValue = visual.selectedData?.label
                            visual_data.groupings = visual.selectedGroup || "" 
                            setVisualData(visual_data)
                            break;
                    }
                }

                if(stext !== undefined)
                {
                    const decryptedText = await decryptAndDecompress(stext);
                    console.log("decryptedText: ", decryptedText)
                    let updatedQuery = ""
                    updatedQuery = replaceTableNameXB(decryptedText, '[id]', '[currency]');
                    console.log("updatedQuery: ", updatedQuery)

                    setQuery(updatedQuery);
                }

               

                const res = await submitEvaQueryHandler(
                    `SELECT * FROM _eva_sql_templates WHERE template IS NOT null ORDER BY intergration_id`,
                    selectedClient,
                    firmDetails,
                    userDetails
                );
    
                const structureData = (arr) => {
                    const result = {};
    
                    arr.forEach(({ template, category, sub_category }) => {
                        if (!result[template]) result[template] = {};
                        if (!result[template][category]) result[template][category] = new Set();
                        result[template][category].add(sub_category);
                    });
    
                    return Object.entries(result)
                        .sort(([a], [b]) => a.localeCompare(b)) // Sort templates alphabetically
                        .map(([template, categories]) => ({
                            template,
                            categories: Object.entries(categories)
                                .sort(([a], [b]) => a.localeCompare(b)) // Sort categories alphabetically
                                .map(([category, subCategories]) => ({
                                    category,
                                    sub_categories: [...subCategories].sort((a, b) => a.localeCompare(b)) // Sort subcategories alphabetically
                                }))
                        }));
                };
    
                const structuredData = structureData(res);
                setTemplatesData(structuredData);
            } catch (error) {
                console.error("Error fetching templates:", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        loadTemplates();
    }, []);

    const handleSuccessUpdate = () => {
        setAlert(
        <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
                handleCloseModal();
            }}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
        >
            <p>You have successfully register new dashboard template KPI!</p>
        </ReactBSAlert>
        );
    };
    const warningAlert = (response) => {
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            {response.message || response.error || response}
          </ReactBSAlert>
        );
      };
    const handleCloseModal = () => {
        onToggleDashboardKPI();
    };

    const handleTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
        setSelectedCategory("");
        setSelectedSubCategory("");
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setSelectedSubCategory("");
    };

    const handleSubCategoryChange = (e) => {
        setSelectedSubCategory(e.target.value);
    };

    const templates = templatesData.map(t => t.template);
    const categories = selectedTemplate
        ? templatesData.find(t => t.template === selectedTemplate)?.categories || []
        : [];
    const subCategories = selectedCategory
        ? categories.find(c => c.category === selectedCategory)?.sub_categories || []
        : [];

    const handleDisplayNameChange = (e) => {
        setDisplayName(e.target.value);
    };

    return (
        <>
            <Modal isOpen={true}>
                {alert}
                {isLoading ? <CustomSpinner /> : (
                    <ModalBody>
                        {/* <h1>Pin to Dashboard</h1>
                        <h5>Select an existing dashboard or create a new one</h5> */}
                        <Form>
                            {/* Template Dropdown */}
                            <FormGroup>
                                <Label for="templateSelect">Select Template</Label>
                                <Input
                                    type="select"
                                    id="templateSelect"
                                    value={selectedTemplate}
                                    onChange={handleTemplateChange}
                                >
                                    <option value="">Select Template</option>
                                    {templates.map((template, index) => (
                                        <option key={index} value={template}>{template}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {/* Category Dropdown */}
                            <FormGroup>
                                <Label for="categorySelect">Select Category</Label>
                                <Input
                                    type="select"
                                    id="categorySelect"
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    disabled={!selectedTemplate}
                                >
                                    <option value="">Select Category</option>
                                    {categories.map((cat, index) => (
                                        <option key={index} value={cat.category}>{cat.category}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {/* Sub-Category Dropdown */}
                            <FormGroup>
                                <Label for="subCategorySelect">Select Sub-Category</Label>
                                <Input
                                    type="select"
                                    id="subCategorySelect"
                                    value={selectedSubCategory}
                                    onChange={handleSubCategoryChange}
                                    disabled={!selectedCategory}
                                >
                                    <option value="">Select Sub-Category</option>
                                    {subCategories.map((subCat, index) => (
                                        <option key={index} value={subCat}>{subCat}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            {/* Display Name Input */}
                            <FormGroup>
                                <Label for="displayName">Display Name</Label>
                                <Input
                                    type="text"
                                    id="displayName"
                                    value={displayName}
                                    onChange={handleDisplayNameChange}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                )}
                <ModalFooter>
                    <Button color="primary" onClick={handleSaveDashboardTemplatesKPI} disabled={!selectedSubCategory}>Save as KPI</Button>{' '}
                    <Button color="secondary" onClick={handleCloseModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default DashboardTemplateKPIModal;
