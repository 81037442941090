import { MainContext } from "context/mainContext";
import React, { useEffect, useState, useCallback, useContext } from "react";
import { Button } from "reactstrap";

function EVADashboardAnswer({ answer, query, isWelcomeMessage, handleSendEVAStreamMessage, isDefaultDashboard, quickFilter }) {
    const [showMore, setShowMore] = useState(true);
    const [templates, setTemplates] = useState([])
    const { bgColor, textColor, userDetails } = useContext(MainContext);

    useEffect(() => {
        setShowMore(query?.length === 0);
        const templates = answer.match(/\*\*(.*?)\*\*/g).map(template => template.replace(/\*\*/g, ''));
        // console.log(templates);
        setTemplates(templates)
    }, [query]);

    const handleClick = useCallback(({ href, text }) => {
        handleSendEVAStreamMessage(text)
    }, []);
    
    const ReformatLine = ({ line }) => {
        const handleAnchorClick = (e) => {
            e.preventDefault();
            const text = e.target.innerText;
            const href = e.target.getAttribute("href");
    
            if (href) {
                handleClick({ href, text });
            // } else if (e.target.tagName === "STRONG" || e.target.tagName === "DIV") {
            } else if (e.target.tagName === "DIV") {
                handleClick({ text });
            }
        };
    
        let formattedLine = line
            .replace(/Certainly! /g, "")
            .replace(/^\d+\.\s*/gm, "") // Remove numbers followed by a period (e.g., "1. ", "2. ")
            .replace(/:\s*/g, "") // Remove colons
            .replace(/####(.*?)(\n|$)/g, "<h3>$1</h3>")
            .replace(/###(.*?)(\n|$)/g, "<h2>$1</h2>")
            .replace(/- *\*\*(.*?)\*\*/g, "<strong>$1</strong>")
            .replace(/\*\*(.*?)\*\*/g, (_, text) => 
                isDefaultDashboard
                    ? `<div style="display: flex"><div class='defaultEvaDashboardButton' style="background: ${bgColor}; color: ${textColor};">${text}</div></div>`
                    : `<br><strong>${text}</strong>`
            )
            .replace(/\n/g, "<br>") // Ensure new lines are converted to <br>
            .replace(
                /<a href='(.*?)'>(.*?)<\/a>/g,
                (_, href, text) =>
                    `<a href="${href}" class="custom-link" style="background: ${bgColor}; color: ${textColor};">${text}</a>`
            );
    
    
        if (!formattedLine.trim()) formattedLine = "<br>";
    
        return (
            <span
                dangerouslySetInnerHTML={{ __html: formattedLine }}
                onClick={(e) => {
                    if (e.target.tagName === "A" || e.target.tagName === "STRONG" || e.target.tagName === "DIV") {
                        handleAnchorClick(e);
                    }
                }}
            />
        );
    };
    
    

    const lines = answer ? answer.split("`nl`") : [];
    const displayedLines = isWelcomeMessage
        ? lines
        : showMore
        ? lines
        : lines.slice(0, 2);

    return (
        <div style={{ fontWeight: '500', fontSize: 13 }}>
            {/* {displayedLines.map((line, index) => (
                <div key={index}>
                    <ReformatLine line={line} />
                </div>
            ))}

            {lines.length > 2 && query?.length > 0 && (
                <div style={{ display: "flex", padding: "10px 0px" }}>
                    <Button
                        color="primary"
                        size="sm"
                        outline
                        onClick={() => setShowMore(!showMore)}
                    >
                        {showMore ? "See less" : "See more"}
                    </Button>
                </div>
            )} */}
            <div>Hi {userDetails.User.FirstName}, I'm EVA, your AI Dashboard Builder. I can build dynamic dashboards for you based on your business needs. </div>
            <br></br>

            <div className="mb-3">Are you stuck to begin with? See below 7 ideas that are popular in the market right now which you can load immediately.</div>
            <div className="default-dashboard-container">
                {templates.length > 0 && templates.map((item, index) => (
                    <div 
                        key={index} 
                        className="default-dashboard-item" 
                        style={{ background: bgColor , color: textColor }} 
                        onClick={() => handleSendEVAStreamMessage(item)}
                    >{item} Dashboard</div>
                ))}
            </div>
            <br></br>

            <div>Otherwise , why not use quick filters to select a category and sub category - then guide me more with what you want to focus on, explaining what you would like inside your dashboard. Leave it with me to build you something amazing!</div>
            
        </div>
    );
}

export default EVADashboardAnswer;
