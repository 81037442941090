import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2';
import { getRandomLightColor } from 'services/EVAService';
import { defaultControl } from 'services/EVAVisualService';

function DashboardTemplateltemPieGraph({ sqlresult, item, treeViewData, templateItemVisual, selectedViewOption, groupLevel }) 
{
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(templateItemVisual.selectedGraphHeaderOption)
    const [selectedDataList, setSelectedDataList] = useState([]);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [gridGroupLevel, setGridGroupLevel] = useState(groupLevel)
    const [oldPieItem, setOldPieItem] = useState(null)
    const [newUpdatesCount, setNewUpdatesCount] = useState(0)
    const [groupHeaderList, setGroupHeaderList] = useState(templateItemVisual.chartHeader?.header)
    const {
        bgColor,
        textColor
    } = useContext(MainContext);
    const { 
        selectedGroup,
        selectedGroups,
        chartHeader,
        selectedLabel,
        selectedData,
        pieItem,
        selectedFooter,
        headerToMultiSelect
    } = templateItemVisual

    const [chartDataSet, setChartDataSet] = useState(null);
    let chartData = {
        labels: [],
        datasets: [],
    }

    useEffect(() => {
        if(selectedLabel && selectedData)
        {
            const label = getPieChartLabel(selectedLabel.id)
            const pieItem = getPieChartLabelGroup(label)
            const data = getPieChartData(selectedData.id, pieItem)

            setOldPieItem(pieItem)
            setSelectedDataList(data)
            setSelectedGraphHeaderOption(selectedGraphHeaderOption)
        }
    },[selectedGraphHeaderOption, gridGroupLevel])

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)

        if(templateItemVisual.selectedGroup) {
            processPieHeader(templateItemVisual.selectedGroup)
        }
    },[item])

    useEffect(() => {
    },[chartDataSet])
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    const processPieHeader = (selectedGroup) => {
        // console.log("headerToMultiSelect: ", headerToMultiSelect)
        if(!headerToMultiSelect) return
        const res = headerToMultiSelect.find((e) => e.name === selectedGroup);
        const header = getChartHeader2(res?.id)

        setGroupHeaderList(header)
        handleSelectGraphHeader(header[0])
    }
    const getChartHeader2 = (id) => {
        const label = []
        let currentIndentLevel = 0;
        loopThruItems(id, treeViewData);

        function loopThruItems(indentLevel, treeViewData) {
            const rowsToSum = [];
            
            for (let i = 0; i < treeViewData.length; i++) {
                const row = treeViewData[i];

                if (currentIndentLevel === id) {
                    if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                        if(!label.includes(row.name))
                        label.push(row.name)
                    }
                }

                if (row.children) {
                    currentIndentLevel++;
                    const childRowsToSum = loopThruItems(indentLevel, row.children);
                    rowsToSum.push(...childRowsToSum);
                    currentIndentLevel--;
                }
            }
            return rowsToSum;
        }
        return label
    }
    const getPieChartLabelGroup = (label) => 
    {
        const group = [];
        label.forEach((element, index) => {
            group.push({ 
                data: element, 
                color: pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index), 
                isOpen: false
            });
        });
        return group
    };
    const getPieChartLabel = (id) => {
        const label = []
        const chartLabel = []
        let currentIndentLevel = 0;

        if(selectedGraphHeaderOption)
        { 
            loopThruItemsWithFilter(id, treeViewData)
        } else {
            loopThruItems(id, treeViewData);
        }

        function loopThruItems(indentLevel, treeViewData) {
            const rowsToSum = [];
            
            for (let i = 0; i < treeViewData.length; i++) {
                const row = treeViewData[i];

                if (currentIndentLevel === id) {
                    if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                        const param = {id: row.sqlId, name: row.name}
                        chartLabel.push(row.name)
                        label.push(param)
                    }
                }

                if (row.children) {
                    currentIndentLevel++;
                    const childRowsToSum = loopThruItems(indentLevel, row.children);
                    rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                    currentIndentLevel--;
                }
            }
            return rowsToSum;
        }

        function loopThruItemsWithFilter(indentLevel, treeViewData) {
            for (let i = 0; i < treeViewData.length; i++) {
                const row = treeViewData[i];
                if(row.name === selectedGraphHeaderOption)
                {
                    // console.log(row)
                    if(row.children) {
                        currentIndentLevel++;
                        loopThruParentChild(indentLevel, row.children)
                        currentIndentLevel--;
                    }

                    if(row.value)
                    {
                        const param = {id: row.sqlId, name: row.name}
                        chartLabel.push(row.name)
                        label.push(param)
                    }

                    function loopThruParentChild (indentLevel, parent)
                    {
                        for (let i = 0; i < parent.length; i++) { 
                            const row = parent[i];
                            if (currentIndentLevel === id) {
                                if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                    const param = {id: row.sqlId, name: row.name}
                                    chartLabel.push(row.name)
                                    label.push(param)
                                }
                            }

                            if (row.children) {
                                currentIndentLevel++;
                                loopThruParentChild(indentLevel, row.children);
                                currentIndentLevel--;
                            }
                        }
                    }
                }

                if (row.children) {
                    currentIndentLevel++;
                    loopThruItemsWithFilter(indentLevel, row.children);
                    currentIndentLevel--;
                }
            }
        }
        chartData.labels = chartLabel
        return label
    }
    const getPieChartData = (id, pieitem) => {
        const data = []
        const color = []

        if(selectedGraphHeaderOption || selectedGroup)
        {
            pieitem && pieitem.length > 0 && pieitem.forEach((item, index) => 
            {
                let totalAmount = 0
                loopThruItems(treeViewData)
                function loopThruItems(treeViewData) {
                    for (let i = 0; i < treeViewData.length; i++) {
                        const row = treeViewData[i];
                        if(row.sqlId === item.data.id) {
                            if (!row.id.includes("-TotalRow") && 
                                row.id !== "grand-total" && 
                                !row.id.includes("-grandParent-")) 
                            {
                                if(row.value)
                                {
                                    totalAmount += parseFloat(row.value[id - gridGroupLevel])
                                }

                                if (row.children) {
                                    loopThruParentChild(row.children);
                                }
                            }
                        }
                        if (row.children) {
                            loopThruItems(row.children);
                        }
                    }
                }

                function loopThruParentChild(parent) {
                    for (let i = 0; i < parent.length; i++) {
                        const row = parent[i];
                        if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                            if(row.value)
                            {
                                totalAmount += parseFloat(row.value[id - gridGroupLevel])
                            }

                            if (row.children) {
                                loopThruParentChild(row.children);
                            }
                        }
                    }
                }
                

                data.push(totalAmount = totalAmount < 0 ? Math.abs(totalAmount) : totalAmount)
                color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
            })

            // console.log(data)
            chartData.datasets = [{
                label: '',
                data: data,
                backgroundColor: color,
                hoverOffset: 4,
            }]
        } else {
            // console.log(pieitem)
            pieItem && pieItem.length > 0 && pieItem.forEach((item, index) => 
            {
                let totalAmount = 0
                if(gridGroupLevel) {
                    loopThruItems(treeViewData)
                    function loopThruItems(treeViewData) {
                        for (let i = 0; i < treeViewData.length; i++) {
                            const row = treeViewData[i];
                            if(row.sqlId === item.data.id) {
                                
                                if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                                    if(row.value)
                                    {
                                        totalAmount += parseFloat(row.value[id - gridGroupLevel]) 
                                    }

                                    if (row.children) {
                                        loopThruParentChild(row.children);
                                    }
                                }
                            }
                            if (row.children) {
                                loopThruItems(row.children);
                            }
                        }
                    }

                    function loopThruParentChild(parent) {
                        for (let i = 0; i < parent.length; i++) {
                            const row = parent[i];
                            
                            if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                      
                                if(row.value)
                                {
                                    totalAmount += parseFloat(row.value[id - gridGroupLevel])
                                }

                                if (row.children) {
                                    loopThruParentChild(row.children);
                                }
                            }
                        }
                    }
                    
                    data.push(totalAmount = totalAmount < 0 ? Math.abs(totalAmount) : totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                } else {
                    sqlresult.forEach((element, index) => {
                        if(item.data === element[selectedLabel.id])
                        {
                            if (!data.includes(element[id])) {
                                if (typeof element[id] === 'string') 
                                            if (element[id].includes('%')) 
                                                if(!isNaN(parseFloat(element[id].replace('%', '')).toFixed(2)))
                                                    element[id] = parseFloat(element[id].replace('%', '')).toFixed(2)
    
                                totalAmount+= element[id]
                            }
                        }
                    });
                    data.push(totalAmount = totalAmount < 0 ? Math.abs(totalAmount) : totalAmount)
                    color.push(pieItem && pieItem[index] && pieItem[index].color ? pieItem[index].color : defaultControl(index));
                }
            })

            chartData.datasets = [{
                label: '',
                data: data,
                backgroundColor: color,
                hoverOffset: 4,
            }]
        }


        // setColors(color)
        // console.log(chartData)
        // console.log({ pieItem, selectedLabel })
      
        setChartDataSet(null)
        setTimeout(() => {
            setChartDataSet(chartData)
            setNewUpdatesCount(prev => prev + 1)
        }, 100);
        return data
    }
    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }
    return (
        <>
            <div className='templateVisualGraphHeaderContent'>
                {chartDataSet &&
                    <>
                        <div className='templateVisualGraphHeaderContainer'>
                            {groupHeaderList && groupHeaderList.map((item) => (
                            <div 
                                className='templateVisualGraphHeaderItem' 
                                style={selectedGraphHeaderOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                onClick={() => handleSelectGraphHeader(item)}
                            >{item}</div>
                            ))}
                        </div>
                        <div style={{ maxHeight: screenHeight  - 300 }}>
                            <Pie
                                data={chartDataSet}
                            />
                        </div>
                    
                    </>
                }
            </div>
        </>   
    )
}

export default DashboardTemplateltemPieGraph