import React, { useEffect, useState, useContext } from "react";
// nodejs library that concatenates classes
// import classnames from "classnames";
// react component used to create sweet alerts
// import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import { Container, Row, Col, Button, Input, Modal } from "reactstrap";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// core components
import AccountantDetailsForm from "../details/AccountantDetailsForm";
import Authority from "../details/Authority";
import Clients from "../details/Clients";
import AccountantCard from "./AccountantCard";
import { dataFilterAdvisorOnly } from "../../../../utils/utilityFunctions";
import PanelCard from "../../../../components/Cards/PanelCard";

import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import AccountantUpdateModal from "./AccountantUpdateModal";
import BlockUI from "components/Misc/BlockUI";
import CustomHeader from "customComponents/customHeader";
import { BeatLoader } from "react-spinners";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import UpgradeModal from "components/PlanUpgrade/UpgradeModal";

const Accountant = () => {
  // eslint-disable-next-line
  const { bgColor, textColor, userDetails, setSelectedClientID, selectedClientID, firmDetails, getDetails, } =
    useContext(MainContext);
  const [defaultModal, setdefaultModal] = useState(false);
  const [step, setStep] = useState(1);
  const [accountants, setAccountants] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setalert] = useState(false);
  const [warning, setWarning] = useState(null);
  const [payload, setPayload] = useState({});
  const [modalTitle, setModalTitle] = useState("Add Admin");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedAccountant, setSelectedAccountant] = useState({});
  const [clients, setClients] = useState({});
  const [avatar, setAvatar] = useState();
  const history = useHistory();
  const [selectedClients, setSelectedClients] = useState([]);
  const [removedClients, setRemovedClients] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPositionValid, setIsPositionValid] = useState(true);
  const [showBlockUI, setShowBlockUI] = useState(false);
  // eslint-disable-next-line
  const [isAvatarUpdate, setIsAvatarUpdate] = useState(false); // added
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const fetchAPIs = async () => {
    if (firmDetails?.Details?.Trial && firmDetails?.Details?.IsTrialExtended) {
      return history.push(`/`);
    }
    setIsLoading(true);
    await fetch("/api/accountant/getAllRecords", { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
          setIsLoading(false);
          return;
        }
        if (!data.error && data) {
          setAccountants(data);
          setFilteredData(data);
          setIsLoading(false);
          // let ids = []
          // console.log("getAllRecords", data);
          // data.map(d => {
          //   ids.push(d.AccountantID)
          // })

          // console.log(ids)
        }
      })
      .catch((err) => {
        console.log(err);
      });

    await fetch("/api/client/getAll", { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        setClients(data);
        setIsLoading(false);
      })
      .catch((e) => console.log(e)); // added to catch errors
  };

  useEffect(() => {
    let isMounted = true;
    // console.log(firmDetails);
    let sessionSelectedClientID = sessionStorage.getItem('selectedClientID')
    setSelectedClientID(sessionSelectedClientID ? sessionSelectedClientID : selectedClientID);
    // fetchAPIs(); // Added By Kenneth: Temporary until use details fix
    if (userDetails?.User?.UserAccess < 5) {
      fetchAPIs(); // Comment temporary until user details fix
      setClients(clients);
      setIsLoading(isLoading);
      setAccountants(accountants);
      setFilteredData(filteredData);
    }
    return () => {
      isMounted = false;
    };
    //eslint-disable-next-line
  }, [userDetails, firmDetails]);

  const displayPage = (step) => {
    if (step === 1) {
      return (
        <div className="mt-5">
          <AccountantDetailsForm
            data={payload}
            handleInputChange={handleInputChange}
            selectedAccountant={selectedAccountant}
            avatar={avatar}
            isEmailValid={isEmailValid}
            isFirstNameValid={isFirstNameValid}
            isLastNameValid={isLastNameValid}
            isPhoneValid={isPhoneValid}
            isPositionValid={isPositionValid}
            handleImgChange={handleImgChange}
            modalTitle={modalTitle}
          />
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="mt-5">
          <Clients
            bgColor={bgColor}
            textColor={textColor}
            clients={clients}
            selectedClients={selectedClients}
            handleClientSelection={handleClientSelection}
            removedClients={removedClients}
            setRemovedClients={setRemovedClients}
          />
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="mt-5">
          <Authority
            authority={payload.authority}
            handleInputChange={handleInputChange}
            payload={payload}
            selectedAccountant={selectedAccountant}
            userDetails={userDetails}
          />
        </div>
      );
    }
  };

  const displayStepsIcons = () => {
    return (
      <div className="text-center mb-3" style={{ fontSize: "1.7rem" }}>
        <Row>
          <Col
            xs="4"
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className=""
              style={{
                width: "100%",
                height: "3px",
                backgroundColor: step >= 1 ? "#2dce89" : "gray",
                position: "absolute",
                top: "25px",
              }}
            ></div>
            <div
              style={{
                height: "45px",
                width: "45px",
                border: step >= 1 ? "3px solid #2dce89" : "3px solid gray",
                borderRadius: "50%",
                backgroundColor: "#fff",
                zIndex: "1",
              }}
            >
              <span className="btn-inner--icon">
                <i
                  className="ni ni-single-02"
                  style={{ color: step >= 1 ? "#2dce89" : "" }}
                />
              </span>
            </div>
          </Col>
          <Col
            xs="4"
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className=""
              style={{
                width: "100%",
                height: "3px",
                backgroundColor: step >= 2 ? "#2dce89" : "gray",
                position: "absolute",
                top: "25px",
              }}
            ></div>
            <div
              style={{
                height: "45px",
                width: "45px",
                border: step >= 2 ? "3px solid #2dce89" : "3px solid gray",
                borderRadius: "50%",
                backgroundColor: "#fff",
                zIndex: "1",
              }}
            >
              <span className="btn-inner--icon">
                <i
                  className="ni ni-briefcase-24"
                  style={{ color: step >= 2 ? "#2dce89" : "" }}
                />
              </span>
            </div>
          </Col>
          <Col
            xs="4"
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className=""
              style={{
                width: "100%",
                height: "3px",
                backgroundColor: step === 3 ? "#2dce89" : "gray",
                position: "absolute",
                top: "25px",
              }}
            ></div>
            <div
              style={{
                height: "45px",
                width: "45px",
                border: step === 3 ? "3px solid #2dce89" : "3px solid gray",
                borderRadius: "50%",
                backgroundColor: "#fff",
                zIndex: "1",
              }}
            >
              <span className="btn-inner--icon">
                <i
                  className="ni ni-settings-gear-65"
                  style={{ color: step === 3 ? "#2dce89" : "" }}
                />
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const displayStepsHeader = () => {
    return step === 1 ? (
      <h3 className="text-center">Admin Information</h3>
    ) : step === 2 ? (
      <h3 className="text-center">Client</h3>
    ) : step === 3 ? (
      <h3 className="text-center">Authority</h3>
    ) : null;
  };

  const handleChangeStep = () => {
    validatePayload();
    if (
      !isEmailValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      // !isPhoneValid ||
      !isPositionValid ||
      !payload.Email ||
      !payload.FirstName ||
      !payload.LastName ||
      // !payload.Phone ||
      !payload.Position
    ) {
      return;
    } else {
      if (step === 1) {
        setStep(2);
      } else if (step === 2) {
        setStep(3);
      }
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleCloseModal = () => {
    if (isUpdated) {
      fetchAPIs();
    }
    setdefaultModal(false);
    setStep(1);
    setPayload({});
    setModalTitle("Add Admin");
    setSelectedAccountant({});
    setAvatar();
    setSelectedClients([]);
    setIsUpdate(false);
    setIsUpdated(false);
    setIsFirstNameValid(true);
    setIsEmailValid(true);
    setIsLastNameValid(true);
    setIsPhoneValid(true);
    setIsPositionValid(true);
    setRemovedClients([]);
  };

  const handleSearch = (e) => {
    let filterString = e.target.value;
    setFilteredData(dataFilterAdvisorOnly(filterString, accountants));
  };

  const handleDelete = (data) => {
    setIsLoading(true);
    setShowBlockUI(true);
    fetch(`/api/accountant/deleteRecord/${data.id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.message === "Record has been deleted") {
          getDetails();
          fetchAPIs();
          setShowBlockUI(false);
          successAlert("Record has been deleted successfully");
        }
        if (data.error) {
          setShowBlockUI(false);
          errorAlert(data.error);
          fetchAPIs();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDelete = (data) => {
    // console.log(data)
    setalert(
      <ReactBSAlert
        warning
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Warning"
        onConfirm={() => handleDelete(data)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        showCancel={true}
        btnSize=""
      >
        <p>
          Are you sure you want to delete{" "}
          <strong className="text-warning">{`${data.User.FirstName} ${data.User.LastName}`}</strong>
          's record?
        </p>
      </ReactBSAlert>
    );
  };

  const confirmUpgrade = (type, message, obj) => {
    setWarning(
      <ReactBSAlert
        warning
        //style={{ display: 'block', marginTop: '-100px' }}
        title={firmDetails?.Details?.Trial || firmDetails?.Details?.Plan?.Code === 'TIER1' ? "Add an Admin? Not Just Yet!" : "Warning"}
        onConfirm={() => type === 'planSubscribe' ? redirectToAboutUsPlanTab() : redirectToPricing()}
        onCancel={() => setWarning(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Continue"
        btnSize=""
        showCancel={true}
      >
        {message}
      </ReactBSAlert>
    );
  };



  const successAlert = (message) => {
    setalert(
      <ReactBSAlert
        success
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Success"
        onConfirm={() => {
          setdefaultModal(false);
          setalert(null);
        }}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const errorAlert = (message) => {
    setalert(
      <ReactBSAlert
        warning
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Warning"
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const redirectToPricing = () => {
    setWarning(null);
    setdefaultModal(false);
    // setShowUpgradeModal(true)
    return history.replace("/client/plans");
  };

  const toggleUpgradeModal = () => {
    setShowUpgradeModal(!showUpgradeModal)
  }

  const handleInputChange = (e) => {
    let target = e.target;
    let val = "";
    if (target.type === "checkbox") {
      val = target.checked;
    } else if (target.type === "file") {
      const reader = new FileReader();
      const file = target.files[0];

      reader.onload = function (upload) {
        setAvatar(upload.target.result);

        let inputElement = target.nextSibling;
        inputElement.value = upload.target.result;
        inputElement.dispatchEvent(new Event("input", { bubbles: true }));
      };
      reader.readAsDataURL(file);
    } else {
      val = target.value;
    }
    let key = target.id;
    if (key === "FirstName") {
      if (!val || !val.match(/\S/g)) {
        setIsFirstNameValid(false);
      } else {
        setIsFirstNameValid(true);
      }
    }
    if (key === "Email") {
      const emailRegExp = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      let result = emailRegExp.test(target.value);
      if (!val || !result || !val.match(/\S/g) || !result) {
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    }
    if (key === "LastName") {
      if (!val || !val.match(/\S/g)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }
    }
    // if (key === "Phone") {
    //   if (!val || !val.match(/\S/g)) {
    //     setIsPhoneValid(false);
    //   } else {
    //     setIsPhoneValid(true);
    //   }
    // }
    if (key === "Position") {
      if (!val || !val.match(/\S/g)) {
        setIsPositionValid(false);
      } else {
        setIsPositionValid(true);
      }
    }
    const newPayload = { ...payload };
    newPayload[key] = val;
    if (key === "AnalyticsDashboardReports" && newPayload[key] === false) {
      newPayload.AnalyticsDashboardReportGreen = false;
      newPayload.AnalyticsDashboardReportOrange = false;
    }
    if (key === "AnalyticsDashboardReportOrange" && newPayload[key] === false) {
      newPayload.AnalyticsDashboardReportGreen = false;
    }
    // console.log(val)
    setPayload(newPayload);
  };

  const handleImgChange = (img) => {
    setAvatar(img);
    setIsAvatarUpdate(true);
  };

  const validatePayload = () => {
    if (!payload.Email) {
      setIsEmailValid(false);
    }
    if (!payload.FirstName) {
      setIsFirstNameValid(false);
    }
    if (!payload.LastName) {
      setIsLastNameValid(false);
    }
    // if (!payload.Phone) {
    //   setIsPhoneValid(false);
    // }
    if (!payload.Position) {
      setIsPositionValid(false);
    }
  };

  const addAccountants = () => {
    // console.log(accountants.length);
    setShowBlockUI(true);
    fetch("/api/accountingFirm/getRecord")
      .then((res) => res.json())
      .then((data) => {
        if (data && !data.error) {
          setShowBlockUI(false);
          // console.log(data);
          // console.log(data?.Details?.Trial);
          // console.log(data?.PricingPlan?.Code === 'TIER1');
          let totalUserCount = data?.ClientUserCount ? data?.ClientUserCount + data?.Accountant.length : 0 + data?.Accountant.length;
          if (data?.Details?.Trial || data?.PricingPlan?.Code === 'TIER1') {
            confirmUpgrade(
              "planSubscribe",
              `In trial or on the Essentials plan, adding more admins isn't possible. To expand your team of admins, upgrade to our Business plan.`,
              null
            )
          } else if (data?.PricingPlan?.Code === 'TIER6') {
            setdefaultModal(true)
          } else if (totalUserCount >= data?.PricingPlan?.Details?.ClientUsers) {
            // console.log(totalUserCount);
            // console.log(firmDetails?.Details?.Plan?.Details?.ClientUsers);
            confirmUpgrade(
              "planUpgrade",
              `The maximum number of Admin(s) has been reached. Do you want to upgrade your plan?`,
              null
            )
          } else {
            setdefaultModal(true);
          }
        } else {
          setShowBlockUI(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowBlockUI(false);
      });
    // userDetails?.User?.Details?.TrialUser
    //   ? confirmUpgrade()
    //   : setdefaultModal(true);
  };

  const confirmAddSeat = () => {
    setWarning(
      <ReactBSAlert
        info
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Info"
        onConfirm={() => {
          setdefaultModal(true)
          setWarning(null)
        }}
        onCancel={() => setWarning(null)}
        confirmBtnBsStyle="info"
        confirmBtnText="Continue"
        btnSize=""
        showCancel={true}
      >
        You are about to add seat for a new Admin. Click continue to proceed.
      </ReactBSAlert>
    );
  }

  const redirectToAboutUsPlanTab = () => {
    setWarning(null);
    setdefaultModal(false);
    sessionStorage.setItem("aboutUsTabView", 2);
    return history.replace("/client/business/details");
  }

  const setShowBlockUIModal = () => {
    return <BlockUI />;
  };

  const handleSave = () => {
    // console.log(payload)
    let newPayload = {
      user: {
        Email: payload.Email,
        FirstName: payload.FirstName,
        LastName: payload.LastName,
        Phone: payload.Phone,
        Avatar: avatar,
        UserName: payload.UserName,
      },
      accountant: {
        Name: payload.Position,
        Description: payload.Description,
      },
      authority: {
        Admin: payload.Admin ? payload.Admin : false,
        PracticeManagerAccess: payload.PracticeManagerAccess
          ? payload.PracticeManagerAccess
          : false,
        AnalyticsDashboardReports: payload.AnalyticsDashboardReports
          ? payload.AnalyticsDashboardReports
          : false,
        AnalyticsDashboardReportOrange: payload.AnalyticsDashboardReportOrange
          ? payload.AnalyticsDashboardReportOrange
          : false,
        AnalyticsDashboardReportGreen: payload.AnalyticsDashboardReportGreen
          ? payload.AnalyticsDashboardReportGreen
          : false,
        PushNotifications: payload.PushNotifications
          ? payload.PushNotifications
          : false,
        Chat: payload.Chat ? payload.Chat : false,
        PermanentDocs: payload.PermanentDocs ? payload.PermanentDocs : false,
        ThirdPartyConnectors: payload.ThirdPartyConnectors
          ? payload.ThirdPartyConnectors
          : false,
      },
      client: selectedClients,
    };
    // console.log('newPayload', newPayload)
    let resData = "";
    setShowBlockUI(true);
    fetch("/api/accountant/createRecord", {
      method: "POST",
      body: JSON.stringify(newPayload),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        if (data.error) {
          setShowBlockUI(false);
          return errorAlert(data.error);
        }
        if (data && !data.error) {
          // if (selectedClients.length >= 1) {
          //   // setTimeout(() => {
          //   fetch(
          //     `/api/customReport/subscribeToWorkspaceByAdvisorProfile/${data.record.id}`,
          //     {
          //       method: "POST",
          //       credentials: "include",
          //       body: JSON.stringify({
          //         selectedClients: selectedClients,
          //       }),
          //     }
          //   )
          //     .then((res) => res.json())
          //     .then((data) => {
          //       // console.log(data)
          //       // successAlert(data.message)
          //       if (data.error) {
          //         setShowBlockUI(false);
          //         handleCloseModal();
          //         errorAlert(
          //           `New Advisor has been added successfully. ${data.error}`
          //         );
          //         successAlert("New Advisor has been added successfully");
          //         fetchAPIs();
          //       }
          //       setShowBlockUI(false);
          //       handleCloseModal();
          //       successAlert("New Advisor has been added successfully");
          //       fetchAPIs();
          //     })
          //     .catch((err) => {
          //       console.log(err);
          //     });
          //   // }, 5000);
          // } else {
          resData = data;
          setShowBlockUI(false);
          handleCloseModal();
          successAlert("New Admin has been added successfully");
          getDetails();
          fetchAPIs();
          // }
        }
      })
      .then(() => {
        if (selectedClients.length >= 1) {
          // setTimeout(() => {
          fetch(
            `/api/customReport/subscribeToWorkspaceByAdvisorProfile/${resData.record.id}`,
            {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                selectedClients: selectedClients,
              }),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              // successAlert(data.message)
              if (data.error) {
                errorAlert(`${data.error}`);
              }
              // successAlert("New Advisor has been added successfully");
            })
            .catch((err) => {
              console.log(err);
            });
          // }, 5000);
        }
      })
      .catch((err) => {
        setShowBlockUI(false);
        console.log(err);
      });
  };

  const handleUpdateAccount = (acctnt, idx) => {
    setSelectedAccountant(acctnt);
    setIsUpdate(true);
    let details = acctnt?.User?.Details;
    if (!acctnt?.User?.Details.Authority)
      details = JSON.parse(acctnt?.User?.Details);
    let authority = details.Authority;
    let selected = [];
    // eslint-disable-next-line

    acctnt.Client.map((clnt) => {
      selected.push(clnt.id);
    });
    setPayload({
      Email: acctnt.User.Email,
      // UserName: acctnt.User.UserName,
      UserName: acctnt.User.UserName
        ? acctnt.User.UserName
        : "",
      FirstName: acctnt.User.FirstName,
      LastName: acctnt.User.LastName,
      Phone: acctnt.User.Phone,
      Avatar: acctnt.User.Avatar,
      Position: acctnt.Name,
      Description: acctnt.Description,
      Client: selected,
      Admin: authority?.Admin ? true : false,
      AnalyticsDashboardReports: authority?.AnalyticsDashboardReports
        ? true
        : false,
      AnalyticsDashboardReportGreen: authority?.AnalyticsDashboardReportGreen
        ? true
        : false,
      AnalyticsDashboardReportOrange: authority?.AnalyticsDashboardReportOrange
        ? true
        : false,
      Chat: authority?.Chat,
      PermanentDocs: authority.PermanentDocs,
      PracticeManagerAccess: authority.PracticeManagerAccess,
      PushNotifications: authority.PushNotifications,
      ThirdPartyConnectors: authority.ThirdPartyConnectors,
      MySQLAccess: authority.MySQLAccess
    });
    setSelectedClients(selected);
    setModalTitle("Update Admin");
    setdefaultModal(true);
  };

  const handleClientSelection = (clients) => {
    // console.log(clients)
    let clientsArray = [];

    for (let index in clients) {
      clientsArray.push(parseInt(index, 10));
    }

    setSelectedClients(clientsArray);
  };

  const confirmUpdate = (type) => {
    // console.log("update-payload", payload, isEmailValid, isFirstNameValid, isLastNameValid, isPhoneValid, isPositionValid, type)
    validatePayload();
    if (type === "info") {
      // console.log("asdfasdf", payload)
      if (
        !isEmailValid ||
        !isFirstNameValid ||
        !isLastNameValid ||
        // !isPhoneValid ||
        !isPositionValid ||
        !payload.Email ||
        !payload.FirstName ||
        !payload.LastName ||
        // !payload.Phone ||
        !payload.Position
      ) {
        return;
      }
    }
    setWarning(
      <ReactBSAlert
        warning
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Warning"
        onConfirm={() =>
          type === "info"
            ? handleUpdateAccountantInfo()
            : type === "clients"
              ? handleUpdateClients()
              : type === "authority"
                ? handleUpdateAuthority()
                : null
        }
        onCancel={() => setWarning(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Confirm"
        btnSize=""
        showCancel={true}
      >
        <p>
          Are you sure you want to apply changes to user:{" "}
          <strong className="text-warning">{`${selectedAccountant.User.FirstName} ${selectedAccountant.User.LastName}`}</strong>
        </p>
      </ReactBSAlert>
    );
  };

  const handleUpdateAccountantInfo = () => {
    // console.log("handleUpdateAccountantInfo")
    setWarning(null);
    setShowBlockUI(true)
    const parameters = {
      user: {},
      accountant: {},
    };

    parameters.user = Object.assign({}, payload);
    parameters.user.Avatar = avatar;

    parameters.accountant = {
      Name: payload.Position,
      Description: payload.Description,
    };

    delete parameters.user.Position;
    delete parameters.user.Description;
    delete parameters.user.Client;
    delete parameters.user.authority;

    fetch(
      `/api/accountant/updateAccountantInformation/${selectedAccountant.id}`,
      {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(parameters),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        // console.log("handleUpdateAccountantInfo-data", data)
        if (data.error) {
          errorAlert(data.error);
          setShowBlockUI(false)
        }
        if (data && !data.error) {
          setShowBlockUI(false)
          setIsUpdated(true);
          fetchAPIs();
          setWarning(null);
          successAlert("Record has been updated successfully");
        }
      })
      .catch((err) => {
        setShowBlockUI(false)
        errorAlert(err);
        console.log(err);
      });
  };

  const handleUpdateClients = () => {
    setWarning(null);
    setShowBlockUI(true)
    // console.log(selectedClients)
    // console.log(selectedAccountant)

    fetch(`/api/accountant/updateAccountantClient/${selectedAccountant.id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({ client: selectedClients }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          errorAlert(data.error);
        }
        if (data && !data.error) {
          // setIsUpdated(true)
          // fetchAPIs()
          // setWarning(null)
          // successAlert('Record has been updated successfully')
          if (selectedClients.length >= 1) {
            fetch(
              `/api/customReport/subscribeToWorkspaceByAdvisorProfile/${selectedAccountant.id}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  selectedClients: selectedClients,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                // console.log(data)
                // successAlert(data.message)
              });

            fetch(`/api/dwAccess/addUserToDw`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                selectedClients: selectedClients,
                user: selectedAccountant.User.id,
                business: firmDetails.id,
                businessCode: firmDetails.Code
              }),
            })
              .then(res => res.json())
              .then(data => {
                // console.log('/dwAccess/addUser', data)
                // setShowBlockUI(false)
              })
          }
          if (removedClients.length >= 1) {
            fetch(
              `/api/customReport/unsubscribeToWorkspaceByAdvisorProfile/${selectedAccountant.id}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  removedClients: removedClients,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                // console.log(data.message);
              });
            fetch(`/api/dwAccess/removeUserToDw`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                removedClients: removedClients,
                user: selectedAccountant.User.id,
                business: firmDetails.id,
                businessCode: firmDetails.Code
              }),
            })
              .then(res => res.json())
              .then(data => {
                console.log('/dwAccess/removeUserToDw', data)
                setShowBlockUI(false)
              })
          }
        }
      })
      .then(() => {
        setIsUpdated(true);
        fetchAPIs();
        setWarning(null);
        setShowBlockUI(false)
        successAlert("Record has been updated successfully");
      })
      .then(() => {
        setRemovedClients([]);
      })
      .catch((err) => {
        setShowBlockUI(false)
        errorAlert(err);
        console.log(err);
      });
  };
  const handleSetPayload = (payload) => {
    setPayload(payload)
  }
  const handleUpdateAuthority = (MySQLAccess, isEditMySQLAccess = false) => {
    if((MySQLAccess && isEditMySQLAccess) || !MySQLAccess)
      setShowBlockUI(true)

    setWarning(null);

    fetch(`/api/accountant/updateAuthority/${selectedAccountant.id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        Admin: payload.Admin,
        AnalyticsDashboardReportGreen: payload.AnalyticsDashboardReportGreen,
        AnalyticsDashboardReportOrange: payload.AnalyticsDashboardReportOrange,
        AnalyticsDashboardReports: payload.AnalyticsDashboardReports,
        Chat: payload.Chat,
        PermanentDocs: payload.PermanentDocs,
        PracticeManagerAccess: payload.PracticeManagerAccess,
        PushNotifications: payload.PushNotifications,
        ThirdPartyConnectors: payload.ThirdPartyConnectors,
        MySQLAccess: payload.MySQLAccess
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setShowBlockUI(false)
          errorAlert(data.error);
        }
        if (data && !data.error) {
          setShowBlockUI(false)
          setIsUpdated(true);
          fetchAPIs();
          setWarning(null);
          if(!MySQLAccess) {
            successAlert("Record has been updated successfully");
          }
        }
      })
      .catch((err) => {
        setShowBlockUI(false)
        errorAlert(err);
        console.log(err);
      });
  };

  const handleResendEmail = (acctnt) => {
    setWarning(
      <ReactBSAlert
        warning
        //style={{ display: 'block', marginTop: '-100px' }}
        title="Warning"
        onConfirm={() => {
          setShowBlockUI(true);
          fetch(`/api/accountant/resendEmailInvitation/${acctnt.User.UserID}`, {
            method: "GET",
            credentials: "include",
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.error) {
                setShowBlockUI(false);
                setWarning(null);
                return errorAlert(data.error);
              } else {
                setShowBlockUI(false);
                successAlert(data.message);
                setWarning(null);
              }
            });
        }}
        onCancel={() => setWarning(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Confirm"
        btnSize=""
        showCancel={true}
      >
        <p>
          Are you sure you want to re-send email invitation for user:{" "}
          <strong className="text-warning">{`${acctnt.User.FirstName} ${acctnt.User.LastName}`}</strong>
        </p>
      </ReactBSAlert>
    );
  };

  if (userDetails?.User?.UserAccess >= 5) {
    return <Redirect to="/" />;
  }

  return (
    <>
      {alert}
      {warning}
      <UpgradeModal
        bgColor={bgColor}
        textColor={textColor}
        showUpgradeModal={showUpgradeModal}
        toggleUpgradeModal={toggleUpgradeModal}
      />
      <CustomHeader style={null} />
      <Container className="mt-5" fluid>
        <div className="mt-6 fadeIn">
          <div className="nav-wrapper">
            <PanelCard headerName="Admin">
              <Row className="card-wrapper">
                <Col lg="8" sm="4">
                  <div className="mb-3">
                    {userDetails?.User?.UserAccess >= 4 ||
                      !userDetails?.User?.Details?.Authority?.Admin ? null : (
                      <Button
                        style={{
                          backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                          color: `${textColor === "#000" ? "#fff" : textColor}`,
                          borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                            }`,
                        }}
                        size="sm"
                        type="button"
                        onClick={() => addAccountants()}
                        id="addAccountant"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i
                            className="ni ni-fat-add"
                            style={{ fontSize: "1rem" }}
                          />
                        </span>
                        Add Admin
                      </Button>
                    )}
                    {showBlockUI ? setShowBlockUIModal() : null}
                    <Modal
                      className="modal-dialog-top"
                      isOpen={defaultModal}
                      toggle={handleCloseModal}
                      size="xl"
                      backdrop="static"
                    >
                      <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-default">
                          {modalTitle}
                        </h6>
                        <button
                          aria-label="Close"
                          className="close"
                          data-dismiss="modal"
                          type="button"
                          onClick={handleCloseModal}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </div>
                      <div className="modal-body pt-0">
                        {isUpdate ? (
                          <AccountantUpdateModal
                            data={payload}
                            handleInputChange={handleInputChange}
                            selectedAccountant={selectedAccountant}
                            avatar={avatar}
                            clients={clients}
                            selectedClients={selectedClients}
                            removedClients={removedClients}
                            setRemovedClients={setRemovedClients}
                            handleClientSelection={handleClientSelection}
                            authority={
                              selectedAccountant?.User?.Details?.Authority
                            }
                            confirmUpdate={confirmUpdate}
                            isEmailValid={isEmailValid}
                            isFirstNameValid={isFirstNameValid}
                            isLastNameValid={isLastNameValid}
                            isPhoneValid={isPhoneValid}
                            isPositionValid={isPositionValid}
                            handleImgChange={handleImgChange}
                            userDetails={userDetails}
                            firmDetails={firmDetails}
                            handleSetPayload={handleSetPayload}
                            handleUpdateAuthority={handleUpdateAuthority}
                          />
                        ) : (
                          <>
                            {displayStepsIcons()}
                            {displayStepsHeader()}
                            {displayPage(step)}
                          </>
                        )}
                      </div>
                      {isUpdate ? null : (
                        <div className="modal-footer">
                          {step > 1 ? (
                            <Button
                              outline
                              style={{
                                color: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                  }`,
                                borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                  }`,
                              }}
                              type="button"
                              className=""
                              onClick={handleBack}
                            >
                              <span className="btn-inner--icon ml-1">
                                <i className="ni ni-bold-left mr-2" />
                              </span>
                              Back
                            </Button>
                          ) : null}
                          <Button
                            style={{
                              backgroundColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                }`,
                              color: `${textColor === "#000" ? "#fff" : textColor
                                }`,
                              borderColor: `${bgColor === "#fff" ? "#5e72e4" : bgColor
                                }`,
                            }}
                            type="submit"
                            className="ml-auto"
                            onClick={step === 3 ? handleSave : handleChangeStep}
                          >
                            {step < 3 ? (
                              <div>
                                Next
                                <span className="btn-inner--icon ml-1">
                                  <i className="ni ni-bold-right" />
                                </span>
                              </div>
                            ) : (
                              <div>
                                <i className="ni ni-check-bold mr-2" />
                                Save
                              </div>
                            )}
                          </Button>
                        </div>
                      )}
                    </Modal>
                  </div>
                </Col>
                <Col lg="4" sm="8">
                  <div className="mb-3">
                    <Input
                      placeholder="Search"
                      type="text"
                      bsSize="sm"
                      onChange={handleSearch}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {isLoading ? (
                  <Container className="text-center my-5">
                    <BeatLoader color={bgColor} size={10} margin={2} />
                  </Container>
                ) : accountants.length > 0 ? (
                  <AccountantCard
                    data={filteredData}
                    handleUpdateAccount={handleUpdateAccount}
                    confirmDelete={confirmDelete}
                    isEditable={true}
                    showStatus={true}
                    handleResendEmail={handleResendEmail}
                    userDetails={userDetails}
                  />
                ) : (
                  <div className="col-sm-12 mt-3 text-center">
                    <h5> No Admin found.</h5>
                  </div>
                )}
              </Row>
            </PanelCard>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Accountant;
