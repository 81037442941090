import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Row, Table, Col, ModalFooter } from 'reactstrap';
import Modal from 'react-modal';
import CustomTooltip from '../../../customComponents/customComponents';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Papa from 'papaparse';
import { CsvCustomeHeaderCheck } from '../../../utils/utilityFunctions';
import { uploadBlob, pushToQueue } from 'service/AzureBlobService';
import ReactPlayer from "react-player";
import { ClipLoader, SyncLoader, BeatLoader } from "react-spinners";

import { businessTZ } from "utils/utilityFunctions";
import { MainContext } from 'context/mainContext';
const momentTZ = require("moment-timezone");
const RefreshHistoricRecordsModal = ({ onClose, isOpen, firmDetails, selectedClient, integrationDetails, ...props }) => {

  const [currentRefreshingData, setCurrentRefreshingData] = useState([])
  const [currentFormattedRefreshData, setCurrentFormattedRefreshData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [subParentVisibility, setSubParentVisibility] = useState({});
  const [parentVisibility, setParentVisibility] = useState({});
  const {
    textColor,
    bgColor,
  } = useContext(MainContext);

  useEffect(async () => {
    try {
      setIsLoading(true)
      let clientIdentity = `${firmDetails?.Code.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy ? selectedClient?.Details?.Legacy?.id : selectedClient?.id}`
      // const refreshData = await fetch(`/api/clientService/historicRefreshData?orgId=${integrationDetails?.id}&businessId=${firmDetails?.id}&clientId=${selectedClient?.id}&clientIdentity=${clientIdentity}&service=${integrationDetails?.Service?.id}&dbDestination=${integrationDetails?.Details?.dbDestination}`)
      const refreshData = await fetch(`/api/clientService/getRefreshData?orgId=${integrationDetails?.id}&businessId=${firmDetails?.id}&clientId=${selectedClient?.id}&clientIdentity=${clientIdentity}&service=${integrationDetails?.Service?.id}&dbDestination=${integrationDetails?.Details?.dbDestination}`)
      const refreshDataResult = await refreshData.json()
      if(refreshDataResult.data) {
        setCurrentRefreshingData(refreshDataResult.data)
        const data = refreshDataResult.data
        const newData = processGroupings(data)
        processJobStartEndDate(newData)
        setCurrentFormattedRefreshData(newData)
      }

      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      setCurrentRefreshingData([])
    }
  }, [])

  
  const toggleVisibility = (label, setState) => {
    setState((prevVisibility) => ({
      ...prevVisibility,
      [label]: !prevVisibility[label]
    }));
  };

  const toggleSubParentVisibility = (label) => {
    toggleVisibility(label, setSubParentVisibility);
  };

  const toggleParentVisibility = (label) => {
    toggleVisibility(label, setParentVisibility);
  };

  function processJobStartEndDate(newData)
  {
    newData.forEach(item => {
      const { earliestDateTriggered, oldestLastRefreshDate } = item.children.reduce(
        (acc, child) => {
          // Update the earliest dateTriggered
          if (!acc.earliestDateTriggered || new Date(child.children[0].StartDate) < new Date(acc.earliestDateTriggered)) {
            acc.earliestDateTriggered = child.children[0].StartDate;
          }
      
          // Update the oldest lastRefreshDate
          if (!acc.oldestLastRefreshDate || new Date(child.children[0].EndDate) > new Date(acc.oldestLastRefreshDate)) {
            acc.oldestLastRefreshDate = child.children[0].EndDate;
          }
      
          return acc;
        },
        { earliestDateTriggered: null, oldestLastRefreshDate: null }
      );

      item.TriggerType = "-" // item.children[0].TriggerType
      item.LoadType = "-" 
      item.StartDate = earliestDateTriggered
      item.EndDate = oldestLastRefreshDate
      item.RefreshStatus = "-" // item.children[0].RefreshStatus
      item.RefreshDetails = "-"
    })
  }

  function processGroupings(data)
  { 
    const sortedData = data.sort((a, b) => {
      return parseInt(b.JobRunId) - parseInt(a.JobRunId);
    });

    const parent = {}
    const subParent = {}
    const grouped = {};
    const newData = []
    sortedData.forEach(item => {
      const { JobRunId, TableName, ...itemWithoutDbDestination } = item;

      let db = newData.find(item => item.JobRunId === JobRunId ?? 0)

      if(!grouped[JobRunId]) {
        parent[JobRunId ?? 0] = false
        grouped[JobRunId ?? 0] = { JobRunId: JobRunId ?? 0, children: []}
        newData.push({ 
          JobRunId: JobRunId ?? 0, 
          children: [ 
            { 
              TableName, 
              ...itemWithoutDbDestination,
              children: [ itemWithoutDbDestination ] 
            }
          ]
        })
      }
      else {
        const tableItem = { TableName, children: [ itemWithoutDbDestination ]}
        db?.children.push(tableItem)
      }

      // let table = db?.children.find(table => table.TableName === item.TableName)
      
      // if(!table) {
      //   const tableItem = { TableName, children: [ itemWithoutDbDestination ]}
      //   grouped[JobRunId].children.push(tableItem)
      //   db?.children.push(tableItem)
      //   subParent[`${JobRunId}-${TableName}`] = true
      // } else {
      //   table.children.push(itemWithoutDbDestination)
      // }
    });
    // console.log(subParent)
    // setSubParentVisibility(subParent)
    setParentVisibility(parent)
    return newData
  }

  const FormatedTableList = useCallback(({ currentFormattedRefreshData }) => {
    return (
      <tbody className='tbody-histor-logs'>
        {currentFormattedRefreshData && currentFormattedRefreshData.length > 0 && currentFormattedRefreshData.map((dbD, index) => (
          <>
            <tr key={index}>
              <td 
                colSpan={!parentVisibility?.[dbD.JobRunId] ? 2 : 10} 
                onClick={() => toggleParentVisibility(dbD.JobRunId)}
              >
                <i className={`ni ${parentVisibility?.[dbD.JobRunId] ? 'ni-bold-down' : 'ni-bold-right'} mr-1 mt-1`} style={{ }} /> 
                Job ID: {dbD.JobRunId}
              </td>
              {!parentVisibility?.[dbD.JobRunId] &&
                <>
                  {/* <td onClick={() => toggleSubParentVisibility(`${dbD.JobRunId}-${dbD.children[0].TableName}`)}>  
                    <i className={`ni ${!subParentVisibility?.[`${dbD.JobRunId}-${dbD.children[0].TableName}`] ? 'ni-bold-down' : 'ni-bold-right'} mr-1 mt-1`} /> 
                    {dbD.children[0].TableName}
                  </td>
                  <TDDetails data={dbD.children[0].children[0]} /> */}
                   <TDDetails data={dbD} />
                </>
              }
              {/* {index !== 0 && <EmptyTd count={3} />} */}
            </tr>
            
            {parentVisibility?.[dbD.JobRunId] && dbD.children && dbD.children.length > 0 && dbD.children.map((row, index) => (
              <>
                {
                // index !== 99 &&
                  <tr key={index}  className={`td-${row.RefreshStatus ?? row.children[0].RefreshStatus}`} >  { /* style={{ background: '#f7fcff' }}> * /} 
                    {/* <td></td> */}
                    <td colSpan={2} style={{ paddingLeft: 20, fontWeight: 600 }} onClick={() => toggleSubParentVisibility(`${dbD.JobRunId}-${row.TableName}`)}>
                      {/* <i className={`ni ${!subParentVisibility?.[`${dbD.JobRunId}-${TableName.TableName}`] ? 'ni-bold-down' : 'ni-bold-right'} mr-1 mt-1`} />  */}
                       {row.TableName}
                    </td>
                    {/* {index !== 0 && <EmptyTd count={3} />} */}
                    <TDDetails data={row.children[0]} />
                  </tr>
                }

                {/* {TableName.children && TableName.children.length > 0 && TableName.children.map((child, index) => (
                  index !== 0 &&
                  <tr key={index} style={{ background: '#f9f9f9' }}>
                    <EmptyTd count={2} />
                    <td colSpan={2}></td>
                    <TDDetails data={child} />
                  </tr>
                ))} */}
              </>
            ))}
          </>
        ))}
      </tbody>
    )
  }, [currentFormattedRefreshData, subParentVisibility, parentVisibility])
  const getStatusIcon = (status) => {
    switch (status) {
      case "-":
        return "-";
      case "Completed":
        return <i className="fas fa-check text-success" />;
      case "Failed":
          return <i className="fas fa-times text-warning" />;
      default:
        return <i className="fas fa-hourglass-half text-primary" />;
    }
  };
  const validateRemoveDuration = (RefreshStatus) => {
    if(RefreshStatus === "Completed" || RefreshStatus === "Failed")
      return true
    return false
  }
  const TDDetails = ({data}) => {
    return (
      <>
      <td style={{ width: 27 }}>{getStatusIcon(data.RefreshStatus)}</td>
      <td>{data.TriggerType}</td>
      <td>{data.LoadType}</td>
      <td>{FormattedDate(data.StartDate)}</td>
      <td>{validateRemoveDuration(data.RefreshStatus) ? FormattedDate(data.EndDate): '-'}</td>
      <td style={{ fontWeight: 600 }}>{validateRemoveDuration(data.RefreshStatus) ? getDuration(data.EndDate, data.StartDate) : '-'}</td>
      <td style={{ fontWeight: 600 }}>{data.RefreshStatus}</td>
      <td>{data.RefreshDetails}</td>
    </>
    )
  }
  const EmptyTd = ({ count }) => {
    return (
      Array.from({ length: count }).map((_, i) => (
        <td key={i}></td>
      ))
    )
  }
  const FormattedDate = (timestamp) => {
    const date = new Date(timestamp);
    const readableDate = date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    });

    return readableDate.replace(" at ", ", ")
  }
  const getDuration = (startDate, endDate) => {
    const createdDate = new Date(startDate);
    const updatedDate = new Date(endDate);

    const differenceInMilliseconds = createdDate - updatedDate;

    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    const duration = `${hours > 0 ? hours + ' hours, ' : '' } ${minutes > 0 ? minutes + ' minutes, ' : ''} ${seconds} seconds`;

    return duration
  }
  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: '80%',
            height: '80%'
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose()
        }
        }
        contentLabel="Upload CSV Modal"
      >
        <Row className="lg-12 pl-3">
          <h2>Last Refresh History</h2>
        </Row>
        <div className='CSVList-Container'>
          {
            isLoading ?
              <>
                Loading <BeatLoader size={3} />
              </>
              :
              currentRefreshingData.length < 1 ?
                <>
                  <p className="text-center mt-3">No data found.</p>
                </>
                :
                <Table className='CSVHistoryList'>
                  <thead style={{ position: 'sticky', zIndex: 100, top: -1, background: bgColor, color: textColor }}>
                    <tr>
                      {/* <td style={{ fontWeight: "bold" }}>Table Name</td>
                      <td style={{ fontWeight: "bold" }}>Type</td>
                      <td style={{ fontWeight: "bold" }}>Date Initiated</td>
                      <td style={{ fontWeight: "bold" }}>Last Refresh Date</td> */}
                      
                      <td style={{ fontWeight: "bold", width: 50 }}>Job ID</td>
                      <td style={{ fontWeight: "bold" }}>Table Name</td>
                      <td style={{ fontWeight: "bold" }}></td>
                      <td style={{ fontWeight: "bold" }}>Trigger Type</td>
                      <td style={{ fontWeight: "bold" }}>Load Type</td>
                      <td style={{ fontWeight: "bold" }}>Start Date/Time</td>
                      <td style={{ fontWeight: "bold" }}>Completed Data/Time</td>
                      <td style={{ fontWeight: "bold" }}>Duration</td>
                      <td style={{ fontWeight: "bold" }}>Status</td>
                      <td style={{ fontWeight: "bold" }}>Details</td>
                    </tr>
                  </thead>
                  {/* <tbody>
                    {
                      currentRefreshingData.length > 0 && currentRefreshingData.map((data, i) => (
                        <tr key={i}>
                          <td>{data.TableName}</td>
                          <td>{data.dbDestination}</td>
                          <td>{firmDetails.TimeZone ? momentTZ.tz(data.dateTriggered) : businessTZ(firmDetails.CountryCode, data.dateTriggered, true)}</td>
                          <td>{firmDetails.TimeZone ? momentTZ.tz(data.lastRefreshDate) : businessTZ(firmDetails.CountryCode, data.lastRefreshDate, true)}</td>
                        </tr>
                      ))
                    }

                  </tbody> */}
                  <FormatedTableList 
                    currentFormattedRefreshData={currentFormattedRefreshData} 
                  />
                </Table>
          }
        </div>
        <ModalFooter className='pt-2 pb-0 pr-0'>
          <Button onClick={() => {
            onClose()
          }}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default RefreshHistoricRecordsModal;