import { getRandomLightColor } from "./EVAService";

const processGraphView = (item, layout, headerToMultiSelect, visualData, dataValue) => {
    // console.log(item)

    if(item.visual) {
       
        item.tempVisual.width = layout && layout !== undefined ? layout.w : item.visual.width;
        item.tempVisual.height = layout && layout !== undefined ? layout.h : item.visual.height;
        item.tempVisual.x = layout && layout !== undefined ? layout.x : item.visual.x;
        item.tempVisual.y = layout && layout !== undefined ? layout.y : item.visual.y;
        return item.tempVisual
    } else {
        if (!visualData || !visualData?.dataLabel) return 
        const res = headerToMultiSelect.filter((e) => e.name === visualData.dataLabel);
        if(res.length === 0) return 
        const visual = {
            "selectedViewOption": item.visual_type,
            "selectedGroups": getDataValue(dataValue && dataValue.length > 0 && dataValue !== undefined ? dataValue : visualData.dataValue),
            "selectedGroup": visualData.groupings,
            "chartHeader": [],
            "selectedFooter": { id: res[0].id, label: visualData.dataLabel },
            "selectedGraphHeaderOption": null,
            "headerToMultiSelect": headerToMultiSelect,
            "chartData": {
                "labels": [],
                "datasets": []
            },
            "width": layout && layout !== undefined ? layout.w : item.tempVisual.width,
            "height": layout && layout !== undefined ? layout.h : item.tempVisual.height,
            "x": layout && layout !== undefined ? layout.x : item.tempVisual.x,
            "y": layout && layout !== undefined ? layout.y : item.tempVisual.y
        }

        item.tempVisual = visual
        return visual
    }
}
const processPieGraph = (item, layout, headerToMultiSelect, visualData) => {
    // console.log(item)

    if(item.visual) {
       
        item.tempVisual.width = layout && layout !== undefined ? layout.w : item.visual.width;
        item.tempVisual.height = layout && layout !== undefined ? layout.h : item.visual.height;
        item.tempVisual.x = layout && layout !== undefined ? layout.x : item.visual.x;
        item.tempVisual.y = layout && layout !== undefined ? layout.y : item.visual.y;
        return item.tempVisual
    } else {
        if (!visualData || !visualData?.dataLabel) return 
        
        const dataLabel = headerToMultiSelect.filter((e) => e.name === visualData.dataLabel);
        const dataValue = headerToMultiSelect.filter((e) => e.name === visualData.dataValue);

        if(dataLabel.length === 0 || dataValue.length === 0) return 
        const visual = {
            "selectedGroup": visualData.groupings,
            "selectedGroups": [
                {
                    "label": "",
                    "data": "",
                    "color": "rgb(146, 166, 198)",
                    "isOpen": false
                }
            ],
            "chartHeader": [],
            "selectedLabel": { id: dataLabel[0].id, label: visualData.dataLabel },
            "selectedGraphHeaderOption": null,
            "selectedData": { id: dataValue[0].id, label: visualData.dataValue },
            "headerToMultiSelect": headerToMultiSelect,
            "pieItem": [],
            "selectedViewOption": "Pie Chart",
            "width": layout && layout !== undefined ? layout.w : item.tempVisual.width,
            "height": layout && layout !== undefined ? layout.h : item.tempVisual.height,
            "x": layout && layout !== undefined ? layout.x : item.tempVisual.x,
            "y": layout && layout !== undefined ? layout.y : item.tempVisual.y
        }
        item.tempVisual = visual
        return visual
    }
}
const processCardView = (item, layout, headerToMultiSelect, visualData, sqlresult, gridHeader) => {

    setToCardView(gridHeader, sqlresult, item)
    
    if(item.visual) {
       
        item.tempVisual.width = layout && layout !== undefined ? layout.w : item.visual.width;
        item.tempVisual.height = layout && layout !== undefined ? layout.h : item.visual.height;
        item.tempVisual.x = layout && layout !== undefined ? layout.x : item.visual.x;
        item.tempVisual.y = layout && layout !== undefined ? layout.y : item.visual.y;
        return item.tempVisual
    }

    if(item.tempVisual) {
        item.tempVisual.width = layout && layout !== undefined ? layout.w : item.tempVisual.width;
        item.tempVisual.height = layout && layout !== undefined ? layout.h : item.tempVisual.height;
        item.tempVisual.x = layout && layout !== undefined ? layout.x : item.tempVisual.x;
        item.tempVisual.y = layout && layout !== undefined ? layout.y : item.tempVisual.y;
        return item.tempVisual
    } else {
        const visual = {
            "width": layout && layout !== undefined ? layout.w : item.tempVisual.width,
            "height": layout && layout !== undefined ? layout.h : item.tempVisual.height,
            "x": layout && layout !== undefined ? layout.x : item.tempVisual.x,
            "y": layout && layout !== undefined ? layout.y : item.tempVisual.y
        }
        item.tempVisual = visual
        return visual
    }
}
const processGridView = (item, layout, headerToMultiSelect, visualData, sqlresult, gridHeader) => {

    setToCardView(gridHeader, sqlresult, item)

    if(item.visual) {
       
        item.tempVisual.width = layout && layout !== undefined ? layout.w : item.visual.width;
        item.tempVisual.height = layout && layout !== undefined ? layout.h : item.visual.height;
        item.tempVisual.x = layout && layout !== undefined ? layout.x : item.visual.x;
        item.tempVisual.y = layout && layout !== undefined ? layout.y : item.visual.y;
        return item.tempVisual
    }

    if(item.tempVisual) {
        item.tempVisual.width = layout && layout !== undefined ? layout.w : item.tempVisual.width;
        item.tempVisual.height = layout && layout !== undefined ? layout.h : item.tempVisual.height;
        item.tempVisual.x = layout && layout !== undefined ? layout.x : item.tempVisual.x;
        item.tempVisual.y = layout && layout !== undefined ? layout.y : item.tempVisual.y;
        return item.tempVisual
    } else {
        const visual = {
            "width": layout && layout !== undefined ? layout.w : item.tempVisual.width,
            "height": layout && layout !== undefined ? layout.h : item.tempVisual.height,
            "x": layout && layout !== undefined ? layout.x : item.tempVisual.x,
            "y": layout && layout !== undefined ? layout.y : item.tempVisual.y
        }
        item.tempVisual = visual
        return visual
    }
}

const getDataValue = (dataValue) => {
    const res = []
    dataValue && dataValue.map((item, index) => {
        res.push({
            "label": item,
            "data": item,
            "color": defaultControl(index),
            "isOpen": false
        })
    })
    return res
}

const setToCardView = (gridHeader, sqlresult, item) => {
    
    if((sqlresult && sqlresult.length === 1) && ((!item.visual_type || item.visual_type === undefined) || (!item.visual_data || item.visual_data === undefined)) && (!item.visual || item.visual === undefined))
    {
        const card = sqlresult[0]
        const cardItem = []
        card.map((row, index) => {
            cardItem.push({
                "id": index,
                "column": gridHeader[index],
                "columnItem": "",
                "columnItemList": [card[index]],
                "columnToSum": gridHeader[index],
                "sum": card[index],
                "title": "",
                "description": ""
            })
        })
        const headerToMultiSelect = []
        gridHeader.map((item, index) => {
            headerToMultiSelect.push( {
                "id": index,
                "name": gridHeader[index]
            })
        })
        const cardObject = {
            "selectedViewOption": "Card",
            "cardItem": cardItem,
            "headerToMultiSelect": headerToMultiSelect,
            "width": item.tempVisual.width,
            "height": item.tempVisual.height,
            "x": item.tempVisual.x,
            "y": item.tempVisual.y
        }

        item.tempVisual = cardObject
    }else if(sqlresult && sqlresult.length > 1 && item.visual_data && item.visual_type === "Card"){
     
        const columnToSum =[]
        const column =[]
        const cardItem = []

        item.visual_data &&  item.visual_data.map(item => {
            column.push(item.dataLabel)
            columnToSum.push(item.dataValue)
        })

        column.length > 0 && column.map((item, index) => {
            const colIndex = gridHeader.indexOf(item);
            const sumIndex = gridHeader.indexOf(columnToSum[index]);
            const sum = sqlresult.reduce((acc, item) => {
                return acc + item[sumIndex];
            }, 0);

            cardItem.push({
                "id": index,
                "column": gridHeader[colIndex],
                "columnItem": "",
                "columnItemList": sqlresult.map(item => {
                    return item[sumIndex]
                }),
                "columnToSum": gridHeader[sumIndex],
                "sum": sum,
                "title": "",
                "description": ""
            })
        })
        const headerToMultiSelect = []
        gridHeader.map((item, index) => {
            headerToMultiSelect.push( {
                "id": index,
                "name": gridHeader[index]
            })
        })
        const cardObject = {
            "selectedViewOption": "Card",
            "cardItem": cardItem,
            "headerToMultiSelect": headerToMultiSelect,
            "width": item.tempVisual.width,
            "height": item.tempVisual.height,
            "x": item.tempVisual.x,
            "y": item.tempVisual.y
        }
        item.tempVisual = cardObject
    }
}

const defaultControl = (index) => {
    const color = ['#202474'
    ,'#F7C11D'
    ,'#007F0E'
    ,'#13B5EA'
    ,'#E044A7'
    ,'#744EC2'
    ,'#000000'
    ,'#D64550']

    if(index > color.length - 1) return getRandomLightColor()

    return color[index]
}

const ProcessDefaultLinevisual = (sqlResult, header, groupLevel) => {
    const label = sqlResult.map( i => { return i[0] })
    const headerToMultiSelect = header.map((data, index) => {
        return { id: index, name: data }
    })
    const datasets = headerToMultiSelect.slice(1).reduce((acc, header, index) => {
        if (typeof sqlResult[0][index + 1] === 'number') { 
            acc.push({
                label: header.name,
                data: sqlResult.map(i => {
                    return i[index + 1]
                }),
                colIndex: (index + 1) - groupLevel,
                backgroundColor: defaultControl(index),
                borderColor: defaultControl(index)
            });
        }
        return acc;
    }, []);

    const selectedGroups = headerToMultiSelect.slice(1).reduce((acc, header, index) => {
        if (typeof sqlResult[0][index + 1] === 'number') { 
            acc.push({
                label: header.name,
                data: header.name,
                colIndex: (index + 1) - groupLevel,
                color: defaultControl(index),
                isOpen: false
            });
        }
        return acc;
    }, []);

    let LineObject = {
        "selectedViewOption": "Line Chart",
        "selectedGroups": selectedGroups,
        "selectedGroup": null,
        "chartHeader": [],
        "selectedFooter": headerToMultiSelect[0],
        "selectedGraphHeaderOption": null,
        "headerToMultiSelect": headerToMultiSelect,
        "chartData": {
            "labels": label,
            "datasets": datasets
        }
    }
    return LineObject
}

export { processGraphView, processPieGraph, processCardView, processGridView, defaultControl, ProcessDefaultLinevisual }