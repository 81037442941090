import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, Progress } from "reactstrap";
import moment from "moment";
import CustomTooltip from "customComponents/customComponents";
import { ClipLoader, SyncLoader, BeatLoader } from "react-spinners";
import { businessTZ } from "utils/utilityFunctions";
var momentTZ = require("moment-timezone");


const ClientThirdPartyCard = ({ data, i, intDetails, userDetails, firmDetails, ...props }) => {
  const [isUploadingData, setisUploadingData] = useState(true)

  // console.log(intDetails)
  // console.log(userDetails)
  // console.log(data)
  // console.log(firmDetails)

  const notAFunction = () => {
    return null
  }

  return (
    <Col lg="4" xl="3" md="4" sm="6" key={data.id}>
      <Card className="">
        <CardBody className="d-flex flex-column p-2">
          <div style={{ position: "absolute", right: "10px", top: "10px" }}>
            <Row>
              {/* {data.Service.id === 1 ? (
                <Col className="pl-1" style={{ paddingRight: "5px" }}>
                  <CustomTooltip
                    i={i}
                    id={`refresh${i}`}
                    message="Update"
                    className="fas fa-pen fa-xs"
                  />
                </Col>
              ) : null} */}
              {/* {data.Service.id === 24 ? (
                <Col className="pl-1" style={{ paddingRight: "5px" }}>
                  <CustomTooltip
                    i={i}
                    id={`edit${i}`}
                    message="Edit Staff Details"
                    className="fas fa-edit text-sm"
                    onClick={() => props.openStaffList(data)}
                  />
                </Col>
              ) : null} */}
              {data?.Service?.id === 1 && !data?.Details?.BudgetUpdated ? (
                <Col className="ml--4">
                  <CustomTooltip
                    i={i}
                    id={`xero${i}`}
                    message="This Xero Connection has missing budget scope. please click here to reconnect and get the lates updates"
                    onClick={() =>
                      props.handleReconnect(data.Service.id, data.id)
                    }
                    className="fas fa-exclamation-circle text-danger"
                  />
                </Col>
              ) : null}

              {data.Service.id !== 3 && userDetails.User.UserAccess < 5 ? (
                <Col className="pl-1">
                  <CustomTooltip
                    i={i}
                    id={`viewReports${i}`}
                    message="Delete"
                    className="fas fa-times fa-sm"
                    onClick={() => props.confirmDelete(data)}
                  />
                </Col>
              ) : null}
            </Row>
          </div>
          <div className="pt-3 px-5 text-center">
            <img
              alt="..."
              className="img-center rounded img-fluid shadow-none"
              src={data.Service.Logo}
              style={{ height: "80px", objectFit: "contain" }}
            />
          </div>

          <Row className="mb-1">
            <Col sm="12">
              <hr className="my-3" />
            </Col>
            <Col sm="12" className="text-center " style={{ height: "100px" }}>
              <h4>
                {data.Details.Organisation ||
                  data.Details.Name ||
                  data.Details.name ||
                  data.Details.orgkey ||
                  (data.Status === 9 ? "Provisioning" : "Dataset Active")}
              </h4>
              <p className="text-sm mb-0 h5"> {data?.Service?.id === 24 && data?.Details?.dbDestination === 'DW' ? `${data.Service.Name} (DW)` : data.Service.Name}</p>
              <span className="text-xs text-muteds ml-2">
                {`Organisation ID: ${data.Details?.Legacy?.id ?? data.id}`}
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              {data?.Service?.id === 3 ? (
                <>
                  <h5>Workspace ID:</h5>
                  <span className="text-xs text-muteds ml-2">
                    {data.Details.id || ""}
                  </span>
                </>
              ) : data?.Service?.id === 24 ?
                (
                  <Row>
                    <Col xs={data?.Details?.dbDestination === "DW" ? "6" : "12"} className="text-center">
                      <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openStaffList(data)} style={{ cursor: 'pointer' }}>
                        {/* <div onClick={() => props.openStaffList(data)} style={{ cursor: 'pointer' }}> */}
                        <span className="btn-inner--icon">
                          <i className={`fas fa-user-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                          {/* <i className={`fas fa-user-alt`} /> */}
                        </span>
                        <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Users</h5>
                        {/* <h5 className={``}>Users</h5> */}
                      </div>
                    </Col>
                    {
                      data?.Service?.id === 24 && data?.Details?.dbDestination === "DW" ?
                        <>
                          <Col xs="6" className="text-center" >
                            {data.isUploadingData ?
                              <>
                                <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                                <h5>Uploading Data</h5>
                              </>
                              // : <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                              :
                              <div onClick={() => {
                                if (data.Client == 1644 || data.Client == 1518) {
                                  if (data.Status === 9) {
                                    notAFunction()
                                  } else {
                                    props.openUploadCSV(intDetails, data)
                                  }
                                } else {
                                  if (data.Status === 9 || data.Status === 12) {
                                    notAFunction()
                                  } else {
                                    props.openUploadCSV(intDetails, data)
                                  }
                                }
                              }}
                                style={{ cursor: 'pointer' }}>
                                <span className="btn-inner--icon">
                                  {/* <i className={`fas fa-file-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} /> */}
                                  {
                                    data.Client === 1644 || data.Client == 1518 ?
                                      <i className={`fas fa-file-alt ${data.Status === 9 ? "text-muted" : ""}`} />
                                      : <i className={`fas fa-file-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                                  }
                                </span>
                                {/* <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>WIP Ledger</h5> */}
                                {
                                  data.Client === 1644 || data.Client == 1518 ?
                                    <h5 className={`${data.Status === 9 ? "text-muted" : ""}`}>WIP Ledger</h5>
                                    : <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>WIP Ledger</h5>
                                }
                              </div>
                            }
                          </Col>
                          {/* <Col xs="4" className="text-center">
                            <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openJobCostUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                              <span className="btn-inner--icon">
                                <i className={`far fa-calendar-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                              </span>
                              <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Cost</h5>
                            </div>
                          </Col> */}
                        </>
                        : null
                    }
                  </Row>
                )
                : data?.Service?.id === 55 && data?.Details?.dbDestination === "DW" ?
                  <Row>
                    <Col xs={data?.Service?.id === 55 ? "12" : data?.Details?.dbDestination === "DW" ? "4" : "12"} className="text-center">
                      <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openStaffList(data)} style={{ cursor: 'pointer' }}>
                        <span className="btn-inner--icon">
                          <i className={`fas fa-user-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                        </span>
                        <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Users</h5>
                      </div>
                    </Col>
                  </Row>
                  : data?.Service?.id === 68 ?
                    <Row>
                      <Col xs="6" className="text-center" >
                        <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openJobTaskCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                          <span className="btn-inner--icon">
                            <i className={`fas fa-tasks ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                          </span>
                          <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Task</h5>
                        </div>
                      </Col>
                      <Col xs="6" className="text-center" >
                        {data.isUploadingData ?
                          <>
                            <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                            <h5>Uploading Data</h5>
                          </>
                          : <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openIncomeTaxCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`fas fa-hand-holding-usd ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Income Tax</h5>
                          </div>
                        }
                      </Col>
                    </Row>
                    :
                    data?.Service?.id === 69 ?
                      <Row>
                        <Col xs={data?.Details?.dbDestination === "DW" ? "6" : "12"} className="text-center">
                          <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBStaffList(data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`fas fa-user-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Users</h5>
                          </div>
                        </Col>
                        <Col xs="6" className="text-center" >
                          <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`fas fa-file-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Wip Ledger</h5>
                          </div>
                        </Col>
                        {/* <Col xs="4" className="text-center">
                          <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBJobCostUploadCSV(intDetails, data)} style={{ cursor: 'pointer' }}>
                            <span className="btn-inner--icon">
                              <i className={`far fa-calendar-alt ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                            </span>
                            <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Cost</h5>
                          </div>
                        </Col> */}
                      </Row>
                      : data?.Service?.id === 70 ?
                        <Row>
                          <Col xs="6" className="text-center" >
                            <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBJobTaskCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                              <span className="btn-inner--icon">
                                <i className={`fas fa-tasks ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                              </span>
                              <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Job Task</h5>
                            </div>
                          </Col>
                          <Col xs="6" className="text-center" >
                            {data.isUploadingData ?
                              <>
                                <ClipLoader className={"dataloading-spinner"} size={20} margin={2} />
                                <h5>Uploading Data</h5>
                              </>
                              : <div onClick={() => data.Status === 9 || data.Status === 12 ? null : props.openWMBIncomeTaxCSVModal(intDetails, data)} style={{ cursor: 'pointer' }}>
                                <span className="btn-inner--icon">
                                  <i className={`fas fa-hand-holding-usd ${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`} />
                                </span>
                                <h5 className={`${data.Status === 9 || data.Status === 12 ? "text-muted" : ""}`}>Income Tax</h5>
                              </div>
                            }
                          </Col>
                        </Row>
                        :
                        (
                          <div style={{ height: "52px" }}></div>
                        )}
            </Col>
            {/* {
              data?.Client === 1644 || data?.Client === 1846 || data?.Client === 1518 ? */}
            <Col>
              {
                data?.Service?.id !== 3 && data?.Task?.length ?
                  <div className="progress-wrapper">
                    <div className="progress-info">
                      <div className="progress-label" style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "260px",
                        display: "inline-block",
                      }}>
                        <span style={{ fontSize: ".725rem", fontWeight: "normal", textTransform: "none", background: "none", padding: 0 }}>
                          {data?.Task[0].status === 'completed' ? "Previous" : data?.Task[0].status === "pending" ? "Queued" : "Current"} Task: {data?.Task[0].type === "refresh" ? `${data?.Task[0].status === 'completed' ? 'Updated' : 'Updating'} Data` : `${data?.Task[0].status === 'completed' ? "Uploaded" : "Uploading"} ${data?.Task[0].details?.fileName ?? "CSV"} file`}
                        </span>
                      </div>
                      <div className="progress-percentage">
                        <span style={{ fontSize: ".725rem", color: "#32325d" }}>{`${data?.Task[0].progress}`}% Complete {data?.Task[0].progress !== '100' ? <BeatLoader size={3} /> : ""}</span>
                      </div>
                    </div>
                    <Progress max="100" value={data?.Task[0].progress} color="default" style={{ height: '3px', marginBottom: "0px" }} />
                    <div>
                      {
                        data?.Task[0].type === "refresh" && data?.Task[0].status !== 'completed' ?
                          <span style={{ fontSize: ".725rem", fontWeight: "normal", textTransform: "none", background: "none", padding: 0, cursor: "pointer" }} onClick={() => props.showRefreshProgress(data)}>
                            See Details
                          </span>
                          :
                          <span style={{ fontSize: ".725rem", fontWeight: "normal", textTransform: "none", background: "none", padding: 0, color: "rgba(0, 0, 0, 0)" }}>
                            .
                          </span>
                      }
                    </div>
                  </div>
                  :
                  <div className="progress-wrapper">
                    <div className="progress-info">
                      <div className="progress-label" style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "253px",
                        display: "inline-block",
                        marginBottom: "27px"
                      }}>
                        {
                          data?.Service?.id === 24 ?
                            <span style={{ fontSize: ".725rem", fontWeight: "normal", textTransform: "none", background: "none", padding: 0 }}>No Available Task</span>
                            :
                            <span style={{ fontSize: ".725rem", fontWeight: "normal", textTransform: "none", background: "none", padding: 0, color: "rgba(0, 0, 0, 0)" }}>.</span>
                        }
                      </div>
                      {/* <div className="progress-percentage">
                        <span style={{ fontSize: ".725rem", color: "#32325d" }}>{`${data?.Task[0].progress}`}% Complete {data?.Task[0].progress !== '100' ? <BeatLoader size={3}/> : ""}</span>
                      </div> */}
                    </div>
                  </div>
              }
            </Col>
            {/* : null
            } */}
            <Col sm="12">
              <hr className="my-3" />
            </Col>
            <Col xs="12" className="text-left">
              <span className="btn-inner--icon ml-1">
                <i className="far fa-calendar-plus"></i>
              </span>
              <span className="text-xs text-muted ml-2">
                Created At: {
                  props.accountingFirm.TimeZone
                    ? momentTZ
                      .tz(data.createdAt, props.accountingFirm.TimeZone)
                      .format("DD-MM-YYYY")
                    : businessTZ(props.accountingFirm.CountryCode, data.createdAt)
                }
              </span>
            </Col>
            <Col xs="12" className="text-left">
              <span className="btn-inner--icon ml-1">
                <i className="far fa-calendar-plus"></i>
              </span>
              <span className="text-xs text-muted ml-2">
                Last Refresh:{" "}
                {data.LastRefreshDate
                  ? props.accountingFirm.TimeZone
                    ? momentTZ
                      .tz(data.LastRefreshDate, props.accountingFirm.TimeZone)
                      .format("DD-MM-YYYY HH:mm")
                    : businessTZ(props.accountingFirm.CountryCode, data.LastRefreshDate, true)
                  : "No record found"}
              </span>
              {
                data.LastRefreshDate && data.Details?.dbDestination === 'DW' ? // && data.Details?.isDataBricksIntegration ? 
                <span className="text-xs ml-2" style={{ cursor: "pointer" }} onClick={() => props.showHistoricRefresh(data)}>{`- View History`}</span>
                : ""
              }
              
            </Col>
            {
              data.Details?.dbDestination ?
                <Col xs="12" className="text-left">
                  <span className="btn-inner--icon ml-1">
                    <i className="fas fa-database"></i>
                  </span>
                  <span className="text-xs text-muted ml-2">
                    Database: {data.Details?.dbDestination === "SQL" ? "SQL" : "MySQL"}
                  </span>
                </Col>
                :
                <Col xs="12" className="text-left">
                  <span style={{ fontSize: ".725rem", fontWeight: "normal", textTransform: "none", background: "none", padding: 0, color: "rgba(0, 0, 0, 0)" }}>.</span>
                </Col>
            }
            {
              firmDetails.Database === "DB" && data.Details?.isDataBricksIntegration ?
                <>
                  <Col xs="12" className="text-left">
                    <span className="btn-inner--icon ml-1">
                      <i className="far fa-clock"></i>

                    </span>
                    <span className="text-xs text-muted ml-2">
                      First Refresh Schedule:{" "}
                      {data.ScheduledRefresh?.firstRefreshTime ?? "00:00"}
                    </span>
                  </Col>
                  <Col xs="12" className="text-left">
                    <span className="btn-inner--icon ml-1">
                      <i className="far fa-clock"></i>
                    </span>
                    <span className="text-xs text-muted ml-2">
                      Second Refresh Schedule:{" "}
                      {data.ScheduledRefresh?.secondRefreshTime ?? "12:00"}
                    </span>
                  </Col>
                </>
                :
                <div style={{ height: "71px" }}></div>
            }
          </Row>
        </CardBody>
        <div className="card-footer">
          <Row>
            <Col className="text-center">
              {props.showStatus(data.Status, data.Service.id, data.id, i)}
            </Col>
          </Row>
        </div>
      </Card>
    </Col>
  );
};

export default ClientThirdPartyCard;
