import { IconExpand } from 'chat-components/ui/icon';
import { IconMinimize } from 'chat-components/ui/icon';
import VisualCardView from 'components/DashboardVisual/VisualCardView';
import VisualGraphView from 'components/DashboardVisual/VisualGraphView';
import VisualGridView from 'components/DashboardVisual/VisualGridView';
import VisualPieChartView from 'components/DashboardVisual/VisualPieChartView';
import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import GraphView from 'eva-component/response/GraphView';
import GridView from 'eva-component/response/GridView';
import TreeView from 'eva-component/response/TreeView';
import React, { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Button, Col, CustomInput, FormGroup, Input, Label, Row } from 'reactstrap';
import { validateAndReplaceNulls } from 'services/DashboardService';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { UpdateCellWithFormula } from 'services/EVAEditGridService';
import { ProcessSQLGroupWithTotal } from 'services/EVAEditGridService';
import { PopulateNewRows } from 'services/EVAEditGridService';
import { ProcessDnDTreeviewData } from 'services/EVAEditGridService';
import { TransformSQLResult } from 'services/EVAEditGridService';
import { RecalculateWithTotal } from 'services/EVAEditGridService';
import { GetCellIdValue } from 'services/EVAEditGridService';
import { ProcessGroupWithTotal } from 'services/EVAEditGridService';
import { ProcessGrandTotal } from 'services/EVAEditGridService';
import { ProcessGrandParentRow } from 'services/EVAEditGridService';
import { ProcessRemoveRow } from 'services/EVAEditGridService';
import { ProcessRenamingSQLData } from 'services/EVAEditGridService';
import { ProcessEditedGridCellData } from 'services/EVAEditGridService';
import { reFormatEVADateRange } from 'services/EVAResponseService';
import { evaThemeLighter } from 'services/EVAResponseService';
import { PopulateNewEVAResponseFormat } from 'services/EVAResponseService';
import { isWithChildHandler } from 'services/EVAResponseService';
import { convertToTreeViewData } from 'services/EVAResponseService';
import { getRandomLightColor } from 'services/EVAService';
import { processPieGraph } from 'services/EVAVisualService';
import { processGraphView } from 'services/EVAVisualService';

export default function EVAVisualEditDashboard({ name, query , index, item, layout, selectedTemplateItem, onEditVisual, handleCloseEditVisual }) {
    const [isShowGrid, setIsShowGrid] = useState(false)
    const [isShowGrap, setIsShowGrap] = useState(false)
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    const [toggleAll, setToggleAll] = useState(false)
    const [sqlresult, setSqlResult] = useState(null)
    const [gridHeader, setGridHeader] = useState(null)
    const [gridOriginalHeader, setOriginalGridHeader] = useState(null)
    const [EVAData, setEVAData] = useState(null)
    const [treeViewData, setTreeViewData] = useState(null)
    const [isWithChild, setIsWithChild] = useState(false)
    const [loading, setLoading] = useState(true);
    const [isError, setError] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [queryResult, setQueryResult] = useState(null)
    const [visualType, setVisualType] = useState(null)
    const [visualData, setVisualData] = useState(null)
    const [selectedViewOption, setSelectedViewOption] = useState('Grid')
    const [headerToMultiSelect, setHeaderToMultiSelect] = useState([])
    const [templateItemVisual, setTemplateItemVisual] = useState(null)
    const viewOption = ['Grid', 'Card', 'Pie Chart', 'Line Chart', 'Bar Chart']
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [groupLevel, setGroupLevel] = useState(null)
    const theme = { PrimaryColor: bgColor, TextColor: textColor }

    useEffect(() => {
        const processData = async () => {
            try{
                const res = await submitEvaQueryHandler(query, selectedClient, firmDetails, userDetails);
                setQueryResult(res)
                if(!res || res.columns) {
                    setError(true)
                    setLoading(false);
                    return
                }                

                const sqlresult = res.map(obj => Object.values(obj));
                validateAndReplaceNulls(sqlresult)
                const header = res.length > 0 ? Object.keys(res[0]) : [];
                const cleanedHeader = header.map(h => h.replace(/\s*\[.*?\]/g, ''));

                const newRes = await reFormatEVADateRange(cleanedHeader, sqlresult)

                const treeViewData = convertToTreeViewData(sqlresult)
                const isWithChild = isWithChildHandler(treeViewData)
                
                setVisualData(item.visual_data)
                setEVAData(res)
                setSqlResult(sqlresult)
                setGridHeader(cleanedHeader)
                setOriginalGridHeader(cleanedHeader)
                const { result, groupLevel } = PopulateNewEVAResponseFormat(sqlresult, isWithChild, cleanedHeader);
                const newResult = ProcessSQLGroupWithTotal(result, cleanedHeader, groupLevel)


                setGroupLevel(groupLevel)

                const newTreeData = processEditedGridCellData(item, sqlresult, cleanedHeader)

                if(newTreeData) setTreeViewData(newTreeData)
                else setTreeViewData(result)

                setIsWithChild(isWithChild)
                setLoading(false)
                setError(false)
                if(item.visual_type)
                    processDefaultView(sqlresult, item.visual_type)
            }catch (error)
            {
                console.log(error)
                setError(true)
                setLoading(false);
            }
        }
        processData();
    }, [query, newUpdateCount])

    useEffect(() => {
        const loadData = async () => {
            if(layout === undefined && !gridHeader) return
            processDefaultVisual(item, layout)
        }
        loadData();
    },[item, layout, gridHeader])

    useEffect(() => {
        if(templateItemVisual) setSelectedViewOption(templateItemVisual.selectedViewOption)
    },[templateItemVisual])
    const processEditedGridCellData = (item, sqlresult, gridHeader) => {
        if(item && item.editedGridCellData)
        {
            const { gridGroupLevel, newRowList, dragAndDropData, cellFormulaList, renamedSQLData, hiddenRow, renamedHeaderList, addedColumnList } = item.editedGridCellData
            let header = gridHeader
            let sqlData = sqlresult
            let updatedNewRowList = newRowList

            const processNewHeader = (columnHeader, sqlData, newRowList) => {
                let header = [...columnHeader];
                let updatedData = [...sqlData];
                let updateRowList = JSON.parse(JSON.stringify(newRowList));
            
                if (addedColumnList.length > 0) {
                    addedColumnList.map(item => {
                        header.push(item.name);
                        addZeroToRow(updateRowList);
                    });
                    updatedData = sqlData.map(item => [...item, 0]);
                }
            
                function addZeroToRow(updateRowList) {
                    for (let i = 0; i < updateRowList.length; i++) {
                        const row = updateRowList[i];
                        if (row.value) row.value = [...row.value, 0];
                        if (row.children) addZeroToRow(row.children);
                    }
                }
                setGridHeader(header);
                return { header, updatedData, updateRowList };
            };
            
            if(addedColumnList) {
                const headerData = processNewHeader(gridHeader, sqlData, newRowList);
                header = headerData.header
                sqlData = headerData.updatedData
                updatedNewRowList = headerData.updateRowList
            }

            if(renamedHeaderList) header = ProcessColumnRename(renamedHeaderList, header)
            
            let treeData = []

            treeData = TransformSQLResult(sqlData, header, gridGroupLevel)
           

            treeData = PopulateNewRows(updatedNewRowList, sqlData, header, gridGroupLevel)

            if(dragAndDropData.length > 0) 
            {
                treeData = ProcessDnDTreeviewData(dragAndDropData, treeData)
                const isWithGroup = dragAndDropData.filter(g => g.parentId && g.parentId.includes("-group-"))
                if(isWithGroup.length > 0)
                {
                    treeData = RecalculateWithTotal(treeData)
                }
            }

            let cellValue = GetCellIdValue(treeData)

            treeData = ProcessGroupWithTotal(treeData, cellValue, header, gridGroupLevel, cellFormulaList)
        
            const processRenaming = (treeData) => {
                if (renamedSQLData && renamedSQLData.length > 0) {
                    return ProcessRenamingSQLData(renamedSQLData, treeData);
                }
                return treeData;
            };
     
            treeData = ProcessGrandTotal(treeData, cellValue, updatedNewRowList, header, gridGroupLevel, cellFormulaList)
            cellValue = GetCellIdValue(treeData)
            treeData = ProcessGrandParentRow(updatedNewRowList, treeData)
            treeData = processRenaming(treeData);
            treeData = ProcessRemoveRow(treeData, hiddenRow)
            if(cellFormulaList) {
                cellValue = GetCellIdValue(treeData)
                treeData = UpdateCellWithFormula(treeData, cellValue, cellFormulaList)
            }
            return treeData
        }
        return null
    }
    const ProcessColumnRename = (renamedHeaderList, gridHeader) => {
        if (renamedHeaderList?.length === 0 || !renamedHeaderList)
        { 
            setGridHeader(gridHeader);
            return gridHeader
        }

        const newColumnHeader = [...gridHeader]

        renamedHeaderList && renamedHeaderList.map((item, index) => {
            newColumnHeader[item.colIndex] =  item.newHeader
        })

        setGridHeader(newColumnHeader);
        return newColumnHeader
    }
    const processDefaultVisual = (item, layout) => {

        item.visual = item.tempVisual
        if(!gridHeader) return
        const headerToMultiSelect = gridHeader.map((data, index) => {return {
            id: index, name: data
        }})

        setHeaderToMultiSelect(headerToMultiSelect)
        if(visualData && visualData !== undefined || item.visual)
        {
            if(item.visual) {
                setTemplateItemVisual(item.visual)
                setSelectedViewOption(item.visual.selectedViewOption)
            } 
            // else 
            if(item.visual_type === 'Bar Chart' || item.visual_type === 'Line Chart') {
                const visual = processGraphView(item, layout, headerToMultiSelect, visualData)
                setTemplateItemVisual(visual)
            } else if(item.visual_type === 'Pie Chart') {
                const visual = processPieGraph(item, layout, headerToMultiSelect, visualData)
                setTemplateItemVisual(visual)
            } else if(item.visual_type === 'Card') {
                item.tempVisual.width = layout && layout !== undefined ? layout.w : item.tempVisual.width
                item.tempVisual.height = layout && layout !== undefined ? layout.w : item.tempVisual.height
                item.tempVisual.x = layout && layout !== undefined ? layout.w : item.tempVisual.x
                item.tempVisual.y = layout && layout !== undefined ? layout.w : item.tempVisual.y

                setTemplateItemVisual(item.tempVisual)
            }
        }
    }
    const processDefaultView = (sqlresult, visual_type) => 
    {
        setVisualType(capitalize(visual_type))
        setSelectedViewOption(capitalize(visual_type))
    }
    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };
    const handleSelectViewOption = (item) => {
        setSelectedViewOption(item)
    }
    const handleSaveVisual = (param) => {
        const newParam = { ...param };

        param.height = item.tempVisual?.height
        param.width = item.tempVisual?.width
        param.x = item.tempVisual?.x
        param.y = item.tempVisual?.y
        param.selectedViewOption = selectedViewOption

        item.tempVisual = param
        item.visual = param
        item.visual_type = selectedViewOption

        const newItem = { ...item };



        setTemplateItemVisual(item.visual)
        handleCloseEditVisual()
    }
    const handleSaveGridFormula = (param) => {
        item.editedGridCellData = param;
        setTemplateItemVisual(item.visual)
        setNewUpdateCount(prev => prev + 1)
    }
    const ItemContentView = () => {
        return (
            !isError ? 

            <div className='dashboardTemplateItemContainer' style={{ display: 'block', maxHeight: 'none'}}>
                {loading ? <CustomSpinner /> :
                <div className='dashboardSettingContainer'>
                    <>
                        <h3 style={{marginBottom: 30}}>{name}</h3>
                        <div className='templateVisualContainer'>
                            {viewOption.map((item, index) => (
                                <div 
                                    key={index}
                                    className='templateVisualItem' 
                                    style={selectedViewOption === item ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                                    onClick={() => handleSelectViewOption(item)}
                                >{item}</div>
                            ))}
                        </div>
                    </>
                    <div className='templateGraphContainer'>
                        {selectedViewOption === 'Grid' &&
                            <VisualGridView
                                gridHeader={gridHeader}
                                gridOriginalHeader={gridOriginalHeader}
                                sqlresult={sqlresult}
                                theme={theme}
                                item={item}
                                treeViewData={treeViewData}
                                isWithChild={isWithChild}
                                isShowGrid={isShowGrid}
                                toggleAll={toggleAll}
                                isShowGrap={isShowGrap}
                                handleSaveVisual={handleSaveVisual}
                                selectedViewOption={selectedViewOption}
                                onEditVisual={onEditVisual}
                                handleCloseEditVisual={handleCloseEditVisual}
                                handleSaveGridFormula={handleSaveGridFormula}
                                groupLevel={groupLevel}
                            />
                        }
                        {selectedViewOption === 'Card' &&
                            <VisualCardView
                                gridHeader={gridHeader}
                                sqlresult={sqlresult}
                                theme={theme}
                                treeViewData={treeViewData}
                                isWithChild={isWithChild}
                                isShowGrid={isShowGrid}
                                toggleAll={toggleAll}
                                isShowGrap={isShowGrap}
                                handleSaveVisual={handleSaveVisual}
                                templateItemVisual={templateItemVisual}
                                headerToMultiSelect={headerToMultiSelect}
                                selectedViewOption={selectedViewOption}
                                visualType={visualType}
                                onEditVisual={onEditVisual}
                                handleCloseEditVisual={handleCloseEditVisual}
                                groupLevel={groupLevel}
                            />
                        }
                        {(selectedViewOption === 'Bar Chart' || selectedViewOption === 'Line Chart') &&
                            <VisualGraphView
                                gridHeader={gridHeader}
                                selectedViewOption={selectedViewOption}
                                sqlresult={sqlresult}
                                headerToMultiSelect={headerToMultiSelect}
                                handleSaveVisual={handleSaveVisual}
                                templateItemVisual={templateItemVisual}
                                item={item}
                                onEditVisual={onEditVisual}
                                handleCloseEditVisual={handleCloseEditVisual}
                                groupLevel={groupLevel}
                                treeViewData={treeViewData}
                            />
                        }
                        {selectedViewOption === 'Pie Chart' &&
                            <VisualPieChartView
                                gridHeader={gridHeader}
                                selectedViewOption={selectedViewOption}
                                sqlresult={sqlresult}
                                headerToMultiSelect={headerToMultiSelect}
                                handleSaveVisual={handleSaveVisual}
                                templateItemVisual={templateItemVisual}
                                onEditVisual={onEditVisual}
                                handleCloseEditVisual={handleCloseEditVisual}
                                groupLevel={groupLevel}
                                treeViewData={treeViewData}
                            />
                        }
        
                    </div>
                </div>
                }
            </div>
            :
            <>
                <div className="dashboardItems" style={{
                    height: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', // Center horizontally
                    justifyContent: 'center', // Center vertically
                    flexWrap: 'wrap'
                }}>
                    <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No data found</div>
                    <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                </div>
            </>
        )
    }
    const ItemFullScreenView = () => {
        return (
            <div className='ItemFullScreenViewContainer'>
                <div  className='ItemFullScreenViewContent'>
                    <ItemContentView />
                </div>
            </div>
        )
    }
    return (
        isFullScreen ? 
        <ItemFullScreenView /> :
        <ItemContentView />
        // </Col>
    )
}