import CustomSpinner from 'components/Misc/CustomSpinner';
import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'reactstrap';
import { getCustomGroups } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import GroupDashboards from 'components/CustomDashboardDetails/GroupDashboards';
import CustomDashboardPanelCard from 'components/Cards/CustomDashboardPanelCard';
import { groupDashboards } from 'services/mongoDB';
import GroupDashboardList from 'components/CustomDashboardDetails/GroupDashboardList';
import CustomDashboardDetails from 'components/CustomDashboardDetails/CustomDashboardDetails';
import GroupDashboardDetails from 'components/CustomDashboardDetails/GroupDashboardDetails';
import { updatePersonalDashboard } from 'services/mongoDB';
import { groupDashboardsBI } from 'services/mongoDB';
import ReportView from 'components/CustomDashboardDetails/ReportView';
import { deleteGroupDashboardBI } from 'services/mongoDB';
import { deleteGroupDashboard } from 'services/mongoDB';
import EVA from 'eva-component/EVA';
import Chat from 'chat-components/Chat';
import socket from 'chat-components/utils/socket';
import { generateUUID } from 'services/EVAResponseService';
import { mongoFetchToken } from 'services/mongoDB';
import SendMultiDeviceNotification from 'services/NotificationService';
import SendExpoNotifications from 'services/ExpoNotificationService';
import EVADashboard from 'eva-component/EVADashboard';
import DashboardItemDnD from './DashboardItemDnD';


const GroupDashboard = ({evaIframeUrl, noEvaAccess, editMode, showEVA, showDashboardOption}) => {
    const { bgColor, textColor, userDetails, firmDetails, selectedClientID, selectedClient } = useContext(MainContext);
    
    const [loading, setLoading] = useState(true);
    const [newUpdateCount, setNewUpdateCount] = useState(0);

    const [alert, setAlert] = useState(false);
    const [showChat, setShowChat] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [activeGroup, setActiveGroup] = useState(null)
    const [selectedGroupName, setSelectedGroupName] = useState('')
    const [groupDashboardList, setGroupDashboardList] = useState(null)
    const [groupList, setGroupList] = useState(null)
    const [onResizeItem, setOnResizeItem] = useState(false)

    useEffect(() => {
        let isMounted = true;
        setLoading(true)
        
        const loadData = async () => {
            if (isMounted) {
                const param = {
                    'ClientId': selectedClientID,
                    'email': userDetails.User.Email ? userDetails.User.Email : ''
                };
                
                const res = await getCustomGroups(param);
                const users = []
                selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
                const groups = [{
                    "_id": selectedClient.ClientID,
                    "Users": users,
                    "GroupName": selectedClient.Name,
                    "ClientId": selectedClient.ClientID
                }]
                const newRes = groups.map(item => { return item }).concat(res.map(item => {return item}))
                const newList = [];
                console.log(newRes)

                const promises = newRes.map(async (group) => {
                    const dashboardData = await fetchGroupDashboardByGroupId(group);
                    newList.push(dashboardData);
                });
    
                await Promise.all(promises);
                const combinedArray = newList.flat(); // Flatten the nested arrays
    
                setGroupDashboardList(combinedArray);
                setGroupList(newRes);
                setLoading(false);
            }
        };
    
        loadData();
    
        return () => {
            isMounted = false;
        };
    }, [selectedClientID, selectedClient, newUpdateCount, editMode]);
    
    const fetchGroupDashboardByGroupId = async (group) => {
        const param = { clientId: group.ClientId, groupId: group._id };
    
        const res = await groupDashboards(param);
        const resBI = await groupDashboardsBI(param);
    
        const combinedArray = res.map((item) => ({ ...item, isBI: false, groupName: group.GroupName }))
            .concat(resBI.map((item) => ({ ...item, isBI: true, groupName: group.GroupName })));
    
        combinedArray.sort((a, b) => {
            const dateA = a.createdAt ? new Date(a.createdAt) : null;
            const dateB = b.createdAt ? new Date(b.createdAt) : null;
            if (!dateA && dateB) return 1;
            if (dateA && !dateB) return -1;
            return dateB - dateA;
        });
    
        return combinedArray;
    };
// #region group list
    

    const handleActiveGroup = (index) => {
        if (activeGroup === index)
            return { border: `3px solid ${bgColor}` }
        else
            return { border: `3px solid #fff` }
    }
    const handleSelectGroup = async (group, index) => {
        // console.log(group)
        handleShowBIReport(null, false)
        setIsLoadingDashboardList(true)
        setGroupDashboardList(null)
        setActiveDashboard(null)
        setSelectedGroup(group)
        setActiveGroup(index)

        if(!group)
        {
            const newList = [];
    
            const promises = groupList.map(async (group) => {
                const dashboardData = await fetchGroupDashboardByGroupId(group);
                newList.push(dashboardData);
            });

            await Promise.all(promises);
            const combinedArray = newList.flat(); // Flatten the nested arrays

            setGroupDashboardList(combinedArray);
        }else{
            const dashboardData = await fetchGroupDashboardByGroupId(group);
            setGroupDashboardList(dashboardData);
        }


        setIsLoadingDashboardList(false)
    }
//#endregion

// #region group dashboard list
    const [isLoadingDashboardList, setIsLoadingDashboardList] = useState(false);
    const [selectedDashboardName, setSelectedDashboardName] = useState('')
    const [selectedDashboard, setSelectedDashboard] = useState(null)
    const [activeDashboard, setActiveDashboard] = useState(null)
    const [itemIndexToEdit, setItemIndexToEdit] = useState(-1)
    const [showDashboardList, setShowDashboardList] = useState(false)

    const handleIsActiveGroupDashboard = (index) => {
        if (activeDashboard === index)
            return { border: `3px solid ${bgColor}` }
        else
            return { border: `3px solid #fff` }
    }
    const handleSelectGroupDashboard = (dashboard, index) => {
        setSelectedReportData(null)
        setShowReport(!showReport)
        setSelectedDashboard(null)
        setActiveDashboard(null)

        if(!dashboard || dashboard === undefined) return
        setSelectedDashboard(dashboard)
        setActiveDashboard(index)
        setSelectedDashboardName(capitalize(dashboard.name));
        // console.log(dashboard.details)
        setDashboardItems(dashboard.details)

        if (dashboard.isBI) {
            setTimeout(() => {
                handleShowBIReport(dashboard.details, true);
            }, 200);
        }
    }
    const showDeleteGroupDashboardListConfirmation = (data, index) => {
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => deleteDashboard(data, index)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                showCancel={true}
                btnSize=""
            >
                <p>
                    Are you sure you want to delete {data.name}?'
                </p>
            </ReactBSAlert>
        );
    };
    const deleteDashboard = async (data, index) => {
        try {
            const param = { clientId: data.clientId, groupId: data.groupId, _id: data._id }
            const newGroupDashboardList = groupDashboardList.filter(item => item._id !==  param._id)

            if(data.isBI)
                await deleteGroupDashboardBI(param)
            else
                await deleteGroupDashboard(param)

            const alertMessage = `You have successfully deleted the ${data.name}`
            handleSuccessDashboardUpdate(alertMessage)
            // 
            setGroupDashboardList(newGroupDashboardList)

            if(index === activeDashboard)
            {
                setSelectedDashboard(null)
                setSelectedReportData(null)
                setShowReport(false)
            }

            const createdBy = userDetails.User.FirstName + ' ' +userDetails.User.LastName
            const notifications ={
                uid: await generateUUID(),
                id: data.groupId,
                clientId: selectedClientID,
                message: `${createdBy} removed the ${data.name} dashboard from ${data.groupName} group's dashboard`,
                createdAt: new Date(),
                sender: userDetails.User.Email,
                isDashboard: true,
                url: '/client/customDashboard'
            }

            const res = groupList.filter((item) => item._id === data.groupId)
            const selectedGroup = res[0]
            let msg = notifications.message

            await socket.emit("newGroupNotification", notifications);
            let users = []
            if (selectedGroup && selectedGroup.UserList && Array.isArray(selectedGroup.UserList.users)) {
                users = selectedGroup.UserList.users.filter((item) => item !== userDetails.User.Email);
            } else {
                console.warn("selectedGroup or selectedGroup.UserList.users is not defined or not an array");
                users = [] // Set an empty array or handle it as per your requirements
            }
            sendNotificationHandler(users, false, msg, selectedGroup.GroupName)
        } catch (error) {

        }
    }
    const sendNotificationHandler = async (users, isRemoved, msg, groupName) => {
        const createdBy = userDetails.User.FirstName + ' ' +userDetails.User.LastName
        const ClientID = selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body: msg,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : selectedClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = clientToken.tokens
        .filter(token => {
            return users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
        })
        .map(token => token.token);
    
        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'dashboard'},
          notification: notification,
        };
        
        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };
    const handleSuccessDashboardUpdate = (msg, data) => {
        setAlert(
            <ReactBSAlert
                success
                title="Success"
                onConfirm={async () => {
                    setNewUpdateCount(prevCount => prevCount + 1);
                    setAlert(null)
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
//#endregion

// #region group dashboard item list
    const [selectedReportData, setSelectedReportData] = useState(null)
    const [showReport, setShowReport] = useState(false)
    const [dashboardItems, setDashboardItems] = useState([])

    const handleDeleteDashboardItem = (data) => {
        setAlert(
            <ReactBSAlert
                warning
                title="Warning"
                style={{ display: "block", marginTop: "-100px" }}
                onConfirm={() => deleteDashboardItem(data)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="warning"
                confirmBtnText="Ok"
                showCancel={true}
                btnSize=""
            >
                <p>
                    Are you sure you want to delete this Item?
                </p>
            </ReactBSAlert>
        );
    }
    const handleSaveName = async (data) => {
        try {
            updatePersonalDashboard(selectedDashboard)
            const alertMessage = `You have successfully updated the name of the selected Item`
            handleSuccessUpdate(alertMessage, selectedDashboard.details)
        } catch (error) {

        }
    }
    const handleShowBIReport = (data, showReport) => {
        setSelectedReportData(data)
        setShowReport(showReport)
    }

    useEffect(() => {

    }, [showReport, selectedReportData, selectedDashboard])

//#endregion

    const capitalize = (str) => {
        return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    };

    const deleteDashboardItem = async (data) => {
        const newData = dashboardItems.filter(item => !(item._id === data._id && item.uid === data.uid));
        selectedDashboard.details = newData
        try {
            updatePersonalDashboard(selectedDashboard)
            const alertMessage = `You have successfully deleted the seleted Dashboard Item`
            handleSuccessUpdate(alertMessage, selectedDashboard.details)
        } catch (error) {

        }
    }

    const handleSuccessUpdate = (msg, data) => {
        setAlert(
            <ReactBSAlert
                success
                title="Success"
                onConfirm={async () => {
                    setNewUpdateCount(prevCount => prevCount + 1);
                    setAlert(null)
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const toggleShowChat = () => {
        setShowChat(!showChat)
    }
    const handleNewUpdate = () => {
        setNewUpdateCount(prevCount => prevCount + 1);
    }
    const handleSetItemIndexToEdit = (index) => {
        setItemIndexToEdit(index)
    }
    const handleShowDashboardList = () => {
        setShowDashboardList(!showDashboardList)
    }
    
    return (
        loading ? <CustomSpinner /> :
        (groupList ? 
            <>
                {alert}
                {/* group list  */}
                    <div className='col'>

                    
                    <Row>
                        <GroupDashboards
                            showDashboardList={showDashboardList}
                            groupList={groupList}
                            groupDashboardList={groupDashboardList}
                            handleActiveGroupDashboard={handleActiveGroup}
                            handleSelectGroup={handleSelectGroup}
                            handleIsActiveGroupDashboard={handleIsActiveGroupDashboard}
                            handleSelectGroupDashboard={handleSelectGroupDashboard}
                        />
                    </Row>
                    {isLoadingDashboardList ? <CustomSpinner /> :
                        <>
                            {editMode && showDashboardList && groupDashboardList && groupDashboardList.length > 0 &&
                                <Row> 
                                    <GroupDashboardList 
                                        editMode={editMode}
                                        groupDashboardList={groupDashboardList}
                                        handleIsActiveGroupDashboard={handleIsActiveGroupDashboard}
                                        handleShowDeleteDashboardListConfirmation={showDeleteGroupDashboardListConfirmation}
                                        handleSelectGroupDashboard={handleSelectGroupDashboard}
                                    />
                                </Row> 
                            }
                        </>
                    }
                
                {/* </CustomDashboardPanelCard> */}
                {/* } */}

                {/* group dashboard item list */}
                
                {(groupDashboardList && groupDashboardList.length > 0 && selectedDashboard && dashboardItems && dashboardItems.length > 0) || editMode &&
                    <Row>
                        <div className="col" style={{ paddingRight : showEVA && 0 }}>
         
                            <DashboardItemDnD
                                    editMode={editMode}
                                    onResizeItem={onResizeItem}
                                    isEditIndex={itemIndexToEdit}
                                    handleSetItemIndexToEdit={handleSetItemIndexToEdit}
                                    selectedDashboardTemplate={selectedDashboard}
                                    selectedTemplate={dashboardItems}
                                    handleNewUpdate={handleNewUpdate}
                                    handleDeleteDashboardTemplateItem={handleDeleteDashboardItem}
                                    handleShowDashboardList={handleShowDashboardList}
                                    showDashboardList={showDashboardList}
                                />
                        </div>

                        {!editMode && showEVA && 
                            <EVADashboard
                                token={evaIframeUrl} 
                                showChat={showChat} 
                                noEvaAccess={noEvaAccess} 
                                selectedClientID={selectedDashboard.groupId}
                                handleNewUpdate={handleNewUpdate} 
                            />
                        }
                    </Row>
                }

                {showReport && selectedReportData && 
                    <ReportView
                        data={selectedReportData} selectedDashboard={selectedDashboard} />
                } 
                </div>
            </>
        :
        <p>No Group Dashboard Found!</p>)
    )
}
export default GroupDashboard