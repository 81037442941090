import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Input, Table } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";


const StaffHistoricRatesModal = ({ StaffHistoricRates, handlesetShowStaffHistoricRates, selectedStaff, dbDetails, fetchStaffList, ...props }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [numericInput, setNumericInput] = useState(0);
  const [_staffHistoricRates, setStaffHistoricRates] = useState([]);
  const [alert, setAlert] = useState(null);
  const handleNumericInput = (event) => {
    const value = event.target.value;
    setNumericInput(value);
  };

  const handleSubmit = () => {
    if (!numericInput) return
    // Implement your submit logic here
    const dateString = selectedDate;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month because it's zero-based
    const day = date.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const newRate = {
      "date": formattedDate,
      "rate": numericInput
    };

    const staffHistoricRates = _staffHistoricRates || [];

    // Find the index of the item with the same date, if it exists
    const existingIndex = staffHistoricRates.findIndex(item => item.date === formattedDate);

    // If an item with the same date is found, replace it; otherwise, push the newRate
    if (existingIndex !== -1) {
      staffHistoricRates[existingIndex] = newRate;
    } else {
      staffHistoricRates.push(newRate);
    }


    setNumericInput(0)
    setSelectedDate(new Date())
    setStaffHistoricRates(staffHistoricRates)
    StaffHistoricRates = staffHistoricRates
    handleDBSave(staffHistoricRates)
  };

  const handleDBSave = (staffHistoricRates) => {
    props.setShowBlockUI(true)
    const payloadData =
    {
      "id": selectedStaff.StaffID,
      "historyRate": JSON.stringify(staffHistoricRates),
      "db": dbDetails
    }
    fetch(`/api/clientService/updateStaffHistoryRate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payloadData)
    })
      .then((response) => response.json())
      .then((response) => {
        props.setShowBlockUI(false)
        fetchStaffList()
        if (response.error) return null

      })
      .catch((err) => {
        props.setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            Something went wrong. Please try again later.
          </ReactBSAlert>
        );
        console.log(err);
      });

  }
  const handleDelete = (date) => {
    const updatedRates = _staffHistoricRates.filter(item => item.date !== date);
    setStaffHistoricRates(updatedRates);
    handleDBSave(updatedRates)
  };

  useEffect(() => {
    try {
      // console.log(selectedStaff)
      const parsedRates = JSON.parse(StaffHistoricRates);
      setStaffHistoricRates(parsedRates);
    } catch (error) {
      console.error("Error parsing StaffHistoricRates:", error);
    }
  }, [StaffHistoricRates]);

  if (!_staffHistoricRates || _staffHistoricRates.length === 0) {
    // return null; 
  }

  return (
    <>
      <div className="HistoricRatesLayout">
        <div className="HistoricRatesContainer">
          <h2>Historic Rate of {selectedStaff.StaffName}</h2>
          <div className="historic-table-container">
            <table>
              <thead>
                <tr>
                  <td>Billable Rate</td>
                  <td>Applies until</td>
                  <td></td> {/* Add a new column for delete */}
                </tr>
              </thead>
              <tbody>
                {_staffHistoricRates && _staffHistoricRates.map((item, index) => (
                  <tr key={index}>
                    <td>{item.rate}</td>
                    <td>{item.date}</td>
                    <td>
                      {/* <button onClick={() => handleDelete(item.date)}>Delete</button> */}
                      <i onClick={() => handleDelete(item.date)} className="far fa-trash-alt"></i>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <input
                      type="number"
                      step="0.01"
                      value={numericInput}
                      onChange={handleNumericInput}
                      placeholder="Enter a number"
                    />
                  </td>
                  <td>

                    {/* <DatePicker
                       selected={selectedDate}
                       onChange={(date) => setSelectedDate(date)}
                       dateFormat="MM/dd/yyyy"
                    /> */}
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      dateFormat="MM/dd/yyyy"
                      className=""
                    // onChange={(date) => setStartDate(date)}
                    />
                  </td>
                  <td>
                    {/* Add a button for adding new items here */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="button-container">
            <Button onClick={handleSubmit}>Add Historic Rate</Button>
            <Button onClick={() => { handlesetShowStaffHistoricRates(false) }}>Close</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffHistoricRatesModal;
