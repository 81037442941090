import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Table,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { MainContext } from "context/mainContext";
import { useHistory } from "react-router";
import ReactPaginate from "react-paginate";
import "./paginatestyles.css";

const PowerBITable = ({ data, handleGoLiveDashboard, handleGoLiveReport, handleShowDashboardSettings, addToFavorite, removeToFavorite, ...props }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const [numberPerPage, setNumberPerPage] = useState(5);
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    // console.log(props.userDetails)
    setPaginatedData(data);
    // console.log(data)
    localStorage.getItem("dashReportNumberPerPage") !== null
      ? setNumberPerPage(localStorage.getItem("dashReportNumberPerPage"))
      : setNumberPerPage(5);
  }, [currentPage, paginatedData, data])

  const offset = currentPage * numberPerPage;

  const handleChangeNumPerPage = (num) => {
    setNumberPerPage(num);
    setCurrentPage(0);
    // Save clientListNumberPerPage
    localStorage.setItem("dashReportNumberPerPage", num);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const currentPageData = () => {
    return paginatedData
      .slice(offset, Number(offset) + Number(numberPerPage))
      .map((data, i) => {
        return (
          <tr key={i}>
            <td>
              {
                data.Type === 1 ?
                  <span className="text-info" id={`type${i}`}>
                    <i className="fas fa-chart-bar"></i>
                  </span>
                  :
                  <span className="text-warning" id={`type${i}`}>
                    <i className="fas fa-tachometer-alt"></i>
                  </span>
              }
              <UncontrolledTooltip
                target={`type${i}`}
                placement="top"
                style={{ fontSize: "smaller", padding: "13px" }}
              >
                {data.Type === 1 ? 'Report' : 'Dashboard'}
              </UncontrolledTooltip>
            </td>
            {/* <td style={{ width: '50px', minWidth: '50px' }} onClick={() => data.isFavorite ? removeToFavorite(data) : addToFavorite(data, i)}>
              <span id={`star${i}`}><i className={`${data.isFavorite ? `fas fa-star` : `far fa-star`}`}></i></span>
              <UncontrolledTooltip
                target={`star${i}`}
                placement="top"
                style={{ fontSize: "smaller", padding: "13px" }}
              >
                {`${data.isFavorite ? `Remove` : `Add`} to favourite`}
              </UncontrolledTooltip>
            </td> */}
            <td className="powerbi-name" style={{ cursor: "pointer" }} onClick={() => data.Type === 1 ? props.openViewReport(data) : props.openViewDashboard(data)}>
              <span id={`powerbiName${i}`}>
                {data.Name}
              </span>
              <UncontrolledTooltip
                target={`powerbiName${i}`}
                placement="top"
                style={{ fontSize: "smaller", padding: "13px" }}
              >
                {`Click to view ${data.Type === 1 ? 'Report' : 'Dashboard'}`}
              </UncontrolledTooltip>
            </td>
            <td>{data.Type === 1 ? 'Report' : 'Dashboard'}</td>
            <td>{statusIndicator(data.Status, i)}</td>
            <td>{` — `}</td>
            <td>{` — `}</td>
            <td>{data.Type === 2 ? `N/A` : data.Details?.Settings?.RLS ? 'On' : 'Off'}</td>
            <td>{data.LastSubmittedBy.FirstName ? `${data.LastSubmittedBy.FirstName} ${data.LastSubmittedBy.LastName}` : ` — `}</td>
            {
              (props.userDetails.User.UserAccess <= 4 || props.userDetails.User.Details.EvaEnabled) ?
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      color=""
                      size="sm"
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {
                        data.Type === 1 ?
                          <>
                            {/* <DropdownItem onClick={() => props.openViewReport(data)}>
                              View Report
                            </DropdownItem> */}

                            {
                              props.userDetails.User.UserAccess <= 4 ?
                                <DropdownItem onClick={() => props.openSettingsModal(data)}>
                                  Settings
                                </DropdownItem>
                                : null
                            }
                            {
                              (props.userDetails.User.UserAccess <= 4) &&
                                data.Details &&
                                data.Details.Settings &&
                                data.Details.Settings.RLS &&
                                data.Details.Settings.Role ?
                                <DropdownItem onClick={() => props.openSingleUserSelectionModal(data)}>
                                  Preview RLS
                                </DropdownItem>
                                : null
                            }
                            {data.Status === 4 && (props.userDetails.User.UserAccess <= 4 || props.userDetails.User.Details.EvaEnabled) ?
                              <DropdownItem onClick={() => props.pinToDashboard(data)}>
                                Pin to Dashboard
                              </DropdownItem>
                              : null
                            }
                            {props.userDetails.User.UserAccess <= 4 && props.userDetails?.User?.Details?.Authority?.AnalyticsDashboardReports ?
                              <DropdownItem onClick={() => props.userDetails.User.UserAccess <= 4 ? props.submitButton(data.Status, data.id, data) : null}>
                                {data.Status === 1 ? `Go Live` : `Hide from Clients`} 
                              </DropdownItem> : null
                            }
                            {props.userDetails.User.UserAccess <= 4 ?
                              <DropdownItem onClick={() => props.downloadPbixReport(data, null, true)}>
                                Download
                              </DropdownItem> : null
                            }

                            {props.userDetails.User.UserAccess <= 4 ?
                              <DropdownItem onClick={() => props.openViewReport(data, null, true)}>
                                Edit
                              </DropdownItem> : null
                            }
                            {props.userDetails.User.UserAccess <= 4 ?
                              <DropdownItem onClick={() => props.clonePBIReport(data)}>
                                Duplicate
                              </DropdownItem> : null
                            }
                            {props.userDetails.User.UserAccess <= 4 ?
                              <DropdownItem onClick={() => props.deletePBIReport(data)}>
                                Delete
                              </DropdownItem>
                              : null
                            }
                          </>
                          :
                          <>
                            {/* <DropdownItem onClick={() => props.openViewDashboard(data)}>
                              View Dashboard
                            </DropdownItem> */}
                            {data.Status === 4 || data.Status === 5 ?
                              <DropdownItem onClick={() => handleShowDashboardSettings(data)}>
                                Settings
                              </DropdownItem>
                              : null
                            }
                            <DropdownItem onClick={() => props.userDetails.User.UserAccess <= 4 ? props.submitDashboardButton(data.Status, data.id, data) : null}>
                              {data.Status === 1 ? `Go Live` : `Hide from Clients`}
                            </DropdownItem>
                          </>
                      }
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
                : null
            }
          </tr>
        )
      })
  }

  const statusIndicator = (statusVal, i) => {
    if (statusVal === 1) {
      return (
        <>
          <Button
            // color="danger"
            id={`btn2${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: "#FF0000", borderColor: "#FF0000" }}
          />
          <UncontrolledTooltip
            target={`btn2${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            Offline: This dashboard is offline and can only be seen by admins
            with access to this client
          </UncontrolledTooltip>
        </>
      )
    }
    else if (statusVal === 5) {
      return (
        <>
          <Button
            // color="warning"
            id={`warning${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: "#FFC711", borderColor: "#FFC711" }}
          />
          <UncontrolledTooltip
            target={`warning${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            This dashboard is for processing and can be seen by other
            accountant.
          </UncontrolledTooltip>
        </>
      );
    }
    else if (statusVal === 4) {
      return (
        <>
          <Button
            // color="success"
            id={`success${i}`}
            className="btn-sm"
            // onClick={() => openModal(obj)}
            style={{ height: 20, width: 20, borderRadius: 10, backgroundColor: "#09B300", borderColor: "#09B300" }}
          />
          <UncontrolledTooltip
            target={`success${i}`}
            placement="top"
            style={{ fontSize: "smaller", padding: "13px" }}
          >
            {/*Edited by Joef: Previous message: This report is live and can be seen by client through their Mobile
            Accounting App.*/}
            Live: This dashboard is live and can be seen by the client users you
            have selected in the settings
          </UncontrolledTooltip>
        </>
      );
    }
  }

  const pageCount = () => {
    return Math.ceil(data.length / numberPerPage);
  };

  return (
    <>
      <Card style={{ minHeight: "50vh" }}>
        <CardHeader className="border-0">
          {/* <h3 className="mb-0">Light table</h3> */}
        </CardHeader>
        {paginatedData && paginatedData.length >= 1 ? (
          // <div className="customReportBIStyle"> { /*added by JP */}
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th className="sort" scope="col">
                  <span><i className="far fa-file"></i></span>
                </th>
                {/* <th className="sort" scope="col">
                    ...
                  </th> */}
                <th className="sort" scope="col" style={{ width: '400px', minWidth: '400px' }}>
                  Name
                </th>
                <th className="sort" scope="col">
                  Type
                </th>
                <th className="sort" scope="col">
                  Status
                </th>
                <th className="sort" scope="col">
                  Refreshed
                </th>
                <th className="sort" scope="col">
                  Est. Next Refresh
                </th>
                <th className="sort" scope="col">
                  RLS
                </th>
                <th className="sort" scope="col">
                  Last Submitted By
                </th>
                {
                  (props.userDetails.User.UserAccess <= 4 || props.userDetails.User.Details.EvaEnabled) ?
                    <th className="sort text-right" scope="col">
                      Action
                    </th>
                    : null
                    }
              </tr>
            </thead>
            <tbody className="list">
              {currentPageData()}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {
                  (props.userDetails.User.UserAccess <= 4 || props.userDetails.User.Details.EvaEnabled) ?
                    <td></td>
                    : null
                }
              </tr>
            </tbody>
          </Table>
          // </div>
        ) : null
        }
        <CardFooter className="py-4">
          {data && data.length >= 1 ? null : (
            <p className="text-center mt-3">No Clients found.</p>
          )}
          <Row>
            <Col>
              <UncontrolledDropdown direction="down" size="sm">
                <DropdownToggle
                  caret
                // style={{background: '#5E72E4', color: '#fff', borderColor: '#5E72E4'}}
                >
                  {`${numberPerPage}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => handleChangeNumPerPage(5)}>
                    {`5 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(15)}>
                    {`15 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(30)}>
                    {`30 `}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleChangeNumPerPage(50)}>
                    {`50 `}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
            <Col>
              <ReactPaginate
                previousLabel={<i className="fas fa-angle-left" />}
                nextLabel={<i className="fas fa-angle-right" />}
                pageCount={pageCount()}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </Col>
          </Row>
        </CardFooter>
      </Card >
    </>
  )
}

export default PowerBITable;