import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap';

function DashboardTemplateItemCardView({index, item, treeViewData, sqlresult, templateItemVisual, layouts, onFullScreenOnly, groupLevel, gridHeader}) {
    const {
        bgColor,
        textColor,
    } = useContext(MainContext);
    const [cardItem, setCardItem] = useState([])
    const [cardWidth, setCardWidth] = useState(null)
    const [fontSizeFactor, setFontSizeFactor] = useState(null)
    const [gridGroupLevel, setGridGroupLevel] = useState(null)

    useEffect(() => {

        // let layoutWidth = 0
        // const { cardItem, headerToMultiSelect } = templateItemVisual
        
        // if(layouts !== undefined) 
        // {
        //     layoutWidth = layouts.lg[index].w
        //     setFontSizeFactor(layoutWidth * 7)
            
        // } else setFontSizeFactor(60)
 
        // if(cardItem && cardItem.length > 0)
        // {
        //     loadCards(cardItem, headerToMultiSelect)
        //     const getPercentage = 100 / cardItem.length
        //     let cardWidth 
        //     if(layoutWidth === 4) cardWidth = 25
        //     else if(layoutWidth === 3) cardWidth = 33
        //     else if(layoutWidth === 2)  cardWidth = 50
        //     else if(layoutWidth === 1)  cardWidth = 100
        //     else cardWidth = getPercentage > 20 ? getPercentage : 20

        //     // setCardWidth(cardWidth)
        // }
        
    },[layouts])

    useEffect(() => {
        // console.log(item)
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
        else setGridGroupLevel(groupLevel)
    }, [item])

    const loadCards = (cardItem, headerToMultiSelect) => {
        gridHeader.map((header, index) => {
            if(headerToMultiSelect[index]?.name && headerToMultiSelect[index].name !== header) headerToMultiSelect[index].name = header
            if(cardItem && cardItem[index] && cardItem[index].column !== header) {
                // console.log(cardItem[index])
                // console.log(header)
                // cardItem[index].column = header
                // cardItem[index].columnToSum = header
            }
        })

        cardItem.forEach((card, index) => {
            let totalAmount = 0;
            let isPercentage = false;
            let percentageSum = 0;
            let percentageCount = 0;

            if(sqlresult.length === 1) {

                let index = 0

                if(card.columnIndexToSum === undefined) {
                    const idx = gridHeader.findIndex(e => e === card.columnToSum)
                    index = idx
                } else index = card.columnIndexToSum

                const amount = sqlresult[0][index]

                if (amount && typeof amount === 'string' && amount.includes("%")) {
                    const cleanedItem = parseFloat(amount.replace("%", ""));
                    if (!isNaN(cleanedItem)) {
                        card.sum = `${cleanedItem.toFixed(2)}%`;
                    }
                } else card.sum = amount

            } else {
                if(item.editedGridCellData) {
                    let currentIndentLevel = 0;
                    const rowsToSum = loopThruItems(card.columnIndex, treeViewData);
                    const totalAmount = calculateAllwithValue(rowsToSum);
                    const isPercentage = card.columnToSum.includes("%")
    
                    if(isPercentage) {
                        const sum = loopThruPercentageItems(rowsToSum)
                        // console.log(sum)
                        card.sum = sum
                    }else {
                        card.sum = totalAmount;
                    }
    
                    function loopThruPercentageItems (rowsToSum)
                    {
                        let sum = 0;
                        for (let i = 0; i < rowsToSum.length; i++) {
                            const row = rowsToSum[i];
                            if (row.value && (!row.id.includes("-TotalRow")) && (currentIndentLevel === 0 || !row.id.includes("-grandParent-"))) 
                            {
                                // console.log(row)
                                if (isNaN(row.value[card.columnIndexToSum]) || 
                                (typeof row.value[card.columnIndexToSum] === 'string' && row.value[card.columnIndexToSum].includes("%"))) 
                                {
                                    const item = row.value[card.columnIndexToSum];
                                    // Check if the item is defined and is a string
                                    if (item && typeof item === 'string') {
                                        const cleanedItem = item.replace("%", "");
                                        sum += parseFloat(cleanedItem)
                                    }
                                }
                                else    
                                    sum += parseFloat(row.value[card.columnIndexToSum]);
                            }
                        }
                        
                        const result = sum / rowsToSum.length
                        
                        if(result > 1 || result < -1)
                            return `${((result) / 100).toFixed(2)} %`
    
                        return `${(result).toFixed(2)} %`
                    }
    
                    function loopThruItems(indentLevel, treeViewData) {
                        const rowsToSum = [];
                        
                        for (let i = 0; i < treeViewData.length; i++) {
                            const row = treeViewData[i];
                            
                            if (currentIndentLevel === card.columnIndex) 
                            {
                           
                                if (card.columnItem !== "") {
                                    if (card.columnItem === row.sqlId) { 
                                        if(row.value ) rowsToSum.push(row);
                                        if(row.children) loopThruChildren(row.children)
                                    }
                                } else {
                                    if(row.value) {
                                        if (!row.id.includes("-TotalRow")) {
                                            rowsToSum.push(row);
                                        }
                                    }
                                }
                                
                                if (row.children) {
                                    const childRowsToSum = loopThruItems(indentLevel, row.children);
                                    rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                                }
                            }
    
                            if (row.children) {
                                currentIndentLevel++;
                                const childRowsToSum = loopThruItems(indentLevel, row.children);
                                rowsToSum.push(...childRowsToSum); // Accumulate results from child nodes
                                currentIndentLevel--;
                            }
                        }
                        return rowsToSum;
    
                        function loopThruChildren(parent)
                        {
                            for (let i = 0; i < parent.length; i++) { 
                                const row = parent[i];
                                if(row.value) {
                                    if(!row.id.includes("-TotalRow"))
                                        rowsToSum.push(row);
                                }
                                if(row.children) loopThruChildren(row.children)
                            }
                        }
                    }
    
                    
    
                    function calculateAllwithValue(rowsToSum) {
                        // console.log(rowsToSum);
                        let sum = 0;
                        
                        function loopThruItems(rowsToSum) {
                            for (let i = 0; i < rowsToSum.length; i++) {
                                const row = rowsToSum[i];
                                if (row.value && (!row.id.includes("-TotalRow")) && 
                                    (currentIndentLevel === 0 || !row.id.includes("-grandParent-"))) {
                                        if(isNaN(row.value[card.columnIndexToSum]))
                                            sum += 0
                                        else    
                                            sum += parseFloat(row.value[card.columnIndexToSum]);
                                }
        
                                if (row.children) {
                                    loopThruItems(row.children);
                                }
                            }
                        }
                        
                        loopThruItems(rowsToSum); // Run the calculation on the collected rows
                        // console.log(sum);
                        return sum;
                    }
                    
                }else {
                    const processItem = (item) => {
                        const res = headerToMultiSelect.filter((e) => e.name === card.columnToSum);
                        if (res.length > 0) {
                            const value = item[res[0].id];
                            if (typeof value === 'string' && value.includes('%')) {
                                isPercentage = true;
                                const numericValue = Number(parseFloat(value.replace('%', '')));
                                if (!isNaN(numericValue)) {
                                    percentageSum += numericValue;
                                    percentageCount++;
                                }
                            } else if (typeof value === 'number') {
                                totalAmount += value;
                            }
                        }
                    };
            
                    if (card.columnItem !== "") {
                        treeViewData.forEach((item) => {
                            if (item.sqlId === card.columnItem) {
                                // processItem(item);
                                // console.log(item)
                                totalAmount += item.value[card.columnIndexToSum]
                            }
                        });
                    } else {
                        treeViewData.forEach((item) => {
                            processItem(item);
                        });
                    }
            
                    if (isPercentage && percentageCount > 0) {
                        card.sum = (percentageSum / percentageCount).toFixed(1) + '%';
                    } else {
                        card.sum = totalAmount.toFixed(2);
                    }
                }
            }
        });

        setCardItem(cardItem);
    }

    const formatAmount = (number) => {
        const isNegative = number < 0;
        const absNumber = Math.abs(number);
        
        if (isNaN(number) && !String(number).includes("%")) return 0;
        if (typeof number === 'string' && number.includes("%")) return number;
    
        if (absNumber >= 1e9) {
            return (isNegative ? "-" : "") + (absNumber / 1e9).toFixed(1) + "B";
        } else if (absNumber >= 1e6) {
            return (isNegative ? "-" : "") + (absNumber / 1e6).toFixed(1) + "M";
        } else if (absNumber >= 1e3) {
            return (isNegative ? "-" : "") + (absNumber / 1e3).toFixed(1) + "K";
        } else {
            return (isNegative ? "-" : "") + Math.round(absNumber);
        }
    };
    const titleSize = (fontSizeFactor) => {
        return fontSizeFactor / 3  >= 20 ? 20 : fontSizeFactor / 3 <= 15 ? 15 : fontSizeFactor / 3
    }
    const splitTextIntoLines = (card, maxLength) => {
        if(card.columnToSum === card.column) return ''
        if(card.columnItem === "grand-total") return ''

        let text = ""
        if(gridGroupLevel) {
            const item = card.columnItemList.find(i => i.id === card.columnItem)
            text = item ? item.row : card.column 
        }else {
            text = typeof card.columnItem === 'string' && card.columnItem !== ''
            ? card.columnItem
            : ''
        }   


        if (text.length <= maxLength) return text;
  

        const lines = [];
        for (let i = 0; i < text.length; i += maxLength) {
            lines.push(text.substring(i, i + maxLength));
        }
    
        return lines.join('\n');
    }
    const cardItemWidth = () => {
        return `calc(${cardWidth}% - 0px)`
    }  
    const containerRef = useRef(null);
    const cardRef = useRef(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const { cardItem, headerToMultiSelect } = templateItemVisual
        if (!cardItem || Object.keys(cardItem).length === 0) return;

        try {
            loadCards(cardItem, headerToMultiSelect)
        }catch(error) {
            console.error(error)
        }


        if (containerRef.current) {
            const w = containerRef.current.offsetWidth
            const cardCount = cardItem.length
            setWidth(w);
            for(let i = cardCount; i >= 1 ; i--) 
            {
                const intCardWidth = w / i
                if(intCardWidth >= 170)
                {
                    let cardWidth = 100 / i
                    setCardWidth(cardWidth)
                    break;
                }
            }
        }
    
        const handleResize = () => {
            if (containerRef.current) {
                // setWidth(w);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);

    }, [layouts]);

    useEffect(() => {
        if (cardRef.current) {
            const c = cardRef.current.offsetWidth
            const fontSize = (c / 2) - 60
            setHeight((c / 2) - 40)
            setFontSizeFactor(fontSize > 75 ? 74: fontSize)
        }
    
        const handleResize = () => {
            if (cardRef.current) {
                // const c = cardRef.current.offsetWidth
                // console.log(c)
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [cardWidth, layouts])

    return (
        <div className='cardContent' ref={containerRef}>
            {cardItem.map((card, index) => (
                <div
                    key={index}
                    style={{ 
                        width : cardItemWidth(), 
                        padding: '0px 5px', 
                        minWidth: 150, 
                        minHeight: 250, 
                        marginBottom: 10, 
                        position: 'relative',
                        maxHeight: 400,
                        maxWidth: 200 
                    }}
                    >
                    <div className='cardContainer' ref={cardRef}>
                        <div style={{  height: `${height}px`, position: 'relative'}}>
                            <div className='cardAmount' style={{ fontSize: fontSizeFactor > 100 ? 100 : fontSizeFactor < 30 ? 30 : fontSizeFactor }}>
                                {formatAmount(card.sum)}
                            </div>
                            <div>
                                <div className='cardTitle' style={{ fontSize: titleSize(fontSizeFactor)}}>
                                    {card.columnToSum.replace("_", " ")}
                                </div>
                                {sqlresult.length > 1 &&
                                    <div className='cardTitleDescription'>
                                        {splitTextIntoLines(card, 25)}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default DashboardTemplateItemCardView