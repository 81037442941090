import { CustomButton } from 'components/Button/CustomButton'
import CustomSpinner from 'components/Misc/CustomSpinner'
import { MainContext } from 'context/mainContext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Button, Col, Container, CustomInput, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, Row, Table } from 'reactstrap'
import { evaThemeLighter } from 'services/EVAResponseService';
import { registerDashboardTemplate } from 'services/mongoDB';
import { dashboardTemplate } from 'services/mongoDB';
import ReactBSAlert from "react-bootstrap-sweetalert";
import DashboardTemplateItem from './DashboardTemplateItem';
import DashboardTemplateItemModal from './DashboardTemplateItemModal';
import PanelCard from 'components/Cards/PanelCard';
import { useHistory } from "react-router";
import DashboardTemplateKPI from './DashboardTemplateKPI';
import { submitEvaQueryHandler } from 'services/DashboardService';
import { forEach, wrap } from 'lodash';
import { generateUUID } from 'services/EVAService';
import ManageDashboardDnD from '../manageDashboard/ManageDashboardDnD';
import EVAVisualEditDashboard from '../manageDashboard/EVAVisualEditDashboard';
import { userDashboardTemplate } from 'services/mongoDB';
import { userDashboardTemplateByUser } from 'services/mongoDB';
import { getCustomGroups } from 'services/mongoDB';
import Select from 'react-select';
import { registerGroupNotification } from 'services/mongoDB';
import SendMultiDeviceNotification from 'services/NotificationService';
import SendExpoNotifications from 'services/ExpoNotificationService';
import { mongoFetchToken } from 'services/mongoDB';
import { etaniDashboardTemplates } from 'services/mongoDB';
// import socket from 'services/MainSocket';
// import socket from 'chat-components/utils/socket';
import XBImage from '../../../assets/img/XB.png';
import XPMImage from '../../../assets/img/XPM.png';
import WFMImage from '../../../assets/img/WFM2.png'
import { updateEtaniDashboardTemplates } from 'services/mongoDB';
import EVAChatDashboard from 'eva-component/EVAChatDashboard';

function DashboardTemplate({ ...props }) 
{
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(true);
    const [alert, setAlert] = useState(false);
    // const [templateList, setTemplateList] = useState(null)
    const [selectedTemplateItem, setSelectedTemplateItem] = useState([]);
    const [newDashboardName, setNewDashboardName] = useState(null);
    const [tabKey, setKey] = useState(null);
    const [dashboardType, setDashboardType] = useState('New');
    const [KPITabKey, setKPITabKey] = useState(null);
    const [templates, setTemplates] = useState([])
    const [integration, setIntegration] = useState([])
    const [categories, setCategories] = useState([])
    const [sub_category, setSubCategories] = useState([])
    const [category_sub_category, setCategorySubCategories] = useState([])
    const [sqlExampleResult, setSQLExampleResult] = useState([])
    const [filteredSqlExampleResult, setFilteredSQLExampleResult] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [onEditVisual, setOnEditVisual] = useState(false)
    const [onEditItem, setOneditItem] = useState(null)
    const [onEditItemIndex, setOneditItemIndex] = useState(null)
    const [newUpdateCount, setNewUpdateCount] = useState(0);
    const [userTemplatesList, setUserTemplateList] = useState([])
    const [selectedExistingDashboard, setSelectedExistingDashboard] = useState(null)
    const [pintoDashboard, setPintoDashboard] = useState('personal');
    const [selectedGroupId, setSelectedGroupId] = useState([]);
    const [groupList, setGroupList] = useState([])
    const [customGroupList, setCustomGroupList] = useState(null)
    const [clientUsers, setClientUsers] = useState([]);
    const [selectGroupUsers, setSelectGroupUsers] = useState(null)
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState(null)
    const [etaniTempaltes, setEtaniTemplates] = useState([])
    const [selectedEtaniTempaltes, setSelectedEtaniTemplates] = useState(null)
    const [toggleShowSettings, setToggleShowSettings] = useState(true)
    const [includesEmail, setIncludesEmail] = useState(false)
    const [evaDateFilters, setEVADateFilters] = useState([])
    const [evaUUID, setEVAUUID] = useState(null)
    const [KPIList, setKPIList] = useState([])
    const {
        bgColor,
        textColor,
        selectedClientID,
        userDetails,
        firmDetails,
        isTemplateEdit,
        selectedTemplatetoEdit, 
        selectedClient,
        selectClientIntegration
    } = useContext(MainContext);


    const history = useHistory();
    const isInitialMount = useRef(true)
    useEffect(() => {
        const email = userDetails?.User?.Email.toLowerCase()
        const specialEmails = [
            "jp@etani.com.au", 
            "fuhad@etani.com.au", 
            "admin@etani-bp.com.au"
        ];
        setIncludesEmail(specialEmails.includes(email))
        
        const loadEVAUUID = async () => {
            const evaUUID = await generateUUID()
            setEVAUUID(evaUUID)
        }

        if(!evaUUID)
            loadEVAUUID()
    },[userDetails])

    useEffect(() => {
        if(!evaUUID || !selectedClientID) return
            processSuggestedDashboard(evaUUID, selectedClientID)
    },[evaUUID, selectedClientID])

    useEffect(()=> {
        const loadTemplates = async () => {
            // const template = await dashboardTemplate()
            // console.log(firmDetails)
            try{
                const res = await submitEvaQueryHandler(`SELECT * FROM _eva_sql_templates WHERE template IS NOT null ORDER BY intergration_id`, selectedClient, firmDetails, userDetails);
                const eva_date_filter = await submitEvaQueryHandler(`SELECT * FROM _eva_date_filters WHERE column_header IS NOT null`, selectedClient, firmDetails, userDetails);
                setEVADateFilters(eva_date_filter)


                
                if(res === undefined || !res) return // history.push(`/client/customDashboard`)
                const newRes = convertWFMtoXPM(res)

                const integrationNames = selectClientIntegration && selectClientIntegration.map(item => item.integration);
              
                const filteredDashboards = integrationNames ? newRes && newRes.filter(dashboard => integrationNames.includes(dashboard.integration)) : [];

                setKPIList(filteredDashboards)

                getTemplates(filteredDashboards)
                const groupedData = groupBy(filteredDashboards, 'template', 'integration', 'category');
                const transformedData = Object.entries(groupedData).map(([key, value]) => {
                    const [template, integration, category] = key.split('-');
                    return {
                        template,
                        integration,
                        category,
                        // details: value.map(item => ({
                        //     sub_category: item.sub_category,
                        //     display_name: item.display_name,
                        //     query: item.query,
                        //     visual_type: item.visual_type,
                        //     visual_data: parseVisualData(item.visual_data) 
                        // }))
                        details: groupBySubCategory(value)
                    };
                });
              
                const etaniTemplate = await etaniDashboardTemplates()
                
                setEtaniTemplates(etaniTemplate)
                setSQLExampleResult(transformedData);
                setFilteredSQLExampleResult(transformedData)
                setIsLoading(false)
            }catch(error)
            {
                console.log(error)
                setIsLoading(false)
            }

            // 
        }
        loadTemplates()
    }, [selectedClient, firmDetails, userDetails])

    const processSuggestedDashboard = useCallback(async (evaUUID, selectedClientID) => {
        const raw = {
            "chatId": evaUUID,
            "query": "dashboard mode, Can you suggest dashboard items for my company?",
            "UserInfo": {
                "chatId": evaUUID,
                "UserId": userDetails.User.UserID,
                "Name": userDetails.User.FirstName,
                "ClientId": selectedClientID,
                "BusinessId": firmDetails.AccountingFirmID 
            }
        };
        try {
            const response = await fetch('https://evabetaprod.azurewebsites.net/eva_stream', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body: JSON.stringify(raw)
            });
            
            const reader = response.body.getReader();
            let chunks = '';
            const streamMessageArray = []

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                chunks += new TextDecoder().decode(value);
                const messagesArray = chunks.split('\n');
                streamMessageArray.push(chunks)
            }

            const newMessage = {
                uuid: evaUUID,
                clientid: selectedClientID,
                userid: userDetails.User.UserID,
                _id: Math.floor(Math.random() * 10000000000000),
                text: streamMessageArray[streamMessageArray.length - 1],
                sender: userDetails.User.UserID,
                user: {            
                    _id: Math.floor(Math.random() * 10000000000000),
                    sender: Math.floor(Math.random() * 10000000000000),
                    name: "EVA"
                },
                createdAt: new Date(),
                isDefaultDashboard: true
            }
        
            handleEVADashboardMessage(newMessage)
        } catch (error) {
            console.log(error)
        }
    },[evaUUID])
    const convertWFMtoXPM = (res) => {
        // console.log(res);
        
        // Filter out items with integration "WFM"
        const removedWFM = res && res.filter(i => i.integration !== "WFM");
        // console.log(removedWFM);
    
        // Filter items with integration "XPM"
        const XPMList = res && res.filter(i => i.integration === "XPM");
        // console.log(XPMList);
    
        // Convert XPM to WFM and modify the query
        const WFMList = XPMList.map((item) => {
            return {
                ...item,
                integration: "WFM",
                query: item.query
                    .replace(/_xpm/g, "_wfm2")
                    .replace(/_wfm2transactions/g, "_wfm2_eva_transactions"),
            };
        });
        // console.log(WFMList);
    
        // Combine removedWFM and WFMList
        const combinedList = [...removedWFM, ...WFMList];
    
        // Sort by integrationid (assumes integrationid can be compared directly)
        const sortedList = combinedList.sort((a, b) => {
            if (a.intergrationid === null) return 1;
            if (b.intergrationid === null) return -1;
            return a.intergrationid - b.intergrationid;
        });
    
        // console.log(sortedList);
        return sortedList
    }
    
    const getTemplates = (res) => {
        const templates = []
        const integration = []
        const categories = []
        const newData = []
        const sub_category = []
        const category_sub_category = []
        // console.log(res)
        if (res && res.length > 0) {
            const templatesSet = new Set();
            const integrationSet = new Set();
            const categoriesSet = new Set();
            const subCategorySet = new Set();
            const categorySubCategorySet = new Set();
            
            res.forEach(item => {
                if (!templatesSet.has(item.template)) {
                    templatesSet.add(item.template);
                    templates.push(item.template);
                }
        
                if (!integrationSet.has(item.integration)) {
                    integrationSet.add(item.integration);
                    integration.push(item.integration);
                }
        
                if (!categoriesSet.has(item.category)) {
                    categoriesSet.add(item.category);
                    categories.push(item.category);
                }
        
                if (item.sub_category && !subCategorySet.has(item.sub_category)) {
                    subCategorySet.add(item.sub_category);
                    sub_category.push(item.sub_category);
                }
        
                const categorySubKey = `${item.category} | ${item.sub_category}`;
                if (item.category && item.sub_category && !categorySubCategorySet.has(categorySubKey)) {
                    categorySubCategorySet.add(categorySubKey);
                    category_sub_category.push({ 
                        category_sub_category: item.category, 
                        sub_category: item.sub_category 
                    });
                }
            });
        }

        if(!tabKey) setKey(integration[0])
        categories.sort((a, b) => a.localeCompare(b));

        setTemplates(templates)
        setIntegration(integration)
        setCategories(categories)
        setSubCategories(sub_category)
        setCategorySubCategories(category_sub_category)
    }
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            // history.push(`/client/customDashboard`);
        }
    }, [selectedClientID, history]);

    useEffect(() => {
        loadUserTemplate()
        loadClientUser(selectedClient?.id)
    },[selectedClientID, selectedClient])
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    const loadClientUser = async (selectedClientID) => {
        if(!selectedClientID || selectedClientID === undefined) return

        await fetch(`/api/user/getAllRecords/${selectedClientID}`)
        .then((res) => res.json())
        .then((data) => {
            if (data && !data.error) {
                const users = []
                data.map((user) => users.push(user.Email));
                setClientUsers(users);
            } else {
                setIsLoading(false)
                return;
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const loadUserTemplate = async () => 
    {
        if (Object.keys(userDetails).length === 0) return false;

        const param = {
            clientId: selectedClientID,
            user: userDetails.User.Email
        }
        const res = await userDashboardTemplateByUser(param)

        const publicTemplate = res.filter((item) => item.userAccessSetting?.accessType === 'public'
            && (item.userAccessSetting?.clientOption === "All Client" || item.userAccessSetting?.selectedClient?.some(client => client.ClientID === selectedClientID))
            && item.businessId === firmDetails.AccountingFirmID
        )

        setUserTemplateList(publicTemplate)
    }
    const fetchClientGroups = async () => {
        if(pintoDashboard === 'personal') return
        const res = await getCustomGroups({ ClientId: selectedClientID, email: userDetails.User.Email })
        setCustomGroupList(res)

        const users = []
        selectedClient && selectedClient.User && selectedClient.User.map(item => users.push(item.Email))
        const groups = [{
            "id": selectedClient.ClientID,
            "Users": users,
            "GroupName": selectedClient.Name,
            "ClientId": selectedClient.ClientID
        }]
        const newRes = groups.map(item => { return { value: item.id, label: item.GroupName } }).concat(res.map(item => {return { value: item.id, label: item.GroupName }}))
        setGroupList(newRes);
    };
    const parseVisualData = (visualData) => {
        try {
            const data = JSON.parse(visualData) 
            return data
        }catch(error){
            return []
        }
    }
    const groupBy = (array, ...keys) => {
        return array.reduce((acc, obj) => {
            const key = keys.map(key => obj[key]).join('-');
            (acc[key] = acc[key] || []).push(obj);
            return acc;
        }, {});
    };
    const groupBySubCategory = (array) => {
        array.sort((a, b) => {
            if (a.sub_category < b.sub_category) return -1;
            if (a.sub_category > b.sub_category) return 1;
            return 0;
        });
        const newData = []
        const groupedData = array.reduce((acc, item) => {
            const { sub_category, display_name, query, visual_type, visual_data, dynamic_query, my_row_id } = item;
            
            let existingEntry = newData.find(entry => entry.sub_category === sub_category ? sub_category : '');
            if(existingEntry) {
                existingEntry.details.push({
                    my_row_id: my_row_id,
                    display_name: display_name,
                    query: query,
                    dynamic_query: dynamic_query,
                    visual_type: visual_type,
                    visual_data: parseVisualData(visual_data) 
                })
            } else {
                existingEntry = { sub_category, details: [{
                    my_row_id: my_row_id,
                    display_name: display_name,
                    query: query,
                    dynamic_query: dynamic_query,
                    visual_type: visual_type,
                    visual_data: parseVisualData(visual_data) 
                }] };
                newData.push(existingEntry);
            }

            // console.log(sub_category)
            // if (!acc[sub_category]) {
            //   acc[sub_category] = [];
            // }
            // acc[sub_category].push(item);

            return acc;
        }, {});
        return newData
    }
    useEffect(() => {
        const loadTemplates = async () => {
            
            if(isTemplateEdit)
            {
                const selectedTemplate = selectedTemplatetoEdit ? selectedTemplatetoEdit : sessionStorage.getItem('selectedTemplatetoEdit')
                
                if(selectedTemplate.isEtaniTemplate) 
                {
                    setIsEtaniTemplate(selectedTemplate.isEtaniTemplate)
                    setNewDashboardName(selectedTemplate.name.split("-")[0])
                    setSelectedTemplateIntegration(selectedTemplate.name.split("-")[1])
                } else {
                    setPintoDashboard(selectedTemplate.isPersonalDashboard ? 'personal' : 'group')
                    setNewDashboardName(selectedTemplate.name)
                }

                selectedTemplate.dashboardTemplate.map((item) => {
                    const findItem = KPIList.find(e => e.my_row_id === item.my_row_id)
                    item.dynamic_query = findItem?.dynamic_query || item.query
                    item.final_dynamic_query = item.query
                })

                setSelectedTemplateItem(selectedTemplate.dashboardTemplate)
                const groupsIds = (selectedTemplatetoEdit?.selectedGroupId?.groupsId || []).map(item => {
                    return groupList.find(group => group.value === item);
                }).filter(group => group !== undefined);                
        
                setDefaultSelectedGroup(groupsIds);
                handleSelectGroupChange(groupsIds)

            }else{
                return
            }
        }

        loadTemplates()
    },[groupList, KPIList])
    useEffect(() => {
        if(selectedTemplateItem.length === 0) return

        const newItem = { ...selectedTemplateItem };


        if(selectedTemplateItem.length === 1) {
            setKPITabKey(selectedTemplateItem[0].name) 
            return
        }
        if(!KPITabKey) {
            setKPITabKey(selectedTemplateItem[0].name) 
            return
        }

        const index = selectedTemplateItem.findIndex((prevItem) => prevItem.name === KPITabKey.name);
        if (index < 0 && selectedTemplateItem.length > 0) setKPITabKey(selectedTemplateItem[0].name)

    },[selectedTemplateItem])

    const handleNewDashboardNameChange = (event) => {
        setNewDashboardName(event.target.value);
    };
    const handleSelectItem = async (item) => {
        item.uid = await generateUUID();
        setSelectedTemplateItem((prevItems) => {
            const index = prevItems.findIndex((prevItem) => prevItem.my_row_id === item.my_row_id);
            if (index > -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(index, 1);
                return updatedItems;
            } else {
                return [...prevItems, item];
            }
        });
    }
    const handleUpdateEtaniTemplate = () => {
        const param = {...selectedEtaniTempaltes}
        param.dashboardTemplate = selectedTemplateItem.map(item => ({
            ...item,
            visual: item.tempVisual,
            editedGridCellData: item.editedGridCellData
        }))

        param.dashboardTemplate.forEach(item => {
            delete item.tempVisual;
        });

        console.log(param)
        updateEtaniDashboardTemplates(param)
    }

    const handleSave = async () => 
    {
        if(pintoDashboard !== 'personal' && (selectedGroupId.length === 0 || !selectedGroupId))
        {
            warningAlert("Please select a Group!");
            return
        }

        if(isEtaniTemplate && !selectedTemplateIntegration) {
            warningAlert("Please select Integration!");
            return
        }

        if(selectedTemplateItem.length > 0 && newDashboardName)
        {
            let param = {
                name: isEtaniTemplate ? `${newDashboardName}-${selectedTemplateIntegration}` : newDashboardName,
                clientId: selectedClientID,
                businessId: firmDetails.AccountingFirmID,
                groupId: selectedClientID,
                user: userDetails.User.Email,
                isPersonalDashboard: isEtaniTemplate ? isEtaniTemplate : pintoDashboard === 'personal',
                selectedGroupId: { groupsId: selectedGroupId },
                isEtaniTemplate: isEtaniTemplate,
                dashboardTemplate: selectedTemplateItem.map(item => ({
                    ...item,
                    visual: item.tempVisual || item.visual,
                    editedGridCellData: item.editedGridCellData
                })),
                uid: selectedEtaniTempaltes?.uid ? selectedEtaniTempaltes.uid : ''
            };
            
            param.dashboardTemplate.forEach(item => {
                delete item.tempVisual;
            });
            
            // console.log("selectedEtaniTempaltes: ", selectedEtaniTempaltes)
            param.dashboardTemplate = param.dashboardTemplate.map(template => {
                return {
                    ...template,
                    query: template.final_dynamic_query || template.query,
                    final_dynamic_query: undefined,
                    dynamic_query: undefined
                };
            });
            
            // Remove undefined properties
            param.dashboardTemplate = param.dashboardTemplate.map(({ final_dynamic_query, dynamic_query, ...rest }) => rest);
            // console.log(param.dashboardTemplate);
            // console.log("param: ", param)

            if(isTemplateEdit)
            {
                param.id= selectedTemplatetoEdit.id
                registerDashboardTemplate(param)
                if(pintoDashboard !== 'personal' && !isEtaniTemplate)
                    sendNotificationPerGroup(isTemplateEdit, newDashboardName)
                const alertMessage = `You have successfully update ${newDashboardName}`
                handleSuccessUpdate(alertMessage)
            }else{
                registerDashboardTemplate(param)
                const alertMessage = `You have successfully add ${newDashboardName}`
                if(pintoDashboard !== 'personal')
                    sendNotificationPerGroup(isTemplateEdit, newDashboardName)
                handleSuccessUpdate(alertMessage)
            }
        }else{
            if (!selectedTemplateItem && selectedTemplateItem.length === 0) warningAlert("You need to add at least one item!");
            if (!newDashboardName) warningAlert("The dashboard name is invalid!");
        }
    }

    const handleImportTemplate = async () => 
    {
        let param = {
            name: newDashboardName,
            clientId: selectedClientID,
            businessId: firmDetails.AccountingFirmID,
            groupId: selectedClientID,
            user: userDetails.User.Email,
            dashboardTemplate: selectedTemplateItem.map(item => ({
                ...item,
                visual: item.tempVisual
            })),
            importedTemplate: true
        };
        
        param.dashboardTemplate.forEach(item => {
            delete item.tempVisual;
        });

        try{
            registerDashboardTemplate(param)
            const alertMessage = `You have successfully add ${newDashboardName}`
            handleSuccessUpdate(alertMessage)
        } catch(error) {
            const alertMessage = `An error occured while saving the template`
            warningAlert(alertMessage)
        }
    }
     const warningAlert = (message) => {
        setAlert(
        <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Warning"
            onConfirm={() => setAlert(null)}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="warning"
            confirmBtnText="Ok"
            btnSize=""
        >
            {message}
        </ReactBSAlert>
        );
    };
    const handleSuccessUpdate = (msg, data) => {
        setAlert(
            <ReactBSAlert
                succes
                title="Success"
                onConfirm={async () => {
                    setAlert(null)
                    history.push(`/client/customDashboard`);
                }}
                confirmBtnBsStyle="success"
                confirmBtnText="Ok"
                btnSize=""
            >
                <>
                    <p>
                        {msg}
                    </p>
                </>
            </ReactBSAlert>
        );
    }
    const handleSelectExistingTemplate =  useCallback((item) => {
        setNewDashboardName(item.name)
        setSelectedExistingDashboard(item.id)
        setSelectedTemplateItem([]);
        
        setTimeout(() => {
            setSelectedTemplateItem(item.dashboardTemplate);
        }, 200);

    }, []);
    const handleSelectTemplate =  async(item, selectedTemplate) => {
        setSelectedTemplate(null);
        setSelectedTemplateItem([]);

        setTimeout(async () => {
            if (selectedTemplate === item) {
                setSelectedTemplate(null);
                setSelectedTemplateItem([]);
            } else {
                const newSelectedTemplateItem = [];
                const filterByTemplate = sqlExampleResult.filter((e) => e.template === item);
        
                for (const e of filterByTemplate) {
                    if (e.template === item) {
                        const details = e.details;
                        for (const detail of details) {
                            const uid = await generateUUID();
                            newSelectedTemplateItem.push({ 
                                name: detail.display_name, 
                                query: detail.query, uid, 
                                visual_type: detail.visual_type, 
                                visual_data: detail.visual_data,
                                integration: item.integration
                            });
                        }
                    }
                }
                setSelectedTemplate(item);
                setSelectedTemplateItem(newSelectedTemplateItem);
            }
        }, 300);
    };
    const handleSelectEtaniTemplate =  async(selectedTemplate) => {
       
        if(selectedTemplate.id === selectedEtaniTempaltes?.id){
            setSelectedTemplateItem([])
            setNewDashboardName(null)
            setSelectedEtaniTemplates(null)
        }else{
            setSelectedTemplateItem([])
            setNewDashboardName(null)
            setSelectedEtaniTemplates(null)
            setTimeout(() => {
                const templateKPI = [...selectedTemplate.dashboardTemplate]
                const KPIDataSet = []

                sqlExampleResult.map(template => {
                    template.details.map(category => {
                        category.details.map(details => {
                            KPIDataSet.push(details)
                        })
                    })
                })

                const updatedTemplateKPI = templateKPI.map(kpi => {
                    const item = KPIDataSet.find(i => i.display_name === kpi.name);
                    if (item && kpi.query !== item.query) {
                        // Return the updated item
                        return item;
                    }
                    // Return the original kpi if no changes are needed
                    return kpi;
                });
                
                // console.log(updatedTemplateKPI);
                

                setNewDashboardName(selectedTemplate.name.split("-")[0])
                setSelectedTemplateItem(templateKPI)
                setSelectedEtaniTemplates(selectedTemplate)
            }, 100);
        }
    };
    const handleSelectCategory = useCallback(async (checked, value) => {
        let newItems = [...selectedTemplateItem];
        for (const item of value.details) {
            for (const e of item.details) {
                const exists = selectedTemplateItem.some(item => item.name === e.display_name);
            
        
                if (exists && !checked) {
                    // Remove item if it exists and should be unchecked
                    newItems = newItems.filter(item => item.name !== e.display_name);
                } else if (!exists && checked) {
                    // Add item if it does not exist and should be checked
                    const newItem = {
                        uid: await generateUUID(),
                        my_row_id: e.my_row_id,
                        name: e.display_name,
                        query: e.query,
                        dynamic_query: e.dynamic_query,
                        visual_type: e.visual_type,
                        visual_data: e.visual_data,
                        integration: value.integration
                    };
                    newItems.push(newItem);
                }
            }
        }
        setSelectedTemplateItem(newItems);
    }, [selectedTemplateItem]);
    
    const handleSelectDashboardType = useCallback((value) => {
        setNewDashboardName(null)
        setSelectedExistingDashboard(null)
        setSelectedTemplateItem([]);
        setDashboardType(value)
    }, []);
    const handleTabSelect = useCallback((value) => {
        setKey(value)
    }, []);
    const handleOnEditVisual = (index) => {
        setOnEditVisual(!onEditVisual)
        setOneditItem(selectedTemplateItem[index])
        setOneditItemIndex(index)
    }
    const handleCloseEditVisual = () => {
        
        setOnEditVisual(false)
        setNewUpdateCount(prevCount => prevCount + 1);
    }
    useEffect(() => {
    },[newUpdateCount])
    const handleSelectPinToDashboard = async (event) => {
        // setDashboardType('new');
        setSelectedGroupId([])
        setPintoDashboard(event.target.value);
    };
    useEffect(() => {
        fetchClientGroups()
    },[pintoDashboard])

    const handleSelectGroupChange = async (value) => {
        const selectedGroupId = value && value.length > 0 && value.map((item) => item.value)
        setSelectedGroupId(selectedGroupId);
    };
    
    const sendNotificationPerGroup = (isTemplateEdit, newDashboardName) => {
        if(selectedGroupId.includes(selectedClientID))
        {
            const usersSet = new Set();
            clientUsers.forEach(user => { if(user !== userDetails.User.Email) usersSet.add(user)});
            const msg = isTemplateEdit ? 
            `${userDetails.User.FirstName} ${userDetails.User.LastName} updated the ${newDashboardName} dashboard in ${selectedClient.Name} group` : 
            `${userDetails.User.FirstName} ${userDetails.User.LastName} posted new dashboard in ${selectedClient.Name} group`

            sendNotificationHandler(usersSet, msg, selectedClient.Name)
        }

        customGroupList && customGroupList.forEach(group => {
            const usersSet = new Set();
            if (selectedGroupId.includes(group.id) && group.UserList && group.UserList.users) {
                group.UserList.users.forEach(user => { if(user !== userDetails.User.Email) usersSet.add(user)});
                const msg = isTemplateEdit ? 
                `${userDetails.User.FirstName} ${userDetails.User.LastName} updated the ${newDashboardName} dashboard in ${group.GroupName}` :
                `${userDetails.User.FirstName} ${userDetails.User.LastName} posted a dashboard in ${group.GroupName}`

                sendNotificationHandler(usersSet, msg, group.GroupName)
            }
        });
    }

    const sendNotificationHandler = async (users, msg, groupName) => {
        sendNotification(users, msg)
        const ClientID = props.selectedClientID
        const notification = {
          title: `new notification from the "${groupName}" group`,
          body:  msg,
          sound: 'default',
          badge: '1',
        };
        
        const clientToken = await mongoFetchToken({ clientid : ClientID, groupId: selectedClientID })
        if(!clientToken) return 
        
        const filteredTokens = clientToken.tokens
        .filter(token => {
            return users.includes(token.email.toLowerCase()) && token.email.toLowerCase() !== userDetails.User.Email.toLowerCase();
        })
        .map(token => token.token);

        if (filteredTokens.length === 0) return
    
        const message = {
          tokens: filteredTokens,
          data: { ClientID: ClientID, Name: selectedClient.Name ,  type: 'custom group', Id: selectedClient.id},
          notification: notification,
        };

        SendMultiDeviceNotification(message)
        SendExpoNotifications(message)
    };

    const sendNotification = async (selectedUsers, message) => {
        for (const email of selectedUsers) {
    
          if(email !== userDetails.User.Email)
          {
            const notifications = {
              uid: await generateUUID(),
              id: selectedClientID,
              clientId: selectedClientID,
              message: message,
              createdAt: new Date(),
              sender: userDetails.User.Email,
              isReport: true,
              email: email,
              user: email,
              url: '',
              groupId: selectedClientID,
            };
            // await socket.emit("newClientNotification", notifications);
            registerGroupNotification(notifications)
          }
        }
    };
    const fileTypeImages = {
        XB: XBImage,
        XPM: XPMImage,
        WFM: WFMImage
    };
    const RenderIntegrationTemplates = ({tabKey, etaniTempaltes}) => {
        const newTabKey = tabKey

        const newData = etaniTempaltes && etaniTempaltes.filter(template => template.name.trim().split("-")[1] && template.name.trim().split("-")[1] === newTabKey)

        return (
            newData.length > 0 ? 
            <div className='templateContainer' style={{ border: 'unset' }}>
                <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Templates</Label>
                <div className='templateContent'>
                    {etaniTempaltes.length > 0 && etaniTempaltes.map((template, index) => (
                        <React.Fragment key={template.id || index}>
                            {template.name.split("-")[1] && template.name.split("-")[1] === newTabKey && (
                                <div 
                                    style={{ 
                                        background: selectedEtaniTempaltes?.id === template.id ? bgColor : undefined, 
                                        color: selectedEtaniTempaltes?.id === template.id ? textColor : undefined 
                                    }}
                                    onClick={e => handleSelectEtaniTemplate(template)}
                                >
                                    {template.name.split("-")[0]}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            : null
        )
    }
    const handleToggleShowSettings = () => {
        setToggleShowSettings(!toggleShowSettings)
    }

    const handleSearch = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const results = [];

        const filteredData = sqlExampleResult.map((category) => {
            const newCategory = { ...category };
            newCategory.details = newCategory.details.map((subCategory) => {
                const newSubCategory = { ...subCategory };
                newSubCategory.details = newSubCategory.details.filter((item) =>
                    item.display_name.toLowerCase().includes(searchTerm)
                );
                return newSubCategory;
            }).filter(subCategory => subCategory.details.length > 0);
            return newCategory;
        }).filter(category => category.details.length > 0);
    
        setFilteredSQLExampleResult(filteredData);
    }
    const [isEtaniTemplate, setIsEtaniTemplate] = useState(false)
    const [selectedTemplateIntegration, setSelectedTemplateIntegration] = useState(null)
    const toggleEtaniTemplate = () => {
        setIsEtaniTemplate(!isEtaniTemplate)
    }
    const handleSelectIntegrationList = (event) => {
        setSelectedTemplateIntegration(event.target.value)
    }
    const EtaniTemplateSetting = () => {
        return (
            <>
            <Row>
                <Col className="d-flex mb-3" style={{ flexDirection: "column" }}>
                    <Label 
                        htmlFor="newDashboardName" 
                        style={{ 
                            fontWeight: 'bold', 
                            fontSize: 14, 
                            marginBottom: 7
                        }}>Is Etani Template?</Label>
                    <label className="custom-toggle custom-toggle-information mr-1">
                        <input
                            checked={isEtaniTemplate}
                            type="checkbox"
                            onChange={toggleEtaniTemplate}
                            id="mfa"
                            name="MFA"
                        />
                        <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                        />
                    </label>
                </Col>
            </Row>
            {isEtaniTemplate &&
                <Row>
                    <Col>
                        <FormGroup>
                            <Label 
                            htmlFor="selectedIntegrationId" 
                            style={{ 
                                fontWeight: 'bold', 
                                fontSize: 14, 
                                marginBottom: 7
                            }}>Select Integration</Label>
                            <Input type="select" id="selectedIntegrationId" value={selectedTemplateIntegration} onChange={handleSelectIntegrationList}>
                            <option value="">Select Integration</option>
                            {integrationList.map(integration => (
                                <option key={integration.id} value={integration.id}>
                                {integration.label}
                                </option>
                            ))}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
            }
            </>
        )
    }
    const integrationList = [
        {id: 'XB', label: 'Xero Blue'},
        {id: 'XPM', label: 'XPM'},
        {id: 'MYOB', label: 'MYOB AR Live'},
        {id: 'WFM', label: 'Workflow Max'}
    ]

    const [isEditDateRange, setIsEditDateRange] = useState(false)
    const [isToggleEVAModal, setIsToggleEVAModal] = useState(false)
    const [evaDashboardMessage, setEVADashboardMessage] = useState([])

    const handleEditDateRangeModal = (item, index) => {
        console.log({ item, index })
        setIsEditDateRange(!isEditDateRange)
    }
    const toggleEVAModal = () => {
        setIsToggleEVAModal(!isToggleEVAModal)
    }
    const clearDashboard = () => {
        setSelectedTemplateItem([]);
    }
    const handleChangeEVAUUID = async () => {
        const evaUUID = await generateUUID()
        setEVADashboardMessage([])
        setEVAUUID(evaUUID)
    }
    const handleEVADashboardMessage = (newMessage) => {
        setEVADashboardMessage(prev => [...prev, newMessage]);
    }
    const handleSelectTemplateItems = async (items) => {
        let newItems = [];

        for (const e of items) {
            const newItem = {
                uid: await generateUUID(),
                my_row_id: e.my_row_id,
                name: e.display_name,
                query: e.query,
                dynamic_query: e.dynamic_query,
                visual_type: e.visual_type,
                visual_data: e.visual_data,
                integration: e.integration
            };
            newItems.push(newItem);
        }
        
        setSelectedTemplateItem(newItems);
    }
    return (
        <div className="mt-6 fadeIn mainDashboard" style={{ height: windowHeight - 72 , overflow: 'hidden'}}>
            {alert}
            {isToggleEVAModal &&
                <EVAChatDashboard 
                    categories={categories}
                    sub_category={category_sub_category}
                    KPIList={KPIList}
                    selectedUUID={evaUUID}
                    evaDateFilters={evaDateFilters}
                    toggleEVAModal={toggleEVAModal}
                    onSelectUUID={handleChangeEVAUUID}
                    evaDashboardMessage={evaDashboardMessage}
                    handleEVADashboardMessage={handleEVADashboardMessage}
                    handleSelectTemplateItems={handleSelectTemplateItems}
                />
            }
            <div className="nav-wrapper dashboardTemplateContainer">

                {onEditVisual &&
                    <div className='onEditVisualContainer'>
                        <div className='onEditVisualContent'>
                            <EVAVisualEditDashboard
                                index={onEditItemIndex}
                                name={onEditItem.name}
                                query={onEditItem.query}
                                item={onEditItem}
                                selectedTemplateItem={selectedTemplateItem}
                                onEditVisual={onEditVisual}
                                handleCloseEditVisual={handleCloseEditVisual}
                            />
                        </div>
                    </div>
                }

                <div className='templateListContainer' 
                    style={{ 
                        marginTop: 1, height: screenHeight, background: '#f7f7f7', 
                        padding: 10, overflow: 'auto', paddingBottom: 110,
                        width: '100%'//!toggleShowSettings ? '100%' : 'calc(100% - 400px)'
                    }}>
                    <ManageDashboardDnD 
                        selectedTemplateItem={selectedTemplateItem}
                        handleOnEditVisual={handleOnEditVisual}
                        onEditVisual={onEditVisual}
                        handleCloseEditVisual={handleCloseEditVisual}
                        newUpdateCount={newUpdateCount}
                        handleSelectItem={handleSelectItem}
                        handleEditDateRangeModal={handleEditDateRangeModal}
                        isEditDateRange={isEditDateRange}
                        evaDateFilters={evaDateFilters}
                    />
                </div>
               
                <div className='templateListSettings' style={{ height: screenHeight - 73 , width: toggleShowSettings ? 600 : 0 }}>
                    <div className='toggleShowSettings' onClick={() => handleToggleShowSettings()}>
                        {toggleShowSettings ? 'Hide' : 'Show'} Menu
                    </div>

                    {toggleShowSettings &&
                        <div className='templateListContainer' style={{ height: screenHeight - 73}}>
                        {isLoading ? <CustomSpinner /> :
                            <Form>
                                <FormGroup>
                                    {sqlExampleResult && sqlExampleResult.length > 0 ?
                                        <div className='templateDashboardTabView'>
                                            {dashboardType === 'Existing' ? 
                                                <div style={{ padding: '10px 10px', border: '1px solid #e0e0e0', marginBottom: 10 }}>
                                                    {/* USER DASHBOARD */}
                                                    <div className='templateContainer' style={{ border: 'unset' }}>
                                                        <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Dashboards</Label>
                                                        {userTemplatesList.length > 0 ? 
                                                            <div className='templateContent'>
                                                                    {userTemplatesList.map((item, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <div  key={index} style={{ background: selectedExistingDashboard === item.id && bgColor, color: selectedExistingDashboard === item.id && textColor }}
                                                                                onClick={e => handleSelectExistingTemplate(item)}>
                                                                                {item.name}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))}
                                                            </div>
                                                            :
                                                            <>
                                                                <div className='noTemplateFound'>
                                                                    No public dashboard template found!
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="modal-footer">
                                                        <CustomButton
                                                            type="button"
                                                            onClick={() => handleImportTemplate()}
                                                            outlineStyle={false}
                                                            title="Save Dashboard"
                                                        />
                                                        <CustomButton
                                                            onClick={() => {
                                                                return history.push(`/client/customDashboard`);
                                                            }}
                                                            outlineStyle={true}
                                                            data-dismiss="modal"
                                                            type="button"
                                                            color="link"
                                                            title="Close"
                                                        /> 
                                                    
                                                    </div>
                                                </div>
                                            :
                                                <div style={{ padding: '10px 10px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10, position: 'relative' }}>
                                                
                                                        <Row>
                                                            {includesEmail && 
                                                                <Col>
                                                                    <EtaniTemplateSetting />
                                                                </Col>
                                                            }
                                                            <Col xl='12'>
                                                                <FormGroup>
                                                                    <Label 
                                                                        htmlFor="newDashboardName" 
                                                                        style={{ 
                                                                            fontWeight: 'bold', 
                                                                            fontSize: 14, 
                                                                            marginBottom: 7
                                                                        }}>Dashboard Name</Label>
                                                                        <Input
                                                                            key={1}
                                                                            type="text"
                                                                            id="newDashboardName"
                                                                            placeholder="Dashboard Name"
                                                                            value={newDashboardName}
                                                                            onChange={handleNewDashboardNameChange}
                                                                        />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl='12'>
                                                                <FormGroup tag="fieldset">
                                                                    <Label 
                                                                        htmlFor="personalDashboard" 
                                                                        style={{ 
                                                                            fontWeight: 'bold', 
                                                                            fontSize: 14, 
                                                                            marginBottom: 7
                                                                        }}>Dashboard Location</Label>
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="personalDashboard"
                                                                        name="pinTodashboardType"
                                                                        value="personal"
                                                                        label="Personal Dashboard"
                                                                        checked={pintoDashboard === 'personal'}
                                                                        onChange={handleSelectPinToDashboard}
                                                                    />
                                                                    <CustomInput
                                                                        type="radio"
                                                                        id="otherDashboard"
                                                                        name="pinTodashboardType"
                                                                        value="group"
                                                                        label="Group Dashboard"
                                                                        checked={pintoDashboard === 'group'}
                                                                        onChange={handleSelectPinToDashboard}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col xl='12'>
                                                                {pintoDashboard === 'group' && (
                                                                    <FormGroup>
                                                                        <Label 
                                                                        htmlFor="personalDashboard" 
                                                                        style={{ 
                                                                            fontWeight: 'bold', 
                                                                            fontSize: 14, 
                                                                            marginBottom: 7
                                                                        }}>Group List</Label>
                                                                        <Select 
                                                                            defaultValue={defaultSelectedGroup}
                                                                            isMulti
                                                                            name="colors"
                                                                            options={groupList}
                                                                            className="basic-multi-select"
                                                                            classNamePrefix="select"
                                                                            onChange={handleSelectGroupChange}
                                                                        />
                                                                    </FormGroup>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
                                                            {integration && integration.map((item, index) => {
                                                                const correspondingItem = selectClientIntegration.find(listItem => listItem.integration === item);
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <a 
                                                                            id={correspondingItem.id} 
                                                                            href="#" 
                                                                            role="tab" 
                                                                            style={{
                                                                                ...(tabKey === item ? { backgroundColor: bgColor, color: textColor } : { border: '1px solid #e0e0e0' }),
                                                                                padding: '5px 10px 5px 4px'
                                                                            }}
                                                                            className={`nav-item nav-link ${tabKey === item ? "active" : ""}`}
                                                                            onClick={() => handleTabSelect(item)}>
                                                                                {item === "XB" || item === "XPM" ||item === "WFM"? 
                                                                                    <img src={fileTypeImages[item]} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
                                                                                :
                                                                                    <img src={correspondingItem.logo} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
                                                                                }
                                                                                
                                                                                {item} Integration
                                                                        </a>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </div>
                                                        <div className='card-body' style={{ border: '1px solid #e0e0e0' , padding: '10px 10px',}}>
                                                            {!isEtaniTemplate &&
                                                                <Row>
                                                                    <Col xs='12'>
                                                                        <RenderIntegrationTemplates 
                                                                            tabKey={tabKey}
                                                                            etaniTempaltes={etaniTempaltes} />
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Row>
                                                            <Col sm="12">
                                                                <InputGroup className="mb-4">
                                                                    <InputGroupAddon addonType="prepend">
                                                                        <InputGroupText>
                                                                        <i className="fas fa-search" />
                                                                        </InputGroupText>
                                                                    </InputGroupAddon>
                                                                    <Input placeholder="Search KPI" type="text" onChange={handleSearch} />
                                                                </InputGroup>
                                                            </Col>
                                                            </Row>
                                                            <Row>
                                                            {filteredSqlExampleResult && filteredSqlExampleResult.map((item, index) => (
                                                                item.integration === tabKey && (
                                                                <Col xs="12" key={index}>  {/* Added key={index} */}
                                                                    <div className="card">
                                                                    <DashboardTemplateKPI  
                                                                        index={index}
                                                                        categories={categories}
                                                                        sub_category={sub_category}
                                                                        tabKey={tabKey}
                                                                        item={item}
                                                                        selectedTemplateItem={selectedTemplateItem}
                                                                        handleSelectItem={handleSelectItem}
                                                                        sqlExampleResult={sqlExampleResult}
                                                                        category={item.category}
                                                                        handleSelectCategory={handleSelectCategory}
                                                        
                                                                    />
                                                                    </div>
                                                                </Col>
                                                                )
                                                            ))}
                                                            </Row>
                                                        </div>
                                                </div>
                                            }

                                            <div className="modal-footer">
                                                <div style={{ position: 'absolute', left: 5 }}>
                                                    <Button color="primary" type="button" size='sm' outline onClick={() => toggleEVAModal()}>
                                                        Build with EVA
                                                    </Button>
                                                    <Button color="warning" type="button" size='sm' outline onClick={() => clearDashboard()}>
                                                        Clear Dashboard
                                                    </Button>
                                                </div>
                                                <CustomButton
                                                    type="button"
                                                    onClick={() => handleSave()}
                                                    outlineStyle={false}
                                                    title="Save Dashboard"
                                                />
                                                <CustomButton
                                                    onClick={() => {
                                                        return history.push(`/client/customDashboard`);
                                                    }}
                                                    outlineStyle={true}
                                                    data-dismiss="modal"
                                                    // className="ml-auto"
                                                    type="button"
                                                    color="link"
                                                    title="Close"
                                                /> 
                                            </div> 
                                        </div>
                                    :
                                        <>
                                            <div className="dashboardItems" style={{
                                                height: '200px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center', // Center horizontally
                                                justifyContent: 'center', // Center vertically
                                                flexWrap: 'wrap'
                                            }}>
                                                <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No available templates for this client</div>
                                                <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
                                            </div>
                                        </>
                                    }
                                </FormGroup>
                            </Form>
                        }
                        </div>
                    }
                </div>

                {!toggleShowSettings &&
                    <div className="toggle-modal-footer">
                        <CustomButton
                            onClick={() => {
                                return history.push(`/client/customDashboard`);
                            }}
                            outlineStyle={true}
                            data-dismiss="modal"
                            // className="ml-auto"
                            type="button"
                            color="link"
                            title="Close"
                        /> 
                        <CustomButton
                            type="button"
                            onClick={() => handleSave()}
                            outlineStyle={false}
                            title="Save Dashboard"
                        />
                        {/* {includesEmail &&
                            <CustomButton
                                type="button"
                                onClick={() => handleUpdateEtaniTemplate()}
                                outlineStyle={false}
                                title="Update Etani Dashboard"
                            />
                        } */}
                    </div> 
                }
            </div>
       
        </div>
    )
}

export default DashboardTemplate

// <PanelCard headerName="Dashboard Templates">
//                     {isLoading ? <CustomSpinner />
//                     : 
//                     <Form>
//                         <FormGroup>
//                             {sqlExampleResult && sqlExampleResult.length > 0 ?
//                                 <div className='templateDashboardTabView'>
//                                     <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
//                                             <>
//                                                 <a 
//                                                     id={1} 
//                                                     href="#" role="tab" 
//                                                     key={1}
//                                                     style={dashboardType === 'New' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
//                                                     className={`nav-item nav-link ${dashboardType === 'New' ? "active" : ""}`}
//                                                     onClick={() => handleSelectDashboardType('New')}>
//                                                         New Dashboard
//                                                 </a>
//                                                 <a 
//                                                     id={2} 
//                                                     href="#" role="tab" 
//                                                     key={2}
//                                                     style={dashboardType === 'Existing' ? { backgroundColor: bgColor, color: textColor }:{ border: '1px solid #e0e0e0' }}
//                                                     className={`nav-item nav-link ${dashboardType === 'Existing' ? "active" : ""}`}
//                                                     onClick={() => handleSelectDashboardType('Existing')}>
//                                                         Public Dashboard
//                                                 </a>
//                                             </>
//                                     </div>
//                                     {dashboardType === 'Existing' ? 
//                                         <div style={{ padding: '20px 15px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10 }}>
//                                             {/* USER DASHBOARD */}
//                                             <div className='templateContainer' style={{ border: 'unset' }}>
//                                                 <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Dashboards</Label>
//                                                 {userTemplatesList.length > 0 ? 
//                                                     <div className='templateContent'>
//                                                             {userTemplatesList.map((item, index) => (
//                                                                 <React.Fragment key={index}>
//                                                                     <div  key={index} style={{ background: selectedExistingDashboard === item.id && bgColor, color: selectedExistingDashboard === item.id && textColor }}
//                                                                         onClick={e => handleSelectExistingTemplate(item)}>
//                                                                         {item.name}
//                                                                     </div>
//                                                                 </React.Fragment>
//                                                             ))}
//                                                     </div>
//                                                     :
//                                                     <>
//                                                         <div className='noTemplateFound'>
//                                                             No public dashboard template found!
//                                                         </div>
//                                                     </>
//                                                 }
//                                             </div>
//                                             <div className="modal-footer">
//                                                 <CustomButton
//                                                     type="button"
//                                                     onClick={() => handleImportTemplate()}
//                                                     outlineStyle={false}
//                                                     title="Save Dashboard"
//                                                 />
//                                                 <CustomButton
//                                                     onClick={() => {
//                                                         return history.push(`/client/customDashboard`);
//                                                     }}
//                                                     outlineStyle={true}
//                                                     data-dismiss="modal"
//                                                     // className="ml-auto"
//                                                     type="button"
//                                                     color="link"
//                                                     title="Close"
//                                                 /> 
                                            
//                                             </div>
//                                         </div>
//                                     :
//                                         <div style={{ padding: '20px 15px', border: '1px solid #e0e0e0', borderRadius: 5, marginBottom: 10 }}>
                                        
//                                                 <Row>
//                                                     <Col xl='4' md='6' sm='12'>
//                                                         <FormGroup>
//                                                             <Label 
//                                                                 htmlFor="newDashboardName" 
//                                                                 style={{ 
//                                                                     fontWeight: 'bold', 
//                                                                     fontSize: 14, 
//                                                                     marginBottom: 7
//                                                                 }}>Dashboard Name</Label>
//                                                                 <Input
//                                                                     key={1}
//                                                                     type="text"
//                                                                     id="newDashboardName"
//                                                                     placeholder="Dashboard Name"
//                                                                     value={newDashboardName}
//                                                                     onChange={handleNewDashboardNameChange}
//                                                                 />
//                                                         </FormGroup>
//                                                     </Col>
//                                                     <Col xl='3' md='6' sm='12'>
//                                                         <FormGroup tag="fieldset">
//                                                             <Label 
//                                                                 htmlFor="personalDashboard" 
//                                                                 style={{ 
//                                                                     fontWeight: 'bold', 
//                                                                     fontSize: 14, 
//                                                                     marginBottom: 7
//                                                                 }}>Dashboard Location</Label>
//                                                             <CustomInput
//                                                                 type="radio"
//                                                                 id="personalDashboard"
//                                                                 name="pinTodashboardType"
//                                                                 value="personal"
//                                                                 label="Personal Dashboard"
//                                                                 checked={pintoDashboard === 'personal'}
//                                                                 onChange={handleSelectPinToDashboard}
//                                                             />
//                                                             <CustomInput
//                                                                 type="radio"
//                                                                 id="otherDashboard"
//                                                                 name="pinTodashboardType"
//                                                                 value="group"
//                                                                 label="Group Dashboard"
//                                                                 checked={pintoDashboard === 'group'}
//                                                                 onChange={handleSelectPinToDashboard}
//                                                             />
//                                                         </FormGroup>
//                                                     </Col>
//                                                     <Col xl='4' md='6' sm='12'>
//                                                         {pintoDashboard === 'group' && (
//                                                             <FormGroup>
//                                                                 <Label 
//                                                                 htmlFor="personalDashboard" 
//                                                                 style={{ 
//                                                                     fontWeight: 'bold', 
//                                                                     fontSize: 14, 
//                                                                     marginBottom: 7
//                                                                 }}>Group List</Label>
//                                                                 <Select 
//                                                                 // defaultValue={[groupList[2], groupList[3]]}
//                                                                     defaultValue={defaultSelectedGroup}
//                                                                     isMulti
//                                                                     name="colors"
//                                                                     options={groupList}
//                                                                     className="basic-multi-select"
//                                                                     classNamePrefix="select"
//                                                                     onChange={handleSelectGroupChange}
//                                                                 />
//                                                                 {/* <Input type="select" id="selectedGroupId" value={selectedGroupId} onChange={handleSelectGroupChange}>
//                                                                 <option value="">Select Group</option>
//                                                                 {groupList.map(group => (
//                                                                     <option key={group.id} value={group.id}>
//                                                                     {group.GroupName}
//                                                                     </option>
//                                                                 ))}
//                                                                 </Input> */}
//                                                             </FormGroup>
//                                                         )}
//                                                     </Col>
//                                                 </Row>

//                                                 {/* TEMPLATES */}
//                                                 {/* <div className='templateContainer' style={{ border: 'unset' }}>
//                                                     <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Templates</Label>
//                                                     <div className='templateContent'>
    
//                                                         {etaniTempaltes.length > 0 && etaniTempaltes.map((template, index) => (
//                                                             <div 
//                                                                 key={index} 
//                                                                 style={{ background: selectedEtaniTempaltes?.id === template.id ? bgColor : undefined, color: selectedEtaniTempaltes?.id === template.id ? textColor : undefined }}
//                                                                 onClick={e => handleSelectEtaniTemplate(template)}
//                                                             >
//                                                                 {template.name}
//                                                             </div>
//                                                         ))}
//                                                     </div>
//                                                 </div> */}

//                                                 {/* INTEGRATION */}
//                                                 <div className="nav nav-tabs" role="tablist" style={{ border: `unset`}}>
//                                                     {integration && integration.map((item, index) => {
//                                                         const correspondingItem = selectClientIntegration.find(listItem => listItem.integration === item);
//                                                         return (
//                                                             <React.Fragment key={index}>
//                                                                 <a 
//                                                                     id={correspondingItem.id} 
//                                                                     href="#" 
//                                                                     role="tab" 
//                                                                     style={{
//                                                                         ...(tabKey === item ? { backgroundColor: bgColor, color: textColor } : { border: '1px solid #e0e0e0' }),
//                                                                         padding: '5px 10px 5px 4px'
//                                                                     }}
//                                                                     className={`nav-item nav-link ${tabKey === item ? "active" : ""}`}
//                                                                     onClick={() => handleTabSelect(item)}>
//                                                                         {item === "XB" || item === "XPM" ||item === "WFM"? 
//                                                                             <img src={fileTypeImages[item]} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
//                                                                         :
//                                                                             <img src={correspondingItem.logo} alt={`${item} logo`} style={{ height: 45, padding: 5 }} />
//                                                                         }
                                                                        
//                                                                         {item} Integration
//                                                                 </a>
//                                                             </React.Fragment>
//                                                         );
//                                                     })}
//                                                 </div>
//                                                 {/* KPI */}
//                                                 {/* <div className='nav-child' style={{ padding: 10, border: '1px solid #e0e0e0', borderRadius: 5, display: 'flex', flexWrap: 'wrap', marginBottom: 10 }}> */}
//                                                 <div className='card-body' style={{ border: '1px solid #e0e0e0'}}>
//                                                     <Row>
//                                                         <Col xs='12'>
//                                                             <RenderIntegrationTemplates 
//                                                                 tabKey={tabKey}
//                                                                 etaniTempaltes={etaniTempaltes} />
//                                                         </Col>
//                                                     </Row>
//                                                     <Row>
//                                                         {sqlExampleResult && sqlExampleResult.map((item, index) => (
//                                                             item.integration === tabKey &&
//                                                             // <div key={index} 
//                                                             //     // style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 10}}
//                                                             //     style={{ height: 'max-content'}}
//                                                             //     >
//                                                             <Col xs='12' xl='4' lg='4' md='6' >
//                                                                 <div className='card'>
//                                                                     <DashboardTemplateKPI  
//                                                                         index={index}
//                                                                         categories={categories}
//                                                                         sub_category={sub_category}
//                                                                         tabKey={tabKey}
//                                                                         item={item}
//                                                                         selectedTemplateItem={selectedTemplateItem}
//                                                                         handleSelectItem={handleSelectItem}
//                                                                         sqlExampleResult={sqlExampleResult}
//                                                                         category={item.category}
//                                                                         handleSelectCategory={handleSelectCategory}
//                                                                     />
//                                                                 </div>
//                                                             </Col>
//                                                         ))}
//                                                     </Row>
//                                                 </div>
//                                                 {/* </div> */}
//                                             {/* Add Ons */}
//                                             {/* <div className='addOneContainer' style={{ border: 'unset' }}>
//                                                 <Label htmlFor="newDashboardName" style={{ fontWeight: 'bold', fontSize: 14, marginBottom: 7 }}>Add Ons</Label>
//                                                 <div className='addOneContent'>
//                                                 <div>Text Box</div>
//                                                 <div>Note</div>
//                                                 <div>Image</div>
//                                                 </div>
//                                             </div>
//                                             */}
//                                             <div className="modal-footer">
//                                                 <CustomButton
//                                                     type="button"
//                                                     onClick={() => handleSave()}
//                                                     outlineStyle={false}
//                                                     title="Save Dashboard"
//                                                 />
//                                                 <CustomButton
//                                                     onClick={() => {
//                                                         return history.push(`/client/customDashboard`);
//                                                     }}
//                                                     outlineStyle={true}
//                                                     data-dismiss="modal"
//                                                     // className="ml-auto"
//                                                     type="button"
//                                                     color="link"
//                                                     title="Close"
//                                                 /> 
                                            
//                                             </div> 
//                                         </div>
//                                     }

//                                 </div>
//                             :
//                                 <>
//                                     <div className="dashboardItems" style={{
//                                         height: '200px',
//                                         display: 'flex',
//                                         flexDirection: 'column',
//                                         alignItems: 'center', // Center horizontally
//                                         justifyContent: 'center', // Center vertically
//                                         flexWrap: 'wrap'
//                                     }}>
//                                         <div className="dashboardItemError" style={{color: '#9f9f9f'}}>No available templates for this client</div>
//                                         <div className="dashboardItemError" style={{color: '#9f9f9f', fontSize: '13px', fontWeight: 400}}>Please check your integration status</div>
//                                     </div>
//                                 </>
//                             }
//                         </FormGroup>
//                     </Form>
//                     }
                
//                 </PanelCard>