import GraphView from 'eva-component/response/GraphView'
import GridView from 'eva-component/response/GridView'
import TreeView from 'eva-component/response/TreeView'
import React, { useContext, useEffect, useState } from 'react'
import { Col, FormGroup, Row } from 'reactstrap'
import EditGridComponent from './EditGridView/EditGridComponent'
import { MainContext } from 'context/mainContext'
import { updateVisualPersonalDashboard } from 'services/mongoDB'
import { updateVisualUserDashboard } from 'services/mongoDB'
import { registerDashboardTemplate } from 'services/mongoDB'

function VisualGridView({ gridHeader, gridOriginalHeader, sqlresult, theme, item, selectedDashboardTemplate, treeViewData, 
    isWithChild, isShowGrid, toggleAll , isShowGrap, handleSaveVisual, onEditVisual, selectedViewOption, 
    handleCloseEditVisual, onEditName, handleSaveGridFormula, VisualGridView, newUpdate, groupLevel, handleToggleDateRanges, gridHeaderWithDateRange }) 
{
    const [isEditGrid, setIsEditGrid]  = useState(false)
    const [includesEmail, setIncludesEmail] = useState(false)
    const [gridGroupLevel, setGridGroupLevel] = useState(null)

    const {
        userDetails,
      } = useContext(MainContext);
      
    useEffect(() => {
    },[newUpdate])

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
        // console.log(onEditName)
        const email = userDetails.User.Email.toLowerCase()
        const specialEmails = ["jp@etani.com.au", "fuhad@etani.com.au", "admin@etani-bp.com.au"];
        setIncludesEmail(specialEmails.includes(email))
    },[])
    const onHandleSaveVisual = () => {
        const param = {
            selectedViewOption: selectedViewOption,
        }
        handleSaveVisual(param)
    }
    const handleToggleEditGrid = () => {
        setIsEditGrid(!isEditGrid)
    }
    const handleSaveEVAGridChanges = (param) => 
    {
        item.editedGridCellData = param;
        // if(selectedDashboardTemplate.personalDashboards) updateVisualPersonalDashboard(selectedDashboardTemplate);
        // else if (selectedDashboardTemplate.userDashboard) updateVisualUserDashboard(selectedDashboardTemplate);
        // else registerDashboardTemplate(selectedDashboardTemplate);
        setIsEditGrid(!isEditGrid)

        // setNewUpdate(prev => prev + 1)
        handleSaveGridFormula(param)
    }
    return (
        isEditGrid ? 
        <EditGridComponent
            gridHeader={gridOriginalHeader} 
            sqlresult={sqlresult} 
            theme={theme} 
            item={item}
            treeViewData={treeViewData} 
            isWithChild={isWithChild} 
            isShowGrid={isShowGrid} 
            toggleAll={toggleAll}
            handleToggleEditGrid={handleToggleEditGrid}
            // handleSaveGridFormula={handleSaveGridFormula}
            handleSaveGridFormula={handleSaveEVAGridChanges}
        />
        :
        <>
        {(onEditVisual || onEditName) &&
            <FormGroup>
                <Col sm='12'>
                    <Row>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                                    onClick={onHandleSaveVisual}
                                >Save View</div>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ border: '1px solid #333', color: '#333', width: 100, marginTop: 10, marginRight: 0 }}
                                    onClick={handleCloseEditVisual}
                                >Cancel</div>
                            </div>
                            {/* {includesEmail && */}
                            <div>
                                <div 
                                    className='addNewVisualData' 
                                    style={{ background: '#088bf4', border: '1px solid #088bf4', color: '#fff', width: 100, marginTop: 10, marginRight: 10 }}
                                    onClick={handleToggleEditGrid}
                                >Edit Grid</div>
                            </div>
                            {/* } */}
                            
                        </div>
                    </Row>
                </Col>
            </FormGroup>
        }
        <div className='EVAResponseContainer visualGridView' style={{ display: 'unset', padding: 0 }}>
            <div className='EVADataContent' style={{ overflow: 'auto' }}>
                {/* {isShowGrap ? <GraphView
                    sqlResult={sqlresult}
                    gridHeader={gridHeader}
                    theme={theme} 
                    treeViewData={treeViewData}
                    isWithChild={isWithChild}
                    isShowGrid={isShowGrid}
                /> :  */}
                {/* gridGroupLevel || (isWithChild && !isShowGrid) ?  */}
                    <TreeView
                        // theme={theme} 
                        // sqlresult={sqlresult} 
                        // gridHeader={gridHeader}
                        // treeViewData={treeViewData}
                        // toggleAll={toggleAll}
                        item={item}
                        theme={theme} 
                        sqlresult={sqlresult} 
                        gridHeader={gridHeader}
                        treeViewData={treeViewData}
                        toggleAll={toggleAll}
                        groupLevel={groupLevel}
                        handleToggleDateRanges={handleToggleDateRanges}
                        gridHeaderWithDateRange={gridHeaderWithDateRange}
                    /> 
                    {/* :
                    <GridView
                        theme={theme} 
                        sqlresult={sqlresult} 
                        EVAData={sqlresult}
                        gridHeader={gridHeader}
                    />
                } */}
            </div>
        </div>
        </>
    )
}

export default VisualGridView