import { MainContext } from 'context/mainContext';
import React, { useContext, useEffect, useState } from 'react'
import { Bar, Line } from 'react-chartjs-2';

function DashboardTempaltemGraphView({ item, treeViewData, sqlresult, templateItemVisual, selectedViewOption, newUpdateCount, groupLevel, gridHeader }) 
{
    const [selectedGraphHeaderOption, setSelectedGraphHeaderOption] = useState(templateItemVisual.selectedGraphHeaderOption)
    const [chartHeader, setChartHeader] = useState([])
    const {
        bgColor,
        textColor
    } = useContext(MainContext);
    
    const { 
        // chartHeader,
        selectedFooter,
        selectedGroup,
        selectedGroups,
        headerToMultiSelect,
    } = templateItemVisual

    const [chartDataSet, setChartDataSet] = useState(null);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [gridGroupLevel, setGridGroupLevel] = useState(groupLevel)

    let chartData = {
        labels: [],
        datasets: [],
    }

    useEffect(() => {

        if (!selectedFooter) {
            return;
        }

        const footer = getChartFooter(selectedFooter.id);
        if (!footer) {
            console.error("Footer with ID", selectedFooter.id, "not found");
            return;
        }
        
        chartData.labels = footer;
        getChartData(selectedGroups);
    }, [selectedGraphHeaderOption, newUpdateCount, gridGroupLevel, templateItemVisual]);
    
    useEffect(() => {
        const handleResize = () => {
          setScreenHeight(window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(item && item.editedGridCellData) setGridGroupLevel(item.editedGridCellData.gridGroupLevel)
        else setGridGroupLevel(groupLevel)

        if(templateItemVisual.selectedGroup) {
            processGraphHeader(templateItemVisual.selectedGroup)
        }
    }, [item])

    useEffect(() => {
        // if(chartDataSet)
    },[chartDataSet])

    const processGraphHeader = (selectedGroup) => {
        if(!headerToMultiSelect) return
        const res = headerToMultiSelect.find((e) => e.name === selectedGroup);
        const header = getChartHeader2(res.id)

        setChartHeader(header)
        handleSelectGraphHeader(header[0].id)
    }
    const getChartHeader2 = (id) => {
        const label = []
        let currentIndentLevel = 0;
        loopThruItems(id, treeViewData);

        function loopThruItems(indentLevel, treeViewData) {
            const rowsToSum = [];
            
            for (let i = 0; i < treeViewData.length; i++) {
                const row = treeViewData[i];

                if (currentIndentLevel === id) {
                    if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-")) {
                        // if(!label.includes(row.name))
                        const isExist = label.find(item => item.sqlId === row.sqlId)

                        if(!isExist)
                            label.push({ header : row.name, id: row.sqlId })
                    }
                }

                if (row.children) {
                    currentIndentLevel++;
                    const childRowsToSum = loopThruItems(indentLevel, row.children);
                    rowsToSum.push(...childRowsToSum);
                    currentIndentLevel--;
                }
            }
            return rowsToSum;
        }
        return label
    }
    const getChartFooter = (id) => {
        const label = []
        let currentIndentLevel = 0;

        const formatedSelectedGraphHeaderOption = selectedGraphHeaderOption?.id || selectedGraphHeaderOption

        if(selectedGraphHeaderOption) loopThruParentChild(id, treeViewData)
        else loopThruItems(id, treeViewData)

        function loopThruItems(indentLevel, parent)
        {
            for (let i = 0; i < parent.length; i++) { 
                const row = parent[i];
                if(id === 0) {
                    insertLabel(row)
                } else {
                    if (currentIndentLevel === id) {
                        insertLabel(row)
                    }

                    if (row.children) {
                        currentIndentLevel++;
                        loopThruItems(indentLevel, row.children)
                        currentIndentLevel--;
                    }
                }
            }
        }

        function loopThruParentChild(indentLevel, parent)
        {
            for (let i = 0; i < parent.length; i++) { 
                const row = parent[i];
                if(row.sqlId === formatedSelectedGraphHeaderOption) 
                {
                    if(row.children) {
                        currentIndentLevel++;
                        loopThruChildren(row.children)
                    }
                    else insertLabel(row)
                }

                if(row.children) {
                    currentIndentLevel++;
                    loopThruParentChild(indentLevel, row.children)
                    currentIndentLevel--;
                }
            }

            function loopThruChildren(parent)
            {
                for (let i = 0; i < parent.length; i++) { 
                    const row = parent[i];
                    if (currentIndentLevel === id) {
                        insertLabel(row)
                    }

                    if(row.children) {
                        currentIndentLevel++;
                        loopThruChildren(row.children)
                        currentIndentLevel--;
                    }
                }
            }
        }

        function insertLabel(row) {
            const param = {id: row.sqlId, label: row.name}
            if (row.id && !row.id.includes("-TotalRow") && row.id !== "grand-total" && !row.id.includes("-grandParent-"))
            {
                label.push(param)
            }
        }

        return label
    }
    const getChartData = (filter) => 
    {
        filter.forEach((fil, index) => {
            if(fil.data !== '' && fil.label !== '')
            {
                const res = headerToMultiSelect.filter((e) => e.name === fil.data);
                const id = res[0]?.id
                if(chartData.labels.length > 0)
                {
                    const item = {
                        id: index,
                        label: fil.label,
                        data: [],
                        backgroundColor: fil.color,
                        borderColor: fil.color,
                    }
                    let itemData = []

                    chartData.labels.forEach((item) => {
                        let totalAmount = 0
                        const colIndex = fil.colIndex ? fil.colIndex : (gridHeader.indexOf(fil.data) - gridGroupLevel)

                        loopThruItems(treeViewData, id)
                        function loopThruItems(parent, id)
                        {
                            for (let i = 0; i < parent.length; i++) { 
                                const row = parent[i];

                                if(row.sqlId === item.id) {
                                    processCalculation(row)

                                    if (row.children) {
                                        loopThruParentChild(row.children)
                                    }
                                }

                                if (row.children && id !== 0) {
                                    loopThruItems(row.children, id);
                                }
                            }
                        }

                        function loopThruParentChild(parent)
                        {
                            for (let i = 0; i < parent.length; i++) { 
                                const row = parent[i];

                                processCalculation(row)
                                

                                if (row.children) {
                                    loopThruParentChild(row.children, id);
                                }
                                
                            }
                        }

                        function processCalculation(row) {
                            if (row.value && !row.sqlId.includes("-TotalRow")) {
                                let itemToSum = row.value[colIndex];
                        
                                if (typeof itemToSum === 'string') {
                                    if (itemToSum.includes('%')) {
                                        const parsedValue = parseFloat(itemToSum.replace('%', '').trim());
                                        if (!isNaN(parsedValue)) {
                                            itemToSum = parsedValue.toFixed(2);
                                        }
                                    }
                                }
                        
                                totalAmount += parseFloat(itemToSum);
                            }
                        }
                            
                        itemData.push(totalAmount)
                    })
                    
                    item.data = itemData
                    chartData.datasets.push(item)
                }
            }
        })


        if(chartData.datasets.length > 0 && chartData.labels.length > 0)
        {
            chartData.labels = reconstructChartLabel(chartData.labels)
            setChartDataSet(chartData)
        }
        // console.log(chartData)
        function reconstructChartLabel(label)
        {
            const newLabelSet = label.map(item => { return item.label })
            return newLabelSet
        }
    }
    const handleSelectGraphHeader = (item) => {
        setSelectedGraphHeaderOption(item)
    }
    const options = {
        scales: {
            x: {
                ticks: {
                    color: 'black' // X-axis label color
                }
            },
            y: {
                ticks: {
                    color: 'black' // Y-axis label color
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    color: 'black' // Legend label color
                }
            }
        }
    };
    return (
        <>
        {chartDataSet &&
            <>
                <div className='templateVisualGraphHeaderContainer'>
                    {chartHeader && chartHeader.map((item) => (
                    <div 
                        className='templateVisualGraphHeaderItem' 
                        style={selectedGraphHeaderOption === item.id ? { backgroundColor : bgColor, color: textColor } : { backgroundColor : '#fafafa', color: bgColor }}
                        onClick={() => handleSelectGraphHeader(item.id)}
                    >{item.header}</div>
                    ))}
                </div>
                <div  style={{ maxHeight: screenHeight  - 300 }}>
                    {selectedViewOption === 'Line Chart' &&
                        <Line
                            datasetIdKey='id'
                            data={chartDataSet}
                            options={options}
                        />}
                    {selectedViewOption === 'Bar Chart' &&
                        <Bar
                            datasetIdKey='id'
                            data={chartDataSet}
                            options={options}
                        />
                    }
                </div>
            </>
        }
        </>
    )
}

export default DashboardTempaltemGraphView