import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Button, Table, Row, Col } from 'reactstrap';
import ReactPlayer from "react-player";
import { uploadBlob, pushToQueue } from 'service/AzureBlobService';
import Papa from 'papaparse';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { CsvHeaderCheck } from '../../../utils/utilityFunctions';
import CustomTooltip from '../../../customComponents/customComponents';

const WmbCSVUploadModal = ({ isOpen, onClose, selectedClient, userDetails, accountingFirm, wipDb, firmDetails, handleUpdateRecord, ...props }) => {

  const [file, setFile] = useState(null);
  const [csvHeader, setCSVHeader] = useState([])
  const [csvIsValid, setcsvIsValid] = useState(true)
  const [csvIsEmptyfile, setcsvIsEmptyfile] = useState(true)
  const [wipHistory, setWipHistory] = useState([]);
  const [alert, setAlert] = useState(null);
  const [csvErrors, setCsvErrors] = useState('');
  const [showTutorial, setShowTutorial] = useState(false);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setcsvIsEmptyfile(false)
  };
  const fileRef = useRef(null);

  useEffect(() => {
    // console.log('wipDb', Number(wipDb.csID))
    // console.log('wipDb', wipDb)
    setcsvIsValid(true)
    setcsvIsEmptyfile(false)
    loadHistory()
  }, [isOpen])

  const loadHistory = async () => {
    await fetch(`/api/clientService/getCsvUploadHistory?csv=wfm2_wip_csv&orgId=${wipDb.csID}`, {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((wipHistoryRecord) => {
        if (wipHistoryRecord.error) {
          wipHistoryRecord = {};
          return warningAlert(wipHistoryRecord);
        }

        setWipHistory(wipHistoryRecord);
      });
  }
  const warningAlert = (response) => {
    setAlert(
      <ReactBSAlert
        error
        title="Error"
        onConfirm={() => setAlert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {response.message ||
          response.error ||
          response.Details.warning ||
          response}
      </ReactBSAlert>
    );
  };
  const handleSubmit = async () => {
    // handleWIPUpload(file);
    if (file) {
      props.setShowBlockUI(true)
      const {isValid, startDate, endDate} = await fileValidateHeader(file, setCsvErrors);
      setcsvIsValid(isValid);
      console.log('startDate', startDate)
      console.log('endDate', endDate)

      if (isValid) {
        handleWIPUpload(file, startDate, endDate);
      } else {
        props.setShowBlockUI(false)
      }
    } else {
      setcsvIsEmptyfile(true)
      setcsvIsValid(true);
    }

    fileRef.current.value = '';
    setFile(null)
    // setcsvIsEmptyfile(false)
  };

  function fileValidateHeader(file, setCsvErrors) {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (result) {
          const firstObject = result.data[0];
          const keys = Object.keys(firstObject);

          const sortedByDate = result.data.sort((a, b) => {
            return new Date(a["[Ledger] Date"]) - new Date(b["[Ledger] Date"]);
          })

          setCSVHeader(keys);

          const { validCsv, errorsFound } = CsvHeaderCheck(keys, newValidWipClearedHeader);
          if (validCsv && !errorsFound.length) {
            resolve({ isValid: true, startDate:`${sortedByDate[0]["[Ledger] Date"]}`, endDate:`${sortedByDate[sortedByDate.length - 1]["[Ledger] Date"]}`});
            // resolve(true);
          } else {
            setCsvErrors(errorsFound);
            // resolve(false);
            resolve({isValid: false});
          }
        },
        error: function (error) {
          console.error('Error parsing CSV:', error);
          resolve(false);
        },
      });
    });
  }

  const handleWIPUpload = async (file, startDate, endDate) => {
    props.setShowBlockUI(true)
    setcsvIsEmptyfile(false)
    const uniqueFileId = new Date().getTime();
    const blobFileName = `QUEUE/CSV/${uniqueFileId}-wfm2-wip-csv-upload.csv`;
    const { dbName, csID, dw, tbName, staging } = wipDb;

    // console.log({ dbName, csID, dw, tbName, staging })

    await fetch(`/api/clientService/uploadCsv`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ fileName: blobFileName })
    })
      .then((response) => response.json())
      .then(async data => {
        console.log(data)

        // Upload to Azure Blob
        await uploadBlob(file, blobFileName, data);

        // // Push it to queue
        const QUEUENAME = 'wfm2-wip-upload-csv-queue';
        let firmCode = firmDetails?.Code.includes("-") ? firmDetails?.Code.replace("-", "") : firmDetails?.Code
        const messageMetadata = {
          organizationId: csID + "",
          clientId: staging ? '1' : `${firmCode.toUpperCase()}_CLIENT${selectedClient?.Details?.Legacy ? selectedClient?.Details?.Legacy?.id : selectedClient?.id}`,
          firmId: accountingFirm.id,
          dbName: "evadb_stage", //"etani-data-warehouse",
          tableName: tbName,
          originalFileName: file.name,
          blobFileName,
          uploader: {
            id: userDetails.User.id,
            email: userDetails.User.Email,
            userAccess: userDetails.User.UserAccess,
          },
          dw: dw,
          csvStartDate: startDate,
          csvEndDate: endDate
        }

        // console.log(messageMetadata)
        await pushToQueue(QUEUENAME, blobFileName, data, messageMetadata);
        props.setShowBlockUI(false)

        const updateClientService = await fetch(`/api/clientService/updateClientServiceAfterCsvUpload`, {
          method: "PUT",
          credentials: "include",
          body: JSON.stringify({
            clientId: selectedClient.id,
            firmId: selectedClient.AccountingFirm,
            serviceId: Number(csID)
          })
        })

        const updatedResult = await updateClientService.json()
        // console.log(updatedResult)

        setAlert(
          <ReactBSAlert
            success
            title="Success"
            onConfirm={() => {
              setAlert(null)
              onClose()
              handleUpdateRecord(updatedResult.updatedClientService)
            }}
            onCancel={() => setAlert(null)}
            confirmBtnBsStyle="success"
            confirmBtnText="Ok"
            btnSize=""
          >
            {"Your file has been successfully uploaded. Please allow approximately 3 minutes for it to be updated in your list."}
          </ReactBSAlert>
        );
      })
      .catch((err) => {
        console.log('UPLOAD WIP CSV ERROR', err);
        props.setShowBlockUI(false)
        setAlert(
          <ReactBSAlert
            error
            title="Error"
            onConfirm={() => setAlert(null)}
            confirmBtnBsStyle="danger"
            confirmBtnText="Ok"
            btnSize=""
          >
            err
          </ReactBSAlert>
        );
      });

  };

  return (
    <>
      <Modal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            margin: 'auto',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: '930px',
            height: '520px'
          }
        }}
        isOpen={isOpen}
        onRequestClose={() => {
          onClose()
          setFile(null)
        }
        }
        contentLabel="Upload CSV Modal"
      >
        {alert}
        <Row className="lg-12 pl-3">
          <h2>Workflow Max WIP Upload</h2>
          <CustomTooltip
            i="1"
            className="fas fa-play-circle"
            message="Play Tutorial"
            onClick={() => setShowTutorial(!showTutorial)}
            leftdiv={'text-danger'}
          />
        </Row>
        <div className="file-upload-container">
          <input type="file" accept=".csv" onChange={handleFileChange} ref={fileRef} />
          <div className="button-container">
            <Button onClick={handleSubmit}>Upload</Button>
            <Button onClick={() => {
              onClose()
              setFile(null)
            }
            }>Cancel</Button>
          </div>
        </div>
        <div className={`csvErr ${csvIsValid ? "hidden" : ""}`}>
          <em>Invalid CSV file:</em>
          {csvErrors.length ?
            <ul>{csvErrors.map((error, k) => <li key={k}>{error}</li>)}</ul>
            : null}
          <span className={`csvErr ${csvIsEmptyfile ? "" : "hidden"}`}>File is empty; please choose a valid file.</span>
        </div>
        <div className='CSVList-Container'>
          <Table className='CSVHistoryList'>
            <thead>
              <tr>
                <td>Uploader</td>
                <td>File Name</td>
                <td>Date</td>
                <td>Status</td>
              </tr>
            </thead>
            <tbody>
              {wipHistory.length > 0 && wipHistory.map((data) => (
                <tr key={data.id}>
                  <td>{data.uploader.email}</td>
                  <td>{data.originalFileName}</td>
                  <td>{dateFormatter(data.updatedAt)}</td>
                  <td>{data.Status}</td>
                </tr>
              ))}

            </tbody>
          </Table>
        </div>

      </Modal>
      <Modal
        className="vid-modal"
        size="lg"
        isOpen={showTutorial}
        style={{
          overlay: {
            zIndex: 9999
          },
          content: {
            zIndex: 10000
          }
        }}
      >
        <div className="modal-body ">
          <div className="modal-header px-0">
            <h6 className="modal-title">
              Tutorial
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setShowTutorial(!showTutorial)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div style={{ position: "relative", paddingTop: "56.25%" }}>
            <ReactPlayer
              className="react-player-custom"
              style={{ position: "absolute", top: 0, right: 0 }}
              url="https://vimeo.com/1014309629/0308c5377b"
              controls
              width="100%"
              height="100%"
              light={true}
              stopOnUnmount={false}
              playsinline={true}
              pip={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
                vimeo: {
                  playerOptions: {
                    autopause: true,
                    responsive: true,
                    loop: true,
                    keyboard: true,
                    pip: true,
                  },
                },
              }}
            />
          </div>
          <Row
            className="mt-3"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            {/* {videoList()} */}
            {/* <Courses /> */}
            <Col>
              <h3>Description</h3>
              {/* {selectedService?.Details?.TrainingVideoDescriptions
              ? parse(selectedService?.Details?.TrainingVideoDescriptions)
              : ""} */}
              <div className="" style={{ height: '180px', overflowY: 'scroll', border: '1px solid #ccc', padding: '10px', textAlign: 'left' }}>
                <strong>AA ETANI - WIP CSV Report</strong>
                <br />
                <div className="content" style={{ marginBottom: '20px' }}>
                  <ul>
                    <li>[Ledger] Staff</li>
                    <li>[Ledger] Date</li>
                    <li>[Ledger] Invoice Number</li>
                    <li>[Ledger] Invoiced Date</li>
                    <li>[Ledger] Ledger Type</li>
                    <li>[Ledger] Time</li>
                    <li>[Job] Job No.</li>
                    <li>[Job] Name</li>
                    <li>[Ledger] Description</li>
                    <li>[Ledger] Billable</li>
                    <li>[Ledger] Note</li>
                    <li>[Ledger] Invoiced Amount (Totalled)</li>
                    <li>[Ledger] Billable Amount (Totalled)</li>
                    <li>[Ledger] Billable Rate</li>
                    <li>[Ledger] Standard Rate</li>
                    <li>[Job] Category</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default WmbCSVUploadModal;

const validWipClearedHeader = [
  "[Ledger] Staff",
  "[Ledger] Date",
  "[Ledger] Invoice Number",
  "[Ledger] Invoiced Date",
  "[Ledger] Ledger Type",
  "[Ledger] Time",
  "[Job] Job No.",
  "[Job] Name",
  "[Ledger] Description",
  "[Ledger] Billable",
  "[Ledger] Note",
  "[Ledger] Invoiced Amount (Totalled)",
  "[Ledger] Billable Amount (Totalled)",
]

const newValidWipClearedHeader = [
  "[Ledger] Staff",
  "[Ledger] Date",
  "[Ledger] Invoice Number",
  "[Ledger] Invoiced Date",
  "[Ledger] Ledger Type",
  "[Ledger] Time",
  "[Job] Job No.",
  "[Job] Name",
  "[Ledger] Description",
  "[Ledger] Billable",
  "[Ledger] Note",
  "[Ledger] Invoiced Amount (Totalled)",
  "[Ledger] Billable Amount (Totalled)",
  "[Ledger] Billable Rate",
  "[Ledger] Standard Rate",
  "[Job] Category",
  "[Ledger] Invoiced Time"
]

function dateFormatter(myData) {
  const originalDate = myData;
  const formattedDate = new Date(originalDate).toLocaleString();
  return formattedDate;
}

