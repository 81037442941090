import React, { useEffect, useState, useContext } from "react";
import classnames from "classnames";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Modal,
  Button,
  Col,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Clients from "./Clients";
import Authority from "./Authority";
import AccountantDetailsForm from "./AccountantDetailsForm";
import ProfileInfo from "components/ProfileInfo/ProfileInfo";

import { MainContext } from "context/mainContext";
import BlockUI from "components/Misc/BlockUI";
import CustomHeader from "customComponents/customHeader";
import { CustomButton } from "components/Button/CustomButton";
import { ClipLoader } from "react-spinners";
import MFABlock from "components/MFABlock/MFABlock";

import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import { set } from "lodash";
import { useHistory } from "react-router";

const AccountantDetails = () => {
  const {
    bgColor,
    textColor,
    userDetails,
    getUserDetails,
    setSelectedClientID,
    selectedClientID,
    contextFunctionsLoading,
    setContextFunctionLoading,
    firmDetails
  } = useContext(MainContext);
  const [tabs, setTabs] = useState(1);
  const [payload, setPayload] = useState({});
  const [alert, setalert] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [removedClients, setRemovedClients] = useState([]);
  const [avatar, setAvatar] = useState();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isFirstNameValid, setIsFirstNameValid] = useState(true);
  const [isLastNameValid, setIsLastNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPositionValid, setIsPositionValid] = useState(true);
  const [accountantDetails, setAccountantDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showBlockUI, setShowBlockUI] = useState(false);
  // eslint-disable-next-line
  const [isAvatarUpdate, setIsAvatarUpdate] = useState(false);
  const [oldImage, setOldImage] = useState();
  const [showPaymentInfo, setShowPaymentInfo] = useState(false);
  const [userPaymentDetails, setUserPaymentDetails] = useState({})
  const [showTandC, setShowTandC] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState({})
  const [showMFASettings, setShowMFASettings] = useState(false)
  const routerHistory = useHistory();

  const fetchAPIs = async () => {
    setContextFunctionLoading(true);
    await fetch("/api/user/invitedUserDetails", { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        // console.log('/api/user/invitedUserDetails', data)
        if (data) {
          if (data.UserAccess >= 5) {
            if (data.error) {
              // console.log(data)
              setIsLoading(false);
              setContextFunctionLoading(false);
              errorAlert(data.error);
            } else {
              fetch(`/api/user/getClientUserDetails/${data.UserID}`, {
                credentials: "include",
              })
                .then((res) => res.json())
                .then((data) => {
                  // console.log('getAccountantDetails',data)
                  setAccountantDetails({ User: data });
                  setPayload({
                    Email: data.Email,
                    FirstName: data.FirstName,
                    LastName: data.LastName,
                    Avatar: data.Avatar,
                  });
                  setIsLoading(false);
                  setContextFunctionLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else if (data.UserAccess < 5) {
            const userID = data.UserID;
            let accountantData = [];
            fetch(`/api/accountant/getAccountantDetails/${userID}`, {
              credentials: "include",
            })
              .then((res) => res.json())
              .then((data) => {
                // console.log("ADMIN-DETAILS", data);
                if (data.error) {
                  // console.log(data)
                  setIsLoading(false);
                  errorAlert(data.error);
                } else {
                  if (
                    data[0].User &&
                    data[0].User.Details &&
                    !data[0].User.Details.Authority
                  ) {
                    data[0].User.Details = JSON.parse(data[0].User.Details);
                  }

                  accountantData = data;
                  setAccountantDetails(data[0]);
                  setIsLoading(false);
                  setContextFunctionLoading(false);

                  fetch(`/api/stripe/getCustomerPaymentDetails?email=${accountantData[0]?.User?.Email}&description=${firmDetails?.Name}`, { credentials: "include" })
                    .then((res) => res.json())
                    .then((data) => {
                      // console.log('getCustomerPaymentDetails', data)
                      if (data && !data.error) {
                        setUserPaymentDetails(data.record)
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              })
              .then(() => {
                let clients = [];
                if (accountantData[0]) {
                  // eslint-disable-next-line
                  accountantData[0].Client.map((clnt) => {
                    clients.push(clnt.id);
                  });
                }
                setSelectedClients(clients);
                setOldImage(
                  accountantData[0] && accountantData[0].User.Avatar
                    ? accountantData[0].User.Avatar
                    : ""
                );
                setPayload({
                  Email:
                    accountantData[0] && accountantData[0].User.Email
                      ? accountantData[0].User.Email
                      : "",
                  UserName:
                    accountantData[0] &&
                      accountantData[0].User.Credential.length >= 1 &&
                      accountantData[0].User.Credential[0].Details?.username
                      ? accountantData[0].User.Credential[0].Details?.username
                      : "",
                  FirstName:
                    accountantData[0] && accountantData[0].User.FirstName
                      ? accountantData[0].User.FirstName
                      : "",
                  LastName:
                    accountantData[0] && accountantData[0].User.LastName
                      ? accountantData[0].User.LastName
                      : "",
                  Phone:
                    accountantData[0] && accountantData[0].User.Phone
                      ? accountantData[0].User.Phone
                      : "",
                  Avatar:
                    accountantData[0] && accountantData[0].User.Avatar
                      ? accountantData[0].User.Avatar
                      : "",
                  Position:
                    accountantData[0] && accountantData[0].Name
                      ? accountantData[0].Name
                      : "",
                  Description:
                    accountantData[0] && accountantData[0].Description
                      ? accountantData[0].Description
                      : "",
                  Client: clients,
                  authority: {
                    Admin:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority.Admin
                        ? accountantData[0].User.Details.Authority.Admin
                        : false,
                    AnalyticsDashboardReportGreen:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority
                          .AnalyticsDashboardReportGreen
                        ? accountantData[0].User.Details.Authority
                          .AnalyticsDashboardReportGreen
                        : false,
                    AnalyticsDashboardReportOrange:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority
                          .AnalyticsDashboardReportOrange
                        ? accountantData[0].User.Details.Authority
                          .AnalyticsDashboardReportOrange
                        : false,
                    AnalyticsDashboardReports:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority
                          .AnalyticsDashboardReports
                        ? accountantData[0].User.Details.Authority
                          .AnalyticsDashboardReports
                        : false,
                    Chat:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority.Chat
                        ? accountantData[0].User.Details.Authority.Chat
                        : false,
                    PermanentDocs:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority.PermanentDocs
                        ? accountantData[0].User.Details.Authority.PermanentDocs
                        : false,
                    PracticeManagerAccess:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority
                          .PracticeManagerAccess
                        ? accountantData[0].User.Details.Authority
                          .PracticeManagerAccess
                        : false,
                    PushNotifications:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority.PushNotifications
                        ? accountantData[0].User.Details.Authority
                          .PushNotifications
                        : false,
                    ThirdPartyConnectors:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority
                          .ThirdPartyConnectors
                        ? accountantData[0].User.Details.Authority
                          .ThirdPartyConnectors
                        : false,
                    MySQLAccess:
                      accountantData[0] &&
                        accountantData[0].User.Details.Authority
                          .MySQLAccess
                        ? accountantData[0].User.Details.Authority
                          .MySQLAccess
                        : {},
                  },
                  Admin:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority.Admin
                      ? accountantData[0].User.Details.Authority.Admin
                      : false,
                  AnalyticsDashboardReportGreen:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority
                        .AnalyticsDashboardReportGreen
                      ? accountantData[0].User.Details.Authority
                        .AnalyticsDashboardReportGreen
                      : false,
                  AnalyticsDashboardReportOrange:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority
                        .AnalyticsDashboardReportOrange
                      ? accountantData[0].User.Details.Authority
                        .AnalyticsDashboardReportOrange
                      : false,
                  AnalyticsDashboardReports:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority
                        .AnalyticsDashboardReports
                      ? accountantData[0].User.Details.Authority
                        .AnalyticsDashboardReports
                      : false,
                  Chat:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority.Chat
                      ? accountantData[0].User.Details.Authority.Chat
                      : false,
                  PermanentDocs:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority.PermanentDocs
                      ? accountantData[0].User.Details.Authority.PermanentDocs
                      : false,
                  PracticeManagerAccess:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority
                        .PracticeManagerAccess
                      ? accountantData[0].User.Details.Authority
                        .PracticeManagerAccess
                      : false,
                  PushNotifications:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority.PushNotifications
                      ? accountantData[0].User.Details.Authority
                        .PushNotifications
                      : false,
                  ThirdPartyConnectors:
                    accountantData[0] &&
                      accountantData[0].User.Details.Authority
                        .ThirdPartyConnectors
                      ? accountantData[0].User.Details.Authority
                        .ThirdPartyConnectors
                      : false,
                  MySQLAccess: accountantData[0] &&
                    accountantData[0].User.Details.Authority
                      .MySQLAccess
                    ? accountantData[0].User.Details.Authority
                      .MySQLAccess
                    : [],
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }

          fetch(`/api/termsAndConditions/getRecord?IntendedTo=${data?.UserAccess < 5 ? 'Advisors' : 'ClientUsers'}`)
            .then((response) => response.json())
            .then((data) => {
              setTermsAndConditions(data)
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // fetch('/api/user/userDetails', { credentials: 'include' })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     // console.log("USERDETAILS",data)
    //     const userID = data.id
    //     let accountantData = []
    //     fetch(`/api/accountant/getAccountantDetails/${userID}`, {
    //       credentials: 'include',
    //     })
    //       .then((res) => res.json())
    //       .then((data) => {
    //         // console.log("ADMIN-DETAILS", data)
    //         if (data.error) {
    //           setIsLoading(false)
    //           errorAlert(data.error)
    //         } else {
    //           accountantData = data
    //           setAccountantDetails(data[0])
    //           setIsLoading(false)
    //         }
    //       })
    //       .then(() => {
    //         let clients = []
    //         if (accountantData[0]) {
    //           // eslint-disable-next-line
    //           accountantData[0].Client.map((clnt) => {
    //             clients.push(clnt.id)
    //           })
    //         }
    //         setSelectedClients(clients)
    //         setOldImage(
    //           accountantData[0] && accountantData[0].User.Avatar
    //             ? accountantData[0].User.Avatar
    //             : '',
    //         )
    //         setPayload({
    //           Email:
    //             accountantData[0] && accountantData[0].User.Email
    //               ? accountantData[0].User.Email
    //               : '',
    //           UserName:
    //             accountantData[0] && accountantData[0].User.UserName
    //               ? accountantData[0].User.UserName
    //               : '',
    //           FirstName:
    //             accountantData[0] && accountantData[0].User.FirstName
    //               ? accountantData[0].User.FirstName
    //               : '',
    //           LastName:
    //             accountantData[0] && accountantData[0].User.LastName
    //               ? accountantData[0].User.LastName
    //               : '',
    //           Phone:
    //             accountantData[0] && accountantData[0].User.Phone
    //               ? accountantData[0].User.Phone
    //               : '',
    //           Avatar:
    //             accountantData[0] && accountantData[0].User.Avatar
    //               ? accountantData[0].User.Avatar
    //               : '',
    //           Position:
    //             accountantData[0] && accountantData[0].Name
    //               ? accountantData[0].Name
    //               : '',
    //           Description:
    //             accountantData[0] && accountantData[0].Description
    //               ? accountantData[0].Description
    //               : '',
    //           Client: clients,
    //           authority: {
    //             Admin:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority.Admin
    //                 ? accountantData[0].User.Details.Authority.Admin
    //                 : false,
    //             AnalyticsDashboardReportGreen:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority
    //                 .AnalyticsDashboardReportGreen
    //                 ? accountantData[0].User.Details.Authority
    //                     .AnalyticsDashboardReportGreen
    //                 : false,
    //             AnalyticsDashboardReportOrange:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority
    //                 .AnalyticsDashboardReportOrange
    //                 ? accountantData[0].User.Details.Authority
    //                     .AnalyticsDashboardReportOrange
    //                 : false,
    //             AnalyticsDashboardReports:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority
    //                 .AnalyticsDashboardReports
    //                 ? accountantData[0].User.Details.Authority
    //                     .AnalyticsDashboardReports
    //                 : false,
    //             Chat:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority.Chat
    //                 ? accountantData[0].User.Details.Authority.Chat
    //                 : false,
    //             PermanentDocs:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority.PermanentDocs
    //                 ? accountantData[0].User.Details.Authority.PermanentDocs
    //                 : false,
    //             PracticeManagerAccess:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority.PracticeManagerAccess
    //                 ? accountantData[0].User.Details.Authority
    //                     .PracticeManagerAccess
    //                 : false,
    //             PushNotifications:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority.PushNotifications
    //                 ? accountantData[0].User.Details.Authority.PushNotifications
    //                 : false,
    //             ThirdPartyConnectors:
    //               accountantData[0] &&
    //               accountantData[0].User.Details.Authority.ThirdPartyConnectors
    //                 ? accountantData[0].User.Details.Authority
    //                     .ThirdPartyConnectors
    //                 : false,
    //           },
    //           Admin:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority.Admin
    //               ? accountantData[0].User.Details.Authority.Admin
    //               : false,
    //           AnalyticsDashboardReportGreen:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority
    //               .AnalyticsDashboardReportGreen
    //               ? accountantData[0].User.Details.Authority
    //                   .AnalyticsDashboardReportGreen
    //               : false,
    //           AnalyticsDashboardReportOrange:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority
    //               .AnalyticsDashboardReportOrange
    //               ? accountantData[0].User.Details.Authority
    //                   .AnalyticsDashboardReportOrange
    //               : false,
    //           AnalyticsDashboardReports:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority
    //               .AnalyticsDashboardReports
    //               ? accountantData[0].User.Details.Authority
    //                   .AnalyticsDashboardReports
    //               : false,
    //           Chat:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority.Chat
    //               ? accountantData[0].User.Details.Authority.Chat
    //               : false,
    //           PermanentDocs:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority.PermanentDocs
    //               ? accountantData[0].User.Details.Authority.PermanentDocs
    //               : false,
    //           PracticeManagerAccess:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority.PracticeManagerAccess
    //               ? accountantData[0].User.Details.Authority
    //                   .PracticeManagerAccess
    //               : false,
    //           PushNotifications:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority.PushNotifications
    //               ? accountantData[0].User.Details.Authority.PushNotifications
    //               : false,
    //           ThirdPartyConnectors:
    //             accountantData[0] &&
    //             accountantData[0].User.Details.Authority.ThirdPartyConnectors
    //               ? accountantData[0].User.Details.Authority
    //                   .ThirdPartyConnectors
    //               : false,
    //         })
    //       })
    //   })

    await fetch("/api/client/getAll", { credentials: "include" })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return errorAlert(data.error);
        }
        if (data && data.length > 0) {
          setClients(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    let isMounted = true;

    // console.log(userDetails)

    if (isMounted) {
      // setSelectedClientID();
      fetchAPIs();
    }

    return () => {
      isMounted = false;
    };

    // return () => {
    //   //added to cleanup asynchronous task
    //   setAccountantDetails(accountantDetails)
    //   setIsLoading(isLoading)
    //   setPayload(payload)
    //   setClients(clients)
    // }

    //eslint-disable-next-line
  }, [userDetails, selectedClientID]);

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);
  };

  const handleInputChange = (e) => {
    // console.log('inputchange', payload)
    let target = e.target;
    let val = "";
    if (target.type === "checkbox") {
      val = target.checked;
    } else if (target.type === "file") {
      const reader = new FileReader();
      const file = target.files[0];

      reader.onload = function (upload) {
        setAvatar(upload.target.result);

        let inputElement = target.nextSibling;
        inputElement.value = upload.target.result;
        inputElement.dispatchEvent(new Event("input", { bubbles: true }));
      };
      reader.readAsDataURL(file);
    } else {
      val = target.value;
    }
    let key = target.id;
    if (key === "FirstName") {
      if (!val || !val.match(/\S/g)) {
        setIsFirstNameValid(false);
      } else {
        setIsFirstNameValid(true);
      }
    }
    if (key === "Email") {
      const emailRegExp = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      let result = emailRegExp.test(target.value);
      if (!val || !result || !val.match(/\S/g) || !result) {
        setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    }
    if (key === "LastName") {
      if (!val || !val.match(/\S/g)) {
        setIsLastNameValid(false);
      } else {
        setIsLastNameValid(true);
      }
    }
    if (key === "Phone") {
      if (!val || !val.match(/\S/g)) {
        setIsPhoneValid(false);
      } else {
        setIsPhoneValid(true);
      }
    }
    if (key === "Position" && accountantDetails?.User?.UserAccess < 5) {
      if (!val || !val.match(/\S/g)) {
        setIsPositionValid(false);
      } else {
        setIsPositionValid(true);
      }
    }
    const newPayload = { ...payload };
    newPayload[key] = val;
    if (key === "AnalyticsDashboardReports" && newPayload[key] === false) {
      newPayload.AnalyticsDashboardReportGreen = false;
      newPayload.AnalyticsDashboardReportOrange = false;
    }
    if (key === "AnalyticsDashboardReportOrange" && newPayload[key] === false) {
      newPayload.AnalyticsDashboardReportGreen = false;
    }
    // console.log(val)
    setPayload(newPayload);
  };

  const handleImgChange = (img) => {
    setAvatar(img);
    setIsAvatarUpdate(true);
  };

  const resetImage = () => {
    // console.log('clicked')
    setAvatar(oldImage);
  };

  const handleClientSelection = (clients) => {
    let clientsArray = [];

    for (let index in clients) {
      clientsArray.push(index);
    }

    setSelectedClients(clientsArray);
  };

  const warningAlert = () => {
    setalert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() => setalert(null)}
        // onCancel={() => setalert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Confirm"
        btnSize=""
      // showCancel={true}
      >
        <p>Details are not complete. Please check and try again.</p>
      </ReactBSAlert>
    );
  };

  const confirmSave = (type) => {
    // console.log('click')
    // if (accountantDetails?.User?.UserAccess >= 5) {
    //   return
    // }
    if (type === "info") {
      // console.log(type);
      if (accountantDetails?.User?.UserAccess < 5) {
        if (
          !isEmailValid ||
          !isFirstNameValid ||
          !isLastNameValid ||
          // !isPhoneValid ||
          !isPositionValid ||
          !payload.Email ||
          !payload.FirstName ||
          !payload.LastName ||
          // !payload.Phone ||
          !payload.Position
        ) {
          return warningAlert();
        }
      } else {
        if (
          !isEmailValid ||
          !isFirstNameValid ||
          !isLastNameValid ||
          !payload.Email ||
          !payload.FirstName ||
          !payload.LastName
        ) {
          return warningAlert();
        }
      }
    }
    setalert(
      <ReactBSAlert
        warning
        title="Warning"
        onConfirm={() =>
          type === "info"
            ? handleUpdateAccountantInfo()
            : type === "clients"
              ? handleUpdateClients()
              : type === "authority"
                ? handleUpdateAuthority()
                : null
        }
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="warning"
        confirmBtnText="Confirm"
        btnSize=""
        showCancel={true}
      >
        <p>
          Are you sure you want to apply changes to user:{" "}
          <strong className="text-info">{`${accountantDetails.User.FirstName} ${accountantDetails.User.LastName}`}</strong>
          ?
        </p>
      </ReactBSAlert>
    );
  };

  const successAlert = (message, reload) => {
    setalert(
      <ReactBSAlert
        success
        title="Success"
        onConfirm={() => reload ? window.location.reload() : setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="success"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const errorAlert = (message) => {
    setalert(
      <ReactBSAlert
        danger
        title="Error"
        onConfirm={() => setalert(null)}
        onCancel={() => setalert(null)}
        confirmBtnBsStyle="danger"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  };

  const handleUpdateAccountantInfo = () => {
    // console.log("accountantDetails", accountantDetails)
    // console.log("payload", payload)
    const parameters = {
      user: {},
      accountant: {},
    };

    parameters.user = Object.assign({}, payload);
    parameters.user.Avatar = avatar;

    if (accountantDetails?.User?.UserAccess < 5) {
      parameters.accountant = {
        Name: payload.Position,
        Description: payload.Description,
      };
      delete parameters.user.Position;
      delete parameters.user.Description;
      delete parameters.user.Client;
      delete parameters.user.authority;
    }

    // console.log(parameters)

    setShowBlockUI(true);
    if (accountantDetails?.User?.UserAccess < 5) {
      fetch(
        `/api/accountant/updateAccountantInformation/${accountantDetails.id}`,
        {
          method: "PUT",
          credentials: "include",
          body: JSON.stringify(parameters),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
          setTimeout(() => {
            if (data.error) {
              setShowBlockUI(false);
              return errorAlert(data.error);
            } else {
              fetchAPIs();
              getUserDetails();
              setShowBlockUI(false);
              successAlert("Record has been updated successfully");
              // window.location.reload();
              // setShowBlockUI(false)
              // setTimeout(() => {
              window.location.reload();
              // }, 1000)
            }
          }, 1000);
        })
        .catch((err) => {
          return errorAlert(err);
        });
    } else {
      // console.log('USERID',accountantDetails.User.UserID)
      fetch(
        `/api/user/updateClientUserRecord/${accountantDetails.User.UserID}`,
        {
          method: "PUT",
          body: JSON.stringify(parameters.user),
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log('updateClientUserRecord',data)
          setTimeout(() => {
            if (data.error) {
              setShowBlockUI(false);
              return errorAlert(data.error);
            } else {
              fetchAPIs();
              getUserDetails();
              setShowBlockUI(false);
              successAlert("Record has been updated successfully");
              // window.location.reload();
              // setShowBlockUI(false)
              // setTimeout(() => {
              window.location.reload();
              // }, 1000)
            }
          }, 1000);
        })
        .catch((err) => {
          return errorAlert(err);
        });
    }
  };

  const handleUpdateClients = () => {
    setShowBlockUI(true);
    fetch(`/api/accountant/updateAccountantClient/${accountantDetails.id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({ client: selectedClients }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setShowBlockUI(false);
          return errorAlert(data.error);
        } else {
          if (selectedClients.length >= 1) {
            // console.log(selectedClients)
            fetch(
              `/api/customReport/subscribeToWorkspaceByAdvisorProfile/${accountantDetails.id}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  selectedClients: selectedClients,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                // console.log(data)
                // successAlert(data.message)
              });
          }
          if (removedClients.length >= 1) {
            fetch(
              `/api/customReport/unsubscribeToWorkspaceByAdvisorProfile/${accountantDetails.id}`,
              {
                method: "POST",
                credentials: "include",
                body: JSON.stringify({
                  removedClients: removedClients,
                }),
              }
            )
              .then((res) => res.json())
              .then((data) => {
                // console.log(data.message)
              });
          }
        }
      })
      .then(() => {
        fetchAPIs();
        setShowBlockUI(false);
        successAlert("Record has been updated successfully");
      })
      .catch((err) => {
        return errorAlert(err);
      });
  };

  const handleUpdateAuthority = (MySQLAccess, isEditMySQLAccess = false) => {
    // console.log('payload', payload)
    if((MySQLAccess && isEditMySQLAccess) || !MySQLAccess)
      setShowBlockUI(true);


    fetch(`/api/accountant/updateAuthority/${accountantDetails.id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        Admin: payload.Admin,
        AnalyticsDashboardReportGreen: payload.AnalyticsDashboardReportGreen,
        AnalyticsDashboardReportOrange: payload.AnalyticsDashboardReportOrange,
        AnalyticsDashboardReports: payload.AnalyticsDashboardReports,
        Chat: payload.Chat,
        PermanentDocs: payload.PermanentDocs,
        PracticeManagerAccess: payload.PracticeManagerAccess,
        PushNotifications: payload.PushNotifications,
        ThirdPartyConnectors: payload.ThirdPartyConnectors,
        MySQLAccess: MySQLAccess ? MySQLAccess: payload.authority.MySQLAccess
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log('data', data)
        if (data.error) {
          setShowBlockUI(false);
          return errorAlert(data.error);
        } else {
          fetchAPIs();
          setShowBlockUI(false);
          

          if(!MySQLAccess) {
            successAlert("Record has been updated successfully");
            window.location.reload();
          }
            
        }
      })
      .catch((err) => {
        return errorAlert(err);
      });
  };

  const setShowBlockUIModal = () => {
    return <BlockUI />;
  };

  const handleShowPaymentInfo = () => {
    // console.log('detailspayment')
    setShowPaymentInfo(!showPaymentInfo)
  }

  const handleShowTandC = () => {
    setShowTandC(true)
  }

  const handleCloseTandC = () => {
    setShowTandC(false)
  }

  const handleShowMFASettings = () => {
    // console.log('SHOW MFA', userDetails)
    setShowMFASettings(true)
  }

  const handleCloseMFASettings = () => {
    setShowMFASettings(false)
  }

  const handleDisableMFASettings = () => {
    setShowBlockUI(true)
    // console.log('disabled')
    const mfaUpdateSettings = async () => {
      try {
        const details = await fetch("/api/auth/mfaUpdateSetup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ MFASettings: false }),
        });
        const data = await details.json();
        if (data.success) {
          setShowBlockUI(false)
          successAlert(data.message, true)
          setShowMFASettings(false)
          // return routerHistory.push("/login");
          // window.location.reload();
        }
        if (!data.success) {
          setShowBlockUI(false)
          errorAlert(data.message)
        }
      } catch (error) {
        setShowBlockUI(false)
        console.log(error);
      }
    };
    mfaUpdateSettings();
  }

  const acceptTerms = () => {
    // console.log("asdasd", userDetails)
    setShowBlockUI(true);
    fetch("/api/termsAndConditions/acceptTandC", {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify({
        Business: userDetails.AccountingFirm,
        User: userDetails.User.id,
      })
    })
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message === 'Terms and conditions has been accepted.') {
          setShowBlockUI(false);
          setShowTandC(false);
          window.location.reload();
        }
        if (data.error) {
          setShowBlockUI(false);
          errorAlert(data.error);
        }
      })
      .catch((err) => {
        setShowBlockUI(false);
        errorAlert(err);
      });
  }

  return (
    <>
      {alert}
      {showBlockUI ? setShowBlockUIModal() : null}
      <Modal
        className="modal-dialog-top"
        isOpen={showTandC}
        toggle={() => setShowTandC(!showTandC)}
        size="lg"
        backdrop="static"
        centered={true}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Terms and Conditions
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => handleCloseTandC()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div
          className="modal-body text-left"
          style={{ paddingTop: "0px", textAlign: "left" }}
        >
          <h3>{userDetails?.User?.Details?.DateAcceptedTermsAndConditions ? `Accepted at: ${moment(userDetails?.User?.Details?.DateAcceptedTermsAndConditions).format("LLL")}` : ""}</h3>

          {
            termsAndConditions ? <ReactQuill
              value={termsAndConditions.Details}
              readOnly={true}
              theme={"bubble"}
            /> : null
          }
        </div>
        <div
          className="modal-footer"
          style={{ display: "flex", justifyContent: "center" }}
        >{
            userDetails?.User?.Details?.DateAcceptedTermsAndConditions ? null
              :
              <Button
                style={{ backgroundColor: "#272A65", borderColor: "#272A65" }}
                className="mt-2"
                color="primary"
                type="button"
                onClick={(e) => {
                  acceptTerms();
                }}
              >
                Accept
              </Button>
          }
        </div>
      </Modal>
      <Modal
        className="modal-dialog-top"
        isOpen={showMFASettings}
        toggle={() => setShowMFASettings(!showMFASettings)}
        size="lg"
        backdrop="static"
        centered={true}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Turn Off Multi Factor Authentication?
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => handleCloseMFASettings()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div
          className="modal-body text-left"
          style={{ paddingTop: "0px", textAlign: "left" }}
        >
          <p
            style={{
              textAlign: "left",
              margin: "0px",
              marginBottom: "20px",
              fontSize: "18px",
            }}
          >
            This action is only recommended if you've lost your phone.
          </p>
        </div>
        <div
          className="modal-footer"
        // style={{ display: "flex", justifyContent: "center" }}
        // style={{ padding: "0px 20px 10px 20px" }}
        >
          <CustomButton
            type="button"
            onClick={handleDisableMFASettings}
            outlineStyle={false}
            title={"Confirm"}
          />
          <CustomButton
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => handleCloseMFASettings()}
            outlineStyle={true}
            title="Cancel"
          />
          {/* <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
          // onClick={submitModalHandler}
          >
            Confirm
          </Button>
          <Button
            style={{
              backgroundColor: "#32325d",
              border: "1px solid #32325d",
              color: "#fff",
            }}
            data-dismiss="modal"
            onClick={handleCloseMFASettings}
          >
            Cancel
          </Button> */}
        </div>
      </Modal>
      <CustomHeader style={{ height: "100px" }} />
      <Container className="mt-5 fadeIn" fluid>
        <Row>
          <div className="col mt-3">
            <Card className="">
              <CardHeader>
                <div>
                  {accountantDetails ? (
                    <ProfileInfo
                      data={accountantDetails}
                      isLoading={isLoading}
                      bgColor={bgColor}
                      contextFunctionsLoading={contextFunctionsLoading}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                <div className="nav-wrapper">
                  {contextFunctionsLoading || isLoading ? (
                    <div className="text-center" style={{ marginTop: "100px" }}>
                      <ClipLoader color={bgColor} size={20} margin={2} />
                    </div>
                  ) : (
                    <Nav
                      className="nav-fill flex-column flex-md-row"
                      id="tabs-icons-text"
                      pills
                      role="tablist"
                    >
                      <NavItem>
                        <NavLink
                          aria-selected={tabs === 1}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: tabs === 1,
                          })}
                          onClick={(e) => toggleNavs(e, tabs, 1)}
                          href="#pablo"
                          role="tab"
                          style={{
                            backgroundColor: `${tabs === 1 && bgColor === "#fff"
                              ? "#5e72e4"
                              : tabs === 1
                                ? bgColor
                                : "#fff"
                              }`,
                            color: `${tabs === 1 && textColor === "#000"
                              ? "#fff"
                              : tabs === 1
                                ? textColor
                                : "#525f7f"
                              }`,
                          }}
                        >
                          <i className="ni ni-briefcase-24 mr-2" />
                          {`${accountantDetails?.User?.UserAccess >= 5
                            ? `User`
                            : `Admin`
                            } Details`}
                        </NavLink>
                      </NavItem>
                      {accountantDetails?.User?.UserAccess >= 5 ||
                        accountantDetails?.User?.Details?.Authority?.Admin ===
                        false ? null : (
                        <>
                          <NavItem>
                            <NavLink
                              aria-selected={tabs === 2}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 2,
                              })}
                              onClick={(e) => toggleNavs(e, tabs, 2)}
                              href="#pablo"
                              role="tab"
                              style={{
                                backgroundColor: `${tabs === 2 && bgColor === "#fff"
                                  ? "#5e72e4"
                                  : tabs === 2
                                    ? bgColor
                                    : "#fff"
                                  }`,
                                color: `${tabs === 2 && textColor === "#000"
                                  ? "#fff"
                                  : tabs === 2
                                    ? textColor
                                    : "#525f7f"
                                  }`,
                              }}
                            >
                              <i className="ni ni-money-coins mr-2" />
                              Clients
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={tabs === 3}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: tabs === 3,
                              })}
                              onClick={(e) => toggleNavs(e, tabs, 3)}
                              href="#pablo"
                              role="tab"
                              style={{
                                backgroundColor: `${tabs === 3 && bgColor === "#fff"
                                  ? "#5e72e4"
                                  : tabs === 3
                                    ? bgColor
                                    : "#fff"
                                  }`,
                                color: `${tabs === 3 && textColor === "#000"
                                  ? "#fff"
                                  : tabs === 3
                                    ? textColor
                                    : "#525f7f"
                                  }`,
                              }}
                            >
                              <i className="ni ni-lock-circle-open mr-2" />
                              Authority
                            </NavLink>
                          </NavItem>
                        </>
                      )}
                    </Nav>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                {contextFunctionsLoading || isLoading ? null : (
                  <TabContent activeTab={"tabs" + tabs}>
                    <TabPane className="fadeIn" tabId="tabs1">
                      {
                        userDetails.User?.Details?.MFAEnabled ?
                          <MFABlock
                            userDetails={userDetails}
                            showMFASettings={showMFASettings}
                            handleShowMFASettings={handleShowMFASettings}
                          />
                          : ''
                      }
                      <AccountantDetailsForm
                        handleInputChange={handleInputChange}
                        data={payload}
                        isEmailValid={isEmailValid}
                        isFirstNameValid={isFirstNameValid}
                        isLastNameValid={isLastNameValid}
                        isPhoneValid={isPhoneValid}
                        isPositionValid={isPositionValid}
                        avatar={avatar}
                        handleImgChange={handleImgChange}
                        isLoading={isLoading}
                        resetImage={resetImage}
                        userAccess={accountantDetails?.User?.UserAccess}
                        handleShowPaymentInfo={handleShowPaymentInfo}
                        showPaymentInfo={showPaymentInfo}
                        userPaymentDetails={userPaymentDetails}
                        userDetails={userDetails}
                        firmDetails={firmDetails}
                      // onProfilePage={true}
                      />
                      {/* {
                        userPaymentDetails?.object && !showPaymentInfo ?
                          <>
                            <FormGroup>
                              <label
                                className="form-control-label btn-link"
                                // htmlFor="exampleFormControlInput1"
                                style={{ cursor: 'pointer' }}
                                onClick={handleShowPaymentInfo}
                              >
                                View payment info
                              </label>
                            </FormGroup>
                          </>
                          : null
                      } */}
                      {/* {
                        showPaymentInfo ? null :
                      } */}
                      <>
                        <FormGroup>
                          <label
                            className="form-control-label btn-link"
                            // htmlFor="exampleFormControlInput1"
                            style={{ cursor: 'pointer' }}
                            onClick={handleShowTandC}
                          >
                            View Terms and Conditions
                          </label>
                        </FormGroup>
                      </>
                      <>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="exampleFormControlInput1"
                          >
                            EVA Training <span className="font-weight-300"> - Opt in to let Etani use your business content to train EVA.</span>
                          </label>
                          <div>
                            <Row>
                              <Col className="col-lg-1" xs="2">
                                <label className="custom-toggle custom-toggle-success mr-1">
                                  <input
                                    defaultChecked={userDetails?.User?.Details?.OptInEvaTraining ?? false}
                                    type="checkbox"
                                    onClick={handleInputChange}
                                    id="OptInEvaTraining"
                                    name="OptInEvaTraining"
                                  // disabled={
                                  //   props.selectedAccountant?.User?.id ===
                                  //     props.userDetails?.User?.id
                                  //     ? true
                                  //     : false
                                  // }
                                  />
                                  <span
                                    className="custom-toggle-slider rounded-circle"
                                    data-label-off="No"
                                    data-label-on="Yes"
                                  />
                                </label>
                              </Col>
                              {/* <Col className="col-lg-11" xs="10">
                                <p>
                                  Let Etani use your business content to train EVA.
                                </p>
                              </Col> */}
                            </Row>
                          </div>
                        </FormGroup>
                      </>
                      <CustomButton
                        onClick={() => confirmSave("info")}
                        type="button"
                        outlineStyle={false}
                        title="Save"
                      // disabled={accountantDetails?.User?.UserAccess >= 5 ? true : false}
                      />
                    </TabPane>
                    <TabPane className="fadeIn" tabId="tabs2">
                      {clients.length >= 1 ? (
                        <>
                          <Clients
                            bgColor={bgColor}
                            textColor={textColor}
                            clients={clients}
                            selectedClients={selectedClients}
                            handleClientSelection={handleClientSelection}
                            removedClients={removedClients}
                            setRemovedClients={setRemovedClients}
                          />
                          <CustomButton
                            onClick={() => confirmSave("clients")}
                            type="button"
                            outlineStyle={false}
                            title="Save"
                          />
                        </>
                      ) : (
                        <div className="col-sm-12 mt-3 text-center">
                          <h5> No Clients found.</h5>
                        </div>
                      )}
                    </TabPane>
                    <TabPane className="fadeIn" tabId="tabs3">
                      {tabs === 3 &&
                        <Authority
                          authority={payload.authority}
                          handleInputChange={handleInputChange}
                          payload={payload}
                          firmDetails={firmDetails}
                          handleUpdateAuthority={handleUpdateAuthority}
                        />
                      }
                      
                      <div className="mt-3">
                        <CustomButton
                          onClick={() => confirmSave("authority")}
                          type="button"
                          outlineStyle={false}
                          title="Save"
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AccountantDetails;
