const momentTZ = require("moment-timezone");

export const businessTZ = (countryCode, date, isLastRefresh) => {
  if(countryCode === "nz") return momentTZ.tz(date, "Pacific/Auckland").format(`${isLastRefresh ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}`)
  if(countryCode === "us") return momentTZ.tz(date, "America/New_York").format(`${isLastRefresh ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}`)
  if(countryCode === "uk") return momentTZ.tz(date, "Europe/London").format(`${isLastRefresh ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}`)
  if(countryCode === "au") return momentTZ.tz(date, "Australia/Adelaide").format(`${isLastRefresh ? "DD-MM-YYYY HH:mm" : "DD-MM-YYYY"}`)
}

export const CsvHeaderCheck = (arr1, arr2) => {
  let validCsv = true;
  let errorsFound = [];
  if (arr1.length !== arr2.length) {
    errorsFound.push(`Number of columns mismatch, expected ${arr2.length} but found ${arr1.length}`);
    const extraColumns = arr1.filter(item => !arr2.includes(item));
    if (extraColumns.length) {
      const extraHeaders = extraColumns.join(',');
      errorsFound.push(`Extra columns found : ${extraHeaders}`);
    }
    validCsv = false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      errorsFound.push(`Invalid column name : expected ${arr2[i]} but found ${arr1[i]}`);
      validCsv = false;
    }
  }

  return {
    validCsv,
    errorsFound
  }
}

export const CsvCustomeHeaderCheck = (arr1, arr2) => {
  let validCsv = true;
  let errorsFound = [];
  if (arr1.length !== arr2.length) {
    errorsFound.push(`Number of columns mismatch, expected ${arr2.length} but found ${arr1.length}`);
    const extraColumns = arr1.filter(item => !arr2.includes(item));
    if (extraColumns.length) {
      const extraHeaders = extraColumns.join(',');
      errorsFound.push(`Extra columns found : ${extraHeaders}`);
    }
    validCsv = false;
  }

  for (let i = 0; i < arr1.length; i++) {
    // This will bypass comparing custom fields. Assuming columns names index are fix.
    if ((i >= 3 && i <= 12) || (i >= 20 && i <= 29)) {
      continue;
    }

    // Compare other fields
    if (arr1[i] !== arr2[i]) {
      errorsFound.push(`Invalid column name: expected ${arr2[i]} but found ${arr1[i]}`);
      validCsv = false;
    }
  }

  return {
    validCsv,
    errorsFound
  }
}

export const getAnnouncmentId = () => {
  const currentURL = window.location.href;
  const uuidRegex = /\/([a-fA-F0-9]{24})$/;
  const matches = currentURL.match(uuidRegex);
  return matches ? matches[0].substring(1) : null;
}

export const dataFilter = (input, data) => {
  let filterString = input;
  const filteredData = data.filter((acctnt) => {
    let included = false;
    for (let index in acctnt) {
      if (
        acctnt.hasOwnProperty(index) &&
        typeof acctnt[index] === "string" &&
        acctnt[index].toLowerCase().indexOf(filterString) >= 0
      ) {
        included = true;
        break;
      } else if (
        acctnt.hasOwnProperty(index) &&
        typeof acctnt[index] === "object"
      ) {
        let subObject = acctnt[index];

        for (let subIndex in subObject) {
          if (
            subObject.hasOwnProperty(subIndex) &&
            typeof subObject[subIndex] === "string" &&
            subObject[subIndex].toLowerCase().indexOf(filterString) >= 0
          ) {
            included = true;
            break;
          }
        }

        if (included) break;
      }
    }
    return included;
  });
  return filteredData;
};

export const dataFilterNameOnly = (input, data) => {
  let filterString = input;
  const filteredData = data.filter(({ Name }) => {
    return Name.toLowerCase().includes(filterString.toLowerCase());
  });
  return filteredData;
};

export const dataFilterTitleOnly = (input, data) => {
  let filterString = input;
  const filteredData = data.filter(({ title }) => {
    return title.toLowerCase().includes(filterString.toLowerCase());
  });
  return filteredData;
};

export const dataFilterAdvisorOnly = (input, data) => {
  let filterString = input;
  const filteredData = data.filter(({ User: { FirstName, LastName } }) => {
    return `${FirstName} ${LastName}`
      .toLowerCase()
      .includes(filterString.toLowerCase());
  });
  return filteredData;
};

export const dataFilterUserOnly = (input, data) => {
  let filterString = input;
  const filteredData = data.filter(({ FirstName, LastName }) => {
    return `${FirstName} ${LastName}`
      .toLowerCase()
      .includes(filterString.toLowerCase());
  });
  return filteredData;
};

export const dataFilterUserOnlyWithSelection = (input, data, filterType) => {
  let filterString = input;
  let filteredData = []
  // console.log(filterType)
  switch (filterType) {
    case "Email":
      filteredData = data.filter(({ Email }) => {
        return Email.toLowerCase().includes(filterString.toLowerCase().trim());
      });
      break;

    case "Client":
      // console.log(data)
      filteredData = data.filter(item => {
        console.log(item)
        return item.Client.some(clientObj => {
          if (clientObj.Name) {
            return clientObj.Name.toLowerCase().includes(filterString.toLowerCase());
          }
          return false;
        });
      });
      break;

    default:
      // console.log(data)
      filteredData = data.filter(({ FirstName, LastName }) => {
        return `${FirstName} ${LastName}`
          .toLowerCase()
          .includes(filterString.toLowerCase());
      });
      break;
  }
  return filteredData;
};

export const dataFilterIntegrationsOnly = (input, data) => {
  // console.log(data)
  let filterString = input;
  const filteredData = data.filter(({ Details: { Name, Organisation, name } }) => {
    if (Name) {
      return Name.toLowerCase().includes(filterString.toLowerCase());
    }
    if (Organisation) {
      return Organisation.toLowerCase().includes(filterString.toLowerCase());
    }
    if (name) {
      return Organisation.toLowerCase().includes(filterString.toLowerCase());
    }
  });
  return filteredData;
};

export const dataFilterIntegrationsOnlyWithSelection = (input, data, filterType) => {
  let filterString = input;
  
  let filteredData = []

  switch (filterType) {
    case "Client":
      filteredData = data.filter(({ Client: { Name } }) => {
        return Name.toLowerCase().includes(filterString.toLowerCase());
      });
      break;
    case "Integration":
        filteredData = data.filter(({ Service: { Name } }) => {
          return Name.toLowerCase().includes(filterString.toLowerCase());
        });
        break;
    default:
      filteredData = data.filter(({ Details: { Name, Organisation, name } }) => {
        if (Name) {
          return Name.toLowerCase().includes(filterString.toLowerCase());
        }
        if (Organisation) {
          return Organisation.toLowerCase().includes(filterString.toLowerCase());
        }
        if (name) {
          return Organisation.toLowerCase().includes(filterString.toLowerCase());
        }
      });

      break;
  }
  return filteredData;
};

export const checkUrlFormat = (url) => {
  let isValid = false;
  const patterns = [
    { pattern: /^((www\.)?)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, description: 'Basic domain format' },
    { pattern: /^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, description: 'With or without www.' },
    { pattern: /^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#\[\]@!$&'()*+,;%=]*)?$/, description: 'Including path and query parameters' },
    { pattern: /^(http:\/\/|https:\/\/)?(www\.)?([a-zA-Z0-9-]+)*\.[a-zA-Z0-9-]+(\/[a-zA-Z0-9-._~:\/?#\[\]@!$&'()*+,;%=]*)?$/, description: 'Comprehensive URL pattern' }
  ];

  for (let item of patterns) {
    if (item.pattern.test(url)) {
      isValid = true;
      return isValid;
    }
  }

  return isValid;
}

export const dataFilterStaffNameOnly = (input, data) => {
  let filterString = input;
  const filteredData = data.filter(({ StaffName }) => {
    return StaffName.toLowerCase().includes(filterString.toLowerCase());
  });
  return filteredData;
};


export const convertBase64ToJpg = (base64Data) => {
  const binaryData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: 'image/jpeg' });
  console.log(blob)
  // const imageUrl = URL.createObjectURL(blob);

  // Hold the data in the variable (imageUrl in this example)
  return blob;

  // You can use imageUrl to display the image in your React component
  // For example, set it as the source of an image element
  // <img src={imageUrl} alt="Converted Image" />
}